import * as Yup from 'yup';

import { Field, Form, Formik } from 'formik';
import { createNewTagRequest, edittagRequest } from './_request';
import { useDispatch, useSelector } from 'react-redux';

import { ENUM_API_STATUS } from '../../utils/_gConstant';
import React from 'react';
import close from '../../assets/icons/close.svg';
import { selectedTagName } from './store/action';
import { toastSuccess } from '../../components/ui-elements/_Toastify';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Tag is required')
    .matches(/^[a-zA-Z0-9]*$/, 'Only alphanumeric characters are allowed'),
});

const AddEditTag = (props) => {
  const { refreshList, closeModal } = props;
  const dispatch = useDispatch();
  const tag = useSelector((state) => state?.TagName?.selectedTagName);
  const handleClosemodal = () => {
    dispatch(selectedTagName(null));
    closeModal();
  };
  const createTag = async (values, { resetForm }) => {
    const res = await createNewTagRequest(values);
    if (res?.status === ENUM_API_STATUS?.SUCCESS_POST_API) {
      refreshList();
      handleClosemodal();
      toastSuccess('Tag Created Successfully');
    }
  };
  const editTagHandle = async (values) => {
    const res = await edittagRequest(tag?.id, values);
    if (res?.status === ENUM_API_STATUS?.SUCCESS_PATCH_API) {
      refreshList();
      handleClosemodal();
      toastSuccess('Tag Updated Successfully');
    }
  };
  const initialValues = {
    name: tag?.name || '',
  };

  const handleSubmit = (values, { resetForm }) => {
    console.log(tag?.id);
    if (tag) {
      editTagHandle(values);
    } else {
      createTag(values, { resetForm });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ touched, errors }) => (
        <Form className={`modal modal--delete-user open`}>
          <div className='modal__container' style={{ width: 550 }}>
            <div className='modal__header'>
              <h1 className='text-lg font-bold'>{tag ? 'Edit' : 'Add New'} Tag</h1>
              <div className='modal__close' onClick={handleClosemodal}>
                <img src={close} alt='close' />
              </div>
            </div>
            <div className='modal__body'>
              <label className='input-field-label primary-font'>Tag</label>
              <Field
                type='text'
                name='name'
                className={`input-field input-field--regular input-field--h40 border2 text-sm w-full lato ${
                  touched.name && errors.name ? 'error' : ''
                }`}
                placeholder='Tag'
              />
              {touched.name && errors.name && <div className='error-message'>{errors.name}</div>}
            </div>
            <div className='modal__footer justify-end'>
              <button
                type='button'
                className='btn btn--secondary btn--h40 px-6 py-2 text-base font-semibold'
                onClick={handleClosemodal}
              >
                Cancel
              </button>
              <button
                type='submit'
                className='btn btn--primary btn--h40 px-6 py-2 text-base font-semibold'
              >
                Save
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddEditTag;
