import '../../../assets/styles/scss/pages/dp-dashboard.scss';
import './styles.scss';

import * as actions from '../store/_actions';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { formatAmountInGerman } from '@/utils/utils';
import { getServicesRequest } from '../store/request';
import { useNavigate } from 'react-router-dom';
import useThrottle from '@/utils/_gFunctions/handleDebouncing';
import { useTranslation } from 'react-i18next';

const DPServices = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const {
    services = [],
    clientType,
    products = [],
    residentialUnits,
  } = useSelector((state) => state.createOrder);
  const { setServices, setProducts } = actions;

  useEffect(() => {
    getServicesRequest(clientType).then((result) => {
      const { data } = result;
      dispatch(setServices(data.data));
      setIsLoading(false);
    });
    // eslint-disable-next-line
  }, []);

  const onSelectProduct = (id) => {
    const _products = [...products];
    const productIndex = _products.findIndex((item) => item.id === id);
    if (productIndex === -1) {
      _products.push({
        id: id,
        quantity: 1,
      });
    } else {
      _products.splice(productIndex, 1);
    }
    dispatch(setProducts(_products));
  };

  const onChangeQuantity = (id, value) => {
    let _products = [...products];
    const index = _products.findIndex((item) => item.id === id);
    _products[index].quantity = value;

    dispatch(setProducts(_products));
  };

  const throttledOnChangeQuantity = useThrottle(onChangeQuantity, 300);
  const throttledOnSelectProduct = useThrottle(onSelectProduct,300);
  const isButtonDisabled = products.some((product) => product.quantity > 0);

  return (
    <>
      {isLoading && (
        <div className='loader-wrapper'>
          <div className='loader'></div>
        </div>
      )}
      {!isLoading && (
        <div className='dp-services col-10 mx-auto mt-10'>
          {services?.map((item, idx) => {
            const {
              id,
              name,
              main_tag,
              additional_tags,
              benefits,
              actual_price,
              final_price,
              multi_quantity,
              prices,
            } = item;
            const productItem = products.find((product) => product.id === id);

            let actualPrice = actual_price;
            let finalPrice = final_price;

            if (!multi_quantity) {
              prices.forEach((price) => {
                if (
                  residentialUnits >= price.min_quantity &&
                  residentialUnits <= price.max_quantity
                ) {
                  actualPrice = price.actual_price;
                  finalPrice = price.final_price;
                }
              });
            }

            return (
              <div
                className='dp-services__card relative pt-6 pl-6 pb-10 bg-white rounded-xl mb-10'
                key={idx - id}
              >
                <div className='dp-services__card__container flex items-start'>
                  <div className='relative custom-checkbox xl flex-shrink-0'>
                    <input
                      type='checkbox'
                      id={name}
                      className='custom-checkbox__input'
                      checked={productItem?.id === id}
                      onChange={() => throttledOnSelectProduct(id)}
                    />
                    <label htmlFor={name} className='custom-checkbox__label'>
                      <code className='custom-checkbox__label__box'></code>
                    </label>
                  </div>
                  <div className='flex-1 dp-services__card__content'>
                    <div className='flex items-center'>
                      <h2 className='dp-services__card__heading text-28 font-medium leading2 pr-8'>
                        {name}
                        <span className='inline-flex items-center justify-center flex-shrink-0 text-white font-medium px-3 py-2 ml-3 bg-primary-brand rounded-xl best-choice-txt'>
                          {main_tag.name}
                        </span>
                      </h2>
                      <div className='dp-services__price-tag secondary-font ml-auto'>
                        {actualPrice !== finalPrice && (
                          <span className='relative text-15 line-through mr-3' style={{ top: 3 }}>
                            {formatAmountInGerman(actualPrice)}
                          </span>
                        )}
                        {formatAmountInGerman(finalPrice)}
                      </div>
                    </div>
                    <div className='dp-services__label-wrapper flex items-center flex-wrap pr-9'>
                      {additional_tags.map((tag) => {
                        return <div className='dp-services__label'>{tag.name}</div>;
                      })}
                    </div>
                    <div className='dp-services__col-wrapper pr-9'>
                      {benefits.map((benifit) => {
                        const { name, description, icon } = benifit;
                        return (
                          <div className='dp-services__col'>
                            <div className='flex items-start'>
                              <figure className='dp-services__col__pic flex-shrink-0'>
                                <img src={icon} alt='doc-icon' style={{ width: 18, height: 22 }} />
                              </figure>
                              <div className='flex flex-col ml-3 mt-2'>
                                <h4 className='flex-shrink-0 font-medium leading2'>{name}</h4>
                                <p className='secondary-font text-sm font-light leading2 mt-3'>
                                  {description}
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {multi_quantity && productItem?.id === id && (
                      <div className='flex items-center justify-end mt-6 pr-9'>
                        <div className='quantity-container flex items-center justify-between'>
                          <button
                            className={`btn ${productItem?.quantity === 1 ? 'disabled' : ''}`}
                            onClick={() => throttledOnChangeQuantity(id, productItem?.quantity - 1)}
                            disabled={productItem?.quantity === 0}
                          >
                            -
                          </button>
                          <p className='quantity'>{productItem?.quantity}</p>
                          <button
                            className='btn'
                            onClick={() => throttledOnChangeQuantity(id, productItem?.quantity + 1)}
                          >
                            +
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}

          <div className='flex items-center justify-center'>
            <button
              className={`btn btn--primary px-10 py-3 ${isButtonDisabled ? '' : 'disabled'}`}
              style={{ fontSize: 18 }}
              onClick={() => navigate('/dp/order/confirmation')}
            >
              {t('Next Step')}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default DPServices;
