// import { LayoutSplashScreen } from '../../../../_dcode/layout/core';
// import { AuthModel, MeUserModel } from './_models';
import * as authHelper from './AuthHelper';

import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// import { WithChildren } from '../../../../_dcode/helpers';
// import { useDispatch } from 'react-redux';
// import { fetchAuthUserSuccess } from '../state';
// import { setMiniMasterDataList } from '../../../../store/actions/_commonActions';
import { fetchAuthUserSuccess } from '../state/_action';
import { getUserByToken } from './_request';

// import { Logout } from '../component/Logout';

// Define the types

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: (auth) => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
};

const AuthContext = createContext(initAuthContextPropsState);

const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(authHelper.getAuth());
  const [currentUser, setCurrentUser] = useState();
  const permissions = useSelector((state) => state?.authReducer?.user?.permissions);
  const saveAuth = (auth) => {
    setAuth(auth);
    if (auth) {
      authHelper.setAuth(auth);
    } else {
      authHelper.removeAuth();
    }
  };

  const hasPermission = useCallback(
    (perm) => {
      return permissions?.includes(perm);
    },
    [permissions]
  );

  const logout = () => {
    saveAuth(undefined);
    setCurrentUser(undefined);
  };

  return (
    <AuthContext.Provider
      value={{ auth, saveAuth, currentUser, setCurrentUser, logout, hasPermission }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const AuthInit = () => {
  const dispatch = useDispatch();
  const { auth, logout, setCurrentUser } = useAuth();
  const didRequest = useRef(false);
  // eslint-disable-next-line no-unused-vars
  const [showSplashScreen, setShowSplashScreen] = useState(true);

  useEffect(() => {
    const requestUser = async (apiToken) => {
      try {
        if (!didRequest.current) {
          const { data: user } = await getUserByToken(apiToken);
          if (user) {
            setCurrentUser(user);
            dispatch(fetchAuthUserSuccess(user));
          }
        }
      } catch (error) {
        console.error(error);
        if (!didRequest.current) {
          logout();
        }
      } finally {
        setShowSplashScreen(false);
      }
    };

    if (auth && auth.access) {
      requestUser(auth.access);
    } else {
      logout();
      setShowSplashScreen(false);
    }

    // Clean-up function to avoid potential memory leaks
    return () => {
      didRequest.current = true;
    };
  }, [auth, setCurrentUser, dispatch, logout]);

  return 'hello';
};

export { AuthProvider, AuthInit, useAuth };
