import React from 'react';

const ShimmerUI = (props) => {
  const { ref } = props;

  return (
    <div className='leads-list__card card-box p-4 mr-4 w-full' ref={ref}>
      <div className='pb-4' style={{ borderBottom: '1px solid #D1D5DB' }}>
        {/* <h4 className='skeleton-loader lg col-10'></h4> */}
      </div>
      <div className='flex items-center justify-between flex-wrap mt-4'>
        <div className='col-3 skeleton-loader mb-4'></div>
        <div className='col-8 skeleton-loader mb-4'></div>
        <div className='col-3 skeleton-loader mb-4'></div>
        <div className='col-8 skeleton-loader mb-4'></div>
        <div className='col-3 skeleton-loader mb-4'></div>
        <div className='col-8 skeleton-loader mb-4'></div>
        <div className='col-3 skeleton-loader mb-4'></div>
        <div className='col-8 skeleton-loader mb-4'></div>
      </div>
      <div className='flex items-center justify-between mt-4'>
        <div className='skeleton-loader skeleton-loader--pills'></div>
        <div className='skeleton-loader skeleton-loader--pills'></div>
      </div>
      <div className='flex items-center justify-between mt-4'>
        <div className='flex items-center flex-1 pr-10'>
          <figure className='skeleton-loader skeleton-loader--avatar'></figure>
          <span className='flex-1 skeleton-loader w-50 ml-2'></span>
        </div>
        <div className='flex items-center flex-shrink-0'>
          <figure className='skeleton-loader skeleton-loader--avatar'></figure>
          <figure className='skeleton-loader skeleton-loader--avatar ml-1'></figure>
        </div>
      </div>
    </div>
  );
};

export default ShimmerUI;
