import { DPuserRoles, ENUM_API_STATUS, isEmpty } from '@/utils/_gConstant';
import React, { useEffect, useState } from 'react';
import { dashboardDataGetRequest, newsAPIgetRequest } from '../store/request';

import Card from './Card';
import Header from '../components/Header';
import NewsCard from './NewsCard';
import OrdersByStatus from './OrdersByStatus';
import activityIcon from '../../../assets/icons/activity-icon.svg';
import alertInfo from '../../../assets/icons/alert-info.svg';
import alertInfoError from '../../../assets/icons/alert-info-red.svg';
import cancelLeft from '../../../assets/icons/cancel-left.svg';
import lineChart from '../../../assets/icons/line-chart-up.svg';
import picChart from '../../../assets/icons/pie-chart.svg';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const DPDashboard = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [activePercentage, setActivePercentage] = useState();
  const [completedPercentage, setCompletedPercentage] = useState();
  const [canceledPercentage, setCanceledPercentage] = useState();
  const [newsData, setNewsData] = useState([]);
  const _guserRole = useSelector((state) => state?.authReducer?.user?.role);

  const handleDashBoardData = async () => {
    const res = await dashboardDataGetRequest();
    if (res?.status === ENUM_API_STATUS?.SUCCESS_GET_API) {
      setData(res?.data);
      setActivePercentage(res?.data?.orders_by_status?.active);
      setCompletedPercentage(res?.data?.orders_by_status?.completed);
      setCanceledPercentage(res?.data?.orders_by_status?.cancel);
      setIsLoading(false);
    }
  };

  const handleNewsAPIdata = async () => {
    const res = await newsAPIgetRequest();
    if (res?.status === ENUM_API_STATUS?.SUCCESS_GET_API) {
      setNewsData(res?.data);
    }
  };

  useEffect(() => {
    handleDashBoardData();
    handleNewsAPIdata();
    document.title = props.title;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dashbaordData = [
    {
      id: 1,
      icon: activityIcon,
      title: t('open_orders'),
      count: data?.open_orders?.value,
      textColor: '',
      valueColor: 'text-green',
      filter: data?.open_orders?.filter,
    },
    {
      id: 2,
      icon: alertInfo,
      title: t('signature_missing'),
      count: data?.signature_missing?.value,
      textColor: '',
      valueColor: 'text-green',
      filter: data?.signature_missing?.filter,
    },
    ...(_guserRole === DPuserRoles?.DP_ADMIN || _guserRole === DPuserRoles?.DP_MASTER
      ? [
          {
            id: 3,
            icon: alertInfoError,
            title: t('signature_missing_team'),
            count: data?.signature_missing_team?.value,
            textColor: 'text-red',
            valueColor: 'text-red',
            filter: data?.signature_missing_team?.filter,
          },
        ]
      : []),
    {
      id: 4,
      icon: alertInfoError,
      title: t('correction_required'),
      count: data?.correction_required?.value,
      textColor: 'text-red',
      valueColor: 'text-red',
      filter: data?.correction_required?.filter,
    },
    {
      id: 5,
      icon: cancelLeft,
      title: t('closed_orders'),
      count: data?.closed_orders?.value,
      textColor: '',
      valueColor: 'text-green',
      filter: data?.closed_orders?.filter,
    },
    {
      id: 6,
      icon: lineChart,
      title: t('total_sales'),
      count: data?.total_sales?.value,
      textColor: '',
      valueColor: 'text-green',
      filter: data?.total_sales?.filter,
    },
    {
      id: 7,
      icon: lineChart,
      title:t('open_commission'),
      count : data?.open_commission?.value,
      textColor: '',
      valueColor:'text-green',
      filter: data?.open_commission?.filter,
      
    },
    {
      id: 7,
      icon: lineChart,
      title: t('daily_turnover_own'),
      count: data?.daily_turnover_own?.value,
      textColor: '',
      valueColor: 'text-green',
      filter: data?.daily_turnover_own?.filter,
    },
    ...(_guserRole === DPuserRoles?.DP_ADMIN || _guserRole === DPuserRoles?.DP_MASTER
      ? [
          {
            id: 8,
            icon: lineChart,
            title: t('daily_turnover_team'),
            count: data?.daily_turnover_business?.value,
            textColor: '',
            valueColor: 'text-green',
            filter: data?.daily_turnover_business?.filter,
          },
        ]
      : []),
    {
      id: 9,
      icon: picChart,
      title: t('cancel_rate_total_sales'),
      count: data?.cancel_rate_total_sales?.value,
      textColor: 'text-red',
      valueColor: 'text-red',
      filter: data?.cancel_rate_total_sales?.filter,
    },
  ];

  return (
    <>
      <section className='page-bg'>
        <Header onCreateOrder={() => navigate('dp-order-form/')} />

        {isLoading && (
          <div className='loader-wrapper'>
            <div className='loader'></div>
          </div>
        )}
        {!isLoading && (
          <main className='page-main'>
            <div className='max-container mt-15'>
              <h3 className='text-xl font-medium'>{t('kpis')}</h3>
              <ul className='flex flex-wrap xl-flex-no-wrap -mx-2 mt-2'>
                {dashbaordData?.map((item, index) => (
                  <Card
                    key={index}
                    icon={item.icon}
                    title={item.title}
                    count={item.count}
                    textColor={item.textColor}
                    valueColor={item.valueColor}
                    filter={item.filter}
                  />
                ))}
              </ul>
              <div className='mt-6 lg-mt-14'>
                <div className='flex flex-wrap xl-flex-no-wrap items-start -mx-2'>
                  <div className='w-full h-full xl-col-8 px-2'>
                    <div className='flex flex-col h-full'>
                      <h3 className='text-xl font-medium'>{t('news')}</h3>
                      <div className='flex flex-wrap md-flex-no-wrap -mx-2 mt-4'>
                        {newsData?.items?.map((item, index) => (
                          <NewsCard
                            key={index}
                            image={item.image.full_url}
                            title={item.title}
                            description={item.subtitle}
                            htmlurl={item.meta.html_url}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                  {!isEmpty(activePercentage) && !isEmpty(canceledPercentage) && (
                    <OrdersByStatus
                      activePercentage={activePercentage}
                      canceledPercentage={canceledPercentage}
                      completedPercentage={completedPercentage}
                    />
                  )}
                </div>
              </div>
            </div>
          </main>
        )}
      </section>
    </>
  );
};

export default DPDashboard;
