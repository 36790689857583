import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

const OrdersByStatus = ({ activePercentage, canceledPercentage, completedPercentage }) => {
  const { t } = useTranslation();

  const allZero = activePercentage === 0 && canceledPercentage === 0 && completedPercentage === 0;
  const series = allZero ? [1] : [activePercentage, canceledPercentage, completedPercentage];
  const labels = allZero ? [t('orders.status.noData')] : [t('orders.status.active'), t('orders.status.canceled'), t('orders.status.done')];
  const colors = allZero ? ['#A9A9A9'] : ['#00BD1E', '#A9A9A9', '#FFAA00'];

  const chartOptions = {
    chart: {
      type: 'donut',
      height: 350,
    },
    labels: labels,
    colors: colors,
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
        },
      },
    ],
  };

  return (
    <div className='w-full h-full xl-col-4 px-2 mt-8 xl-mt-0'>
      <div className='flex flex-col h-full'>
        <h3 className='text-xl font-medium'>{t('orders.status.title')}</h3>
        <div className='card-order-by-status h-full px-4 py-1 bg-white rounded-10 mt-4'>
          <div className='flex items-center justify-between py-3'>
            <div className='flex flex-col xl-pl-6'>
              <div className='flex flex-col'>
                <span className='text-primary-brand text-lg font-medium'>{t('orders.status.active')}</span>
                <span className='text-lg font-medium mt-2'>{parseFloat(activePercentage)?.toFixed(2)}%</span>
              </div>
              <div className='flex flex-col mt-6'>
                <span className='color-grey3 text-lg font-medium'>{t('orders.status.canceled')}</span>
                <span className='text-lg font-medium mt-2'>{parseFloat(canceledPercentage)?.toFixed(2)}%</span>
              </div>
              <div className='flex flex-col mt-6'>
                <span className='color-grey3 text-lg font-medium'>{t('orders.status.done')}</span>
                <span className='text-lg font-medium mt-2'>{parseFloat(completedPercentage)?.toFixed(2)}%</span>
              </div>
            </div>
            <div className='col-6'>
              <ReactApexChart
                options={chartOptions}
                series={series}
                type='donut'
                height={350}
                width={'100%'}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrdersByStatus;
