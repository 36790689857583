import * as Yup from 'yup';

import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { ENUM_API_STATUS } from '../../utils/_gConstant';
import React from 'react';
import close from '../../assets/icons/close.svg';
import { createEnergyConsultantPostRequest } from './_request';
import { selecteditConsultant } from './store/action';
import { toastErrors } from '../../components/ui-elements/_Toastify';

const AddEnergyConsultant = (props) => {
  const { closeModal, refreshList } = props;
  const dispatch = useDispatch();
  const consultantDataRedux = useSelector(
    (state) => state?.energyConsultantReducer?.selecteditConsultant
  );
  const energyConsultantId = consultantDataRedux?.id;
  const handleCloseModal = () => {
    closeModal();
    dispatch(selecteditConsultant(null));
  };
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('First name is required'),
    last_name: Yup.string().required('Last name is required'),
    mobile_number: Yup.string()
      .required('Mobile number is required')
      .matches(/^\+?\d{1,4}?\s?\d{1,14}$/, 'Invalid mobile number format'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    // Add more validation rules as needed
  });
  return (
    <Formik
      initialValues={{
        first_name: consultantDataRedux?.first_name || '',
        last_name: consultantDataRedux?.last_name || '',
        mobile_number: consultantDataRedux?.mobile_number || '',
        location: consultantDataRedux?.location || '',
        email: consultantDataRedux?.email || '',
        postal_code: consultantDataRedux?.postal_code || '',
      }}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        const res = await createEnergyConsultantPostRequest(values, energyConsultantId);
        if (
          res?.status !== ENUM_API_STATUS?.SUCCESS_POST_API &&
          res?.status !== ENUM_API_STATUS?.SUCCESS_PATCH_API
        ) {
          toastErrors(res?.data);
        } else {
          refreshList();
          handleCloseModal();
        }
      }}
    >
      {(formik) => (
        <Form className='modal modal--add-energy-consultant open'>
          <div className='modal__container'>
            <div className='modal__header'>
              {consultantDataRedux ? (
                <h1 className='text-lg font-bold'>Edit Energy Consultant</h1>
              ) : (
                <h1 className='text-lg font-bold'>New Energy Consultant</h1>
              )}

              <div className='modal__close' onClick={handleCloseModal}>
                <img src={close} alt='close' />
              </div>
            </div>
            <div className='modal__body'>
              <div className='flex flex-wrap -mx-2'>
                <div className='col-6 px-2 mb-4'>
                  <label className='input-field-label primary-font text-base font-medium'>
                    First name of the Consultant
                  </label>
                  <Field
                    type='text'
                    name='first_name'
                    className='input-field input-field--regular input-field--h40 w-full lato text-sm font-semibold'
                    placeholder='Name of the consultant'
                  />
                  <ErrorMessage className='error-message' name='first_name' component='p' />
                </div>
                <div className='col-6 px-2 mb-4'>
                  <label className='input-field-label primary-font text-base font-medium'>
                    Last name of the Consultant
                  </label>
                  <Field
                    type='text'
                    name='last_name'
                    className='input-field input-field--regular input-field--h40 w-full lato text-sm font-semibold'
                    placeholder='Name of the consultant'
                  />
                  <ErrorMessage className='error-message' name='last_name' component='p' />
                </div>
                <div className='col-6 px-2 mb-4'>
                  <label className='input-field-label primary-font text-base font-medium'>
                    Mobile
                  </label>
                  <Field
                    type='text'
                    name='mobile_number'
                    className='input-field input-field--regular input-field--h40 w-full lato text-sm font-semibold'
                    placeholder='+56 00 00 00 00'
                  />
                  <ErrorMessage className='error-message' name='mobile_number' component='p' />
                </div>
                <div className='col-6 px-2 mb-4'>
                  <label className='input-field-label primary-font text-base font-medium'>
                    Email
                  </label>
                  <Field
                    type='text'
                    name='email'
                    className='input-field input-field--regular input-field--h40 w-full lato text-sm font-semibold'
                    placeholder='abcd@gmail.com'
                  />
                  <ErrorMessage className='error-message' name='email' component='p' />
                </div>
                <div className='col-6 px-2 mb-4'>
                  <label className='input-field-label primary-font text-base font-medium'>
                    Location
                  </label>
                  <Field
                    type='text'
                    name='location'
                    className='input-field input-field--regular input-field--h40 w-full lato text-sm font-semibold'
                    placeholder='City name'
                  />
                  <ErrorMessage className='error-message' name='location' component='p' />
                </div>
                <div className='col-6 px-2 mb-4'>
                  <label className='input-field-label primary-font text-base font-medium'>
                    Postal code
                  </label>
                  <Field
                    type='text'
                    className='input-field input-field--regular input-field--h40 w-full lato text-sm font-semibold'
                    placeholder='Zip code'
                    name='postal_code'
                  />
                  <ErrorMessage className='error-message' name='postal_code' component='p' />
                </div>
              </div>
            </div>
            <div className='modal__footer justify-end'>
              <button
                className='btn btn--secondary btn--h40 px-6 py-2 text-base font-semibold'
                onClick={handleCloseModal}
              >
                Cancel
              </button>
              <button
                className='btn btn--primary btn--h40 px-6 py-2 text-base font-semibold'
                type='submit'
              >
                Save
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddEnergyConsultant;
