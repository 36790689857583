import React, { useEffect, useState } from 'react';
import { getCitiesListRequest, getCountriesListRequest } from '../../store/request';

import CustomSelect from '@/components/common/CustomeSelect';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const BillingAddress = (props) => {
  const {
    billingCompanyName,
    billingFirstName,
    billingLastName,
    billingStreet,
    billingHouseNumber,
    billingPostalCode,
    billingCity,
    billingCountry,
    setBillingCompanyName,
    setBillingFirstName,
    setBillingLastName,
    setBillingStreet,
    setBillingHouseNumber,
    setBillingPostalCode,
    setBillingCity,
    setBillingCountry,
    readonly,
    validateFields,
    validationErrors,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [cityOptions, setCityOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  useEffect(() => {
    const fetchCountries = async () => {
      const result = await getCountriesListRequest('de');
      console.log(result?.data?.data);
      setCountryOptions(result.data?.data);
    };
    fetchCountries();
  }, []);

  useEffect(() => {
    if (!billingCountry) return;

    const getCities = async () => {
      const result = await getCitiesListRequest('de', billingCountry.value);

      const _cityOptions = result?.data?.data?.cities?.map((city) => ({
        label: city.name,
        value: city.id,
      }));
      setCityOptions(_cityOptions);
    };

    getCities();
    // eslint-disable-next-line
  }, [billingCountry]);
  return (
    <>
      <h2 className='mb-4'>{t('billing_address')}s</h2>
      <div className='mb-4'>
        <label className='block text-base font-medium mb-1'>{t('companyName')}</label>
        <input
          className={`input-field input-field--regular input-field--h40 lato w-full font-semibold border2 ${readonly ? 'input-blur' : ''} ${validationErrors.billingCompanyName ? 'border-red' : ''}`}
          type='text'
          value={billingCompanyName}
          onChange={(event) => {
            validateFields('approxEmployees', event.target.value, setBillingCompanyName);
          }}
          onWheel={(event) => event.target.blur()} // disables scrolling
          readOnly={readonly}
        />
      </div>
      <div className='mb-4'>
        <label className='block text-base font-medium mb-1'>
          {t('firstName')}
          {!readonly && <span className='text-red'>*</span>}
        </label>
        <input
          className={`input-field input-field--regular input-field--h40 lato w-full font-semibold border2 ${readonly ? 'input-blur' : ''} ${validationErrors.billingFirstName ? 'border-red' : ''}`}
          type='text'
          value={billingFirstName}
          onChange={(event) => {
            validateFields('billingFirstName', event.target.value, setBillingFirstName);
          }}
          onWheel={(event) => event.target.blur()} // disables scrolling
          readOnly={readonly}
        />
        {validationErrors.billingFirstName && (
          <span className='text-red text-sm'>{validationErrors.billingFirstName}</span>
        )}
      </div>
      <div className='mb-4'>
        <label className='block text-base font-medium mb-1'>
          {t('lastName')}
          {!readonly && <span className='text-red'>*</span>}
        </label>
        <input
          className={`input-field input-field--regular input-field--h40 lato w-full font-semibold border2 ${readonly ? 'input-blur' : ''} ${validationErrors.billingLastName ? 'border-red' : ''}`}
          type='text'
          value={billingLastName}
          onChange={(event) => {
            validateFields('billingLastName', event.target.value, setBillingLastName);
          }}
          onWheel={(event) => event.target.blur()} // disables scrolling
          readOnly={readonly}
        />
        {validationErrors.billingLastName && (
          <span className='text-red text-sm'>{validationErrors.billingLastName}</span>
        )}
      </div>
      <div className='mb-4'>
        <label className='block text-base font-medium mb-1'>
          {t('street')}
          {!readonly && <span className='text-red'>*</span>}
        </label>
        <input
          className={`input-field input-field--regular input-field--h40 lato w-full font-semibold border2 ${readonly ? 'input-blur' : ''} ${validationErrors.billingStreet ? 'border-red' : ''}`}
          type='text'
          value={billingStreet}
          onChange={(event) => {
            validateFields('billingStreet', event.target.value, setBillingStreet);
          }}
          onWheel={(event) => event.target.blur()} // disables scrolling
          readOnly={readonly}
        />
        {validationErrors.billingStreet && (
          <span className='text-red text-sm'>{validationErrors.billingStreet}</span>
        )}
      </div>
      <div className='mb-4'>
        <label className='block text-base font-medium mb-1'>
          {t('houseNumber')}
          {!readonly && <span className='text-red'>*</span>}
        </label>
        <input
          className={`input-field input-field--regular input-field--h40 lato w-full font-semibold border2 ${readonly ? 'input-blur' : ''} ${validationErrors.billingHouseNumber ? 'border-red' : ''}`}
          type='number'
          value={billingHouseNumber}
          onChange={(event) => {
            validateFields('billingHouseNumber', event.target.value, setBillingHouseNumber);
          }}
          onWheel={(event) => event.target.blur()} // disables scrolling
          readOnly={readonly}
        />
        {validationErrors.billingHouseNumber && (
          <span className='text-red text-sm'>{validationErrors.billingHouseNumber}</span>
        )}
      </div>
      <div className='mb-4'>
        <label className='block text-base font-medium mb-1'>
          {t('postalCode')}
          {!readonly && <span className='text-red'>*</span>}
        </label>
        <input
          className={`input-field input-field--regular input-field--h40 lato w-full font-semibold border2 ${readonly ? 'input-blur' : ''} ${validationErrors.billingCompanyName ? 'border-red' : ''}`}
          type='text'
          value={billingPostalCode}
          onChange={(event) => {
            validateFields('billingPostalCode', event.target.value, setBillingPostalCode);
          }}
          onWheel={(event) => event.target.blur()} // disables scrolling
          readOnly={readonly}
        />
        {validationErrors.billingPostalCode && (
          <span className='text-red text-sm'>{validationErrors.billingPostalCode}</span>
        )}
      </div>
      {readonly ? (
        <div className='mb-4'>
          <label className='block text-base font-medium mb-1'>
            {t('Country')}
            {!readonly && <span className='text-red'>*</span>}
          </label>
          <input
            className={`input-field input-field--regular input-field--h40 lato w-full font-semibold border2 ${readonly ? 'input-blur' : ''} ${validationErrors.billingCompanyName ? 'border-red' : ''}`}
            type='text'
            value={billingCountry}
            readOnly={readonly}
          />
        </div>
      ) : (
        <div className='mb-4'>
          <label className='block font-medium mb-1'>
            {t('Country')}
            <span className='text-red'>*</span>
          </label>
          <CustomSelect
            name='country'
            className={`input-field--regular input-field--h40 border2 text-sm w-full lato w-full font-semibold ${validationErrors.country ? 'border-red' : ''}`}
            placeholder={t('Select Country')}
            options={countryOptions?.map((country) => ({
              label: country.translated_name,
              value: country.id,
            }))}
            maxMenuHeight={150}
            value={billingCountry}
            onChange={(selectedOption) => {
              dispatch(setBillingCity(''));
              setCityOptions([]);
              validateFields('country', selectedOption, setBillingCountry);
            }}
          />
          {validationErrors.country && (
            <span className='text-red text-sm'>{validationErrors.country}</span>
          )}
        </div>
      )}

      {readonly === true ? (
        <div className='mb-4'>
          <label className='block text-base font-medium mb-1'>
            {t('city')}
            {!readonly && <span className='text-red'>*</span>}
          </label>
          <input
            className={`input-field input-field--regular input-field--h40 lato w-full font-semibold border2 ${readonly ? 'input-blur' : ''} ${validationErrors.billingCompanyName ? 'border-red' : ''}`}
            type='text'
            value={billingCity}
            readOnly={readonly}
          />
        </div>
      ) : (
        <div className='mb-4'>
          <label className='block text-base font-medium mb-1'>
            {t('city')}
            {!readonly && <span className='text-red'>*</span>}
          </label>
          <CustomSelect
            name='city'
            className={`input-field--regular input-field--h40 border2 w-full lato w-full text-sm font-semibold ${validationErrors.city ? 'border-red' : ''}`}
            placeholder={t('Select City')}
            options={cityOptions}
            maxMenuHeight={150}
            onChange={(selectedOption) => validateFields('city', selectedOption, setBillingCity)}
            value={billingCity}
          />
          {validationErrors.city && (
            <span className='text-red text-sm'>{validationErrors.city}</span>
          )}
        </div>
      )}
    </>
  );
};

export default BillingAddress;
