import React from 'react';
import docFile from '../../assets/icons/doc-file.svg';
import downloadIcon from '../../assets/icons/download.svg';
import jpegFile from '../../assets/icons/jpg-file.png';
import pdfFile from '../../assets/icons/pdf-file.svg';
import pngFile from '../../assets/icons/png-file.png';
import trashIcon from '../../assets/icons/trash.svg';

const docDetails = [
  {
    id: 1,
    icon: jpegFile,
    title: 'Proof of use.jpeg',
    sizeDate: '174MB,  22/02/2023',
  },
  {
    id: 2,
    icon: pdfFile,
    title: 'Signed proof of use.pdf',
    sizeDate: '174MB,  22/02/2023',
  },
  {
    id: 3,
    icon: pngFile,
    title: 'House Prof.png',
    sizeDate: '174MB,  22/02/2023',
  },
  {
    id: 4,
    icon: docFile,
    title: 'Proof.docs',
    sizeDate: '174MB,  22/02/2023',
  },
];
const documentsAcionItems = [
  { id: 1, questions: 'Signed proof of use' },
  { id: 2, questions: 'BAFA power of attorney' },
  { id: 3, questions: 'Floor Plans' },
  { id: 4, questions: 'Section of the house' },
  { id: 5, questions: 'Views of the House' },
  { id: 6, questions: 'Pictures of the house' },
  { id: 7, questions: 'Construction description' },
  { id: 8, questions: 'Energy Certificate' },
  { id: 9, questions: 'Personal Drawing' },
  { id: 10, questions: 'Declaration of Consent Weigh' },
  { id: 11, questions: 'BAFA Confirmation' },
  { id: 12, questions: 'BAFA Authorization' },
  { id: 13, questions: 'BAFA De Minimis Statement' },
];
const Document = () => {
  return (
    <>
      <div className='lead-main-info__documents mt-6 mx-2 pb-3 border1-b'>
        <div className='flex items-center justify-between'>
          <h2 className='text-lg font-semibold'>All Documents</h2>
        </div>
      </div>
      <div className='mt-5'>
        <div className='flex'>
          <div className='col-3 flex-shrink-0 pr-15 border1-r' style={{ width: 305 }}>
            <ul className=''>
              {documentsAcionItems.map((item, index) => (
                <li key={index} className='action-nav-list'>
                  {item.questions}
                </li>
              ))}
            </ul>
          </div>
          <div className='col-9 flex-1'>
            <ul className='px-8'>
              {docDetails.map((item, index) => (
                <li
                  key={index}
                  className='mt-2 px-5 py-2'
                  style={{
                    borderBottom: '1px solid #D1D5DB',
                    borderRadius: '0 0 8px 8px',
                  }}
                >
                  <div className='flex items-center justify-between'>
                    <div className='flex items-center truncate pr-5'>
                      <img src={item.icon} alt='jpeg-file' style={{ maxHeight: 32 }} />
                      <div className='flex flex-col ml-4 truncate'>
                        <h3 className='font-medium truncate'>{item.title}</h3>
                        <span className='secondary-font text-grey2 text-xs font-regular mt-1 truncate'>
                          {item.sizeDate}
                        </span>
                      </div>
                    </div>
                    <div className='flex items-center'>
                      <div className='flex items-center justify-center flex-shrink-0 w-5 h-5 cursor-pointer'>
                        <img src={downloadIcon} alt='downloadIcon' />
                      </div>
                      <div className='flex items-center justify-center flex-shrink-0 w-5 h-5 ml-4 cursor-pointer'>
                        <img src={trashIcon} alt='deleteIcon' />
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Document;
