const initialState = {
  hearderCreateButtonHide: false,
};

const createOrderState = {
  services: [],
  clientType: 'B2C',
  companyName: '',
  postalCode: '',
  country: '',
  city: '',
  residentialUnits: '',
  annualEnergyCost: '',
  products: [],
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  consultingLocation: '',
  street: '',
  houseNumber: '',
  buildingApplication: '',
  approxEmployees: '',
  annualTurnover: '',
  energy_costs: {
    unit: 'Kwh',
    currency: 'Euro',
    values: [],
  },
  billingCompanyName: '',
  billingFirstName: '',
  billingLastName: '',
  billingStreet: '',
  billingHouseNumber: '',
  billingPostalCode: '',
  billingCity: '',
  billingCountry : '',

};
const DPUserDetails = {
  details: {},
};

export const headerCreateNewButtonStateHandle = (state = initialState, action) => {
  switch (action.type) {
    case 'BUTTON_STATE':
      return {
        ...state,
        hearderCreateButtonHide: action.payload,
      };
    default:
      return state;
  }
};

export const createOrder = (state = createOrderState, action) => {
  switch (action.type) {
    case 'SERVICES':
      return {
        ...state,
        services: action.payload,
      };
    case 'CLIENT_TYPE':
      return {
        ...state,
        clientType: action.payload,
      };
    case 'COMPANY_NAME':
      return {
        ...state,
        companyName: action.payload,
      };
    case 'POSTAL_CODE':
      return {
        ...state,
        postalCode: action.payload,
      };
    case 'COUNTRY':
      return {
        ...state,
        country: action.payload,
      };
    case 'CITY':
      return {
        ...state,
        city: action.payload,
      };
    case 'RESIDENTIAL_UNITS':
      return {
        ...state,
        residentialUnits: action.payload,
      };
    case 'ANNUAL_ENERGY_COST':
      return {
        ...state,
        annualEnergyCost: action.payload,
      };
    case 'PRODUCTS':
      return {
        ...state,
        products: action.payload,
      };
    case 'FIRST_NAME':
      return {
        ...state,
        firstName: action.payload,
      };
    case 'LAST_NAME':
      return {
        ...state,
        lastName: action.payload,
      };
    case 'PHONE':
      return {
        ...state,
        phone: action.payload,
      };
    case 'EMAIL':
      return {
        ...state,
        email: action.payload,
      };
    case 'CONSULTING_LOCATIONS':
      return {
        ...state,
        consultingLocation: action.payload,
      };
    case 'STREET':
      return {
        ...state,
        street: action.payload,
      };
    case 'HOUSE_NUMBER':
      return {
        ...state,
        houseNumber: action.payload,
      };
    case 'BUILDING_APPLICATION':
      return {
        ...state,
        buildingApplication: action.payload,
      };
    case 'APPROX_EMPLOYEES':
      return {
        ...state,
        approxEmployees: action.payload,
      };
    case 'ANNUAL_TURNOVER':
      return {
        ...state,
        annualTurnover: action.payload,
      };
    case 'UPDATE_ENERGY_COST': {
      const { name, unit, currency, value, amount } = action.payload;

      let updatedValues;
      if (state.energy_costs.values) {
        const existingIndex = state.energy_costs.values.findIndex((item) => item.name === name);
        if (existingIndex !== -1) {
          updatedValues = state.energy_costs.values.map((item, index) =>
            index === existingIndex ? { ...item, value, amount } : item
          );
        } else {
          updatedValues = [...state.energy_costs.values, { name, value, amount }];
        }
      } else {
        updatedValues = [{ name, value, amount }];
      }

      return {
        ...state,
        energy_costs: {
          unit,
          currency,
          values: updatedValues,
        },
      };
    }
    case 'SET_ENERGY_COST': {
      return {
        ...state,
        energy_costs: action.payload,
      };
    }
    case 'SET_BILLING_COMPANY_NAME': {
      return {
        ...state,
        billingCompanyName: action.payload,
      };
    }
    case 'SET_BILLING_FIRST_NAME': {
      return {
        ...state,
        billingFirstName: action.payload,
      };
    }
    case 'SET_BILLING_LAST_NAME': {
      return {
        ...state,
        billingLastName: action.payload,
      };
    }
    case 'SET_BILLING_STREET': {
      return {
        ...state,
        billingStreet: action.payload,
      };
    }
    case 'SET_BILLING_HOUSE_NUMBER': {
      return {
        ...state,
        billingHouseNumber: action.payload,
      };
    }
    case 'SET_BILLING_POSTAL_CODE': {
      return {
        ...state,
        billingPostalCode: action.payload,
      };
    }
    case 'SET_BILLING_COUNTRY' : {
      return {
        ...state,
        billingCountry : action.payload
      }
    }
    case 'SET_BILLING_CITY': {
      return {
        ...state,
        billingCity: action.payload,
      };
    }

    case 'RESET':
      return createOrderState;
    default:
      return state;
  }
};

export const dpuserDetails = (state = DPUserDetails, action) => {
  switch (action.type) {
    case 'DP_USER_DETAILS':
      return {
        ...state,
        details: action.payload,
      };
    case 'RESET_DP_USER':
      return DPUserDetails;
    default:
      return state;
  }
};
