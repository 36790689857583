import { Navigate, Route, Routes } from 'react-router-dom';

import AdminDashboard from '@/pages/Admin/AdminDashboard';
import DistributionPartners from '@/pages/Admin/DistributionPartners';
import EnergyConsultants from '@/pages/Admin/EnergyConsultants';
import LeadDetails from '@/pages/Admin/LeadDetails';
import { LeadListing } from '@/pages/Admin/LeadListing';
import PropTypes from 'prop-types';
import TagManagement from '@/pages/Admin/TagManagement';
import { UserRoles } from '@/utils/_gConstant';
import UsersManagment from '@/pages/Admin/UsersManagment';
import { useSelector } from 'react-redux';

const AdminRoute = ({ children }) => {
  const userRole = useSelector((state) => state.authReducer?.user?.role);
  if (![UserRoles.OPS_ADMIN, UserRoles.OPS_USER].includes(userRole)) {
    return <Navigate to='/' />;
  }

  return (
    <Routes>
      <Route path='/' element={<AdminDashboard title='App | IKOSIA' />}>
        <Route path='leads/' element={<LeadListing title='App | IKOSIA' />} index />
        <Route path='users/' element={<UsersManagment title='App | IKOSIA' />} />
        <Route path='consultants/' element={<EnergyConsultants title='App | IKOSIA' />} />
        <Route path='tags/' element={<TagManagement title='App | IKOSIA' />} />
        <Route path='partners/' element={<DistributionPartners title='App | IKOSIA' />} />
        <Route path='leads/details/:leadId/' element={<LeadDetails title='App | IKOSIA' editIcon={true} />} />
        <Route path='/' element={<Navigate to='leads/' replace />} />
      </Route>
    </Routes>
  );
};

AdminRoute.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
    PropTypes.node,
    PropTypes.object,
  ]).isRequired,
};

export default AdminRoute;
