import * as actions from '../../store/_actions';

import { CLIENT_TYPE, VALIDATION_REGEX, isEmpty } from '@/utils/_gConstant';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BillingAddress from './BillingAddress';
import CustomSelect from '@/components/common/CustomeSelect';
import { getCitiesListRequest } from '../../store/request';
import { preventExponentialInputInNumber } from '@/utils/utils';
import { useDebounceFuntion } from '@/utils/_gFunctions/handleDebouncing';
import { useTranslation } from 'react-i18next';

const CustomerData = ({ readonly, setHasError = () => {}, setShowBilling }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    clientType,
    companyName,
    firstName,
    lastName,
    phone,
    email,
    postalCode,
    street,
    houseNumber,
    buildingApplication,
    city,
    approxEmployees,
    annualTurnover,
    billingCompanyName,
    billingFirstName,
    billingLastName,
    billingStreet,
    billingHouseNumber,
    billingPostalCode,
    billingCity,
    billingCountry,
  } = useSelector((state) => state.createOrder);
  const {
    setCompanyName,
    setFirstName,
    setLastName,
    setPhone,
    setEmail,
    setStreet,
    setHouseNumber,
    setBuildingApplication,
    setCity,
    setApproxEmployees,
    setAnnualTurnover,
    setPostalCode,
    setBillingCompanyName,
    setBillingFirstName,
    setBillingLastName,
    setBillingStreet,
    setBillingHouseNumber,
    setBillingPostalCode,
    setBillingCity,
    setBillingCountry,
  } = actions;
  const [cityOptions, setCityOptions] = useState([]);
  const [showBillingAddress, setShowBillingAddress] = useState(readonly);
  const [validationErrors, setValidationErrors] = useState({});

  const isPrivateClient = clientType === CLIENT_TYPE[0].value;
  const _firstStepcountryChoice = useSelector((state) => state?.createOrder?.country?.value);

  const validateFields = (fieldName, value, updateFunction, pattern = '') => {
    const errors = { ...validationErrors };
    const createRequiredFields = (isPrivateClient, showBillingAddress) => {
      return {
        companyName: !isPrivateClient,
        approxEmployees: !isPrivateClient,
        annualTurnover: !isPrivateClient,
        firstName: true,
        lastName: true,
        phone: true,
        email: true,
        // consultingLocation: true,
        street: true,
        houseNumber: true,
        buildingApplication: true,
        city: true,
        postalCode: true,
        ...(showBillingAddress
          ? {
              billingCompanyName: false,
              billingFirstName: true,
              billingLastName: true,
              billingStreet: true,
              billingHouseNumber: true,
              billingPostalCode: true,
              billingCity: true,
              billingCountry: true,
            }
          : {}),
      };
    };
    
    const requiredFields = createRequiredFields(isPrivateClient, showBillingAddress);

    const required = requiredFields[fieldName];
    if (required && isEmpty(value)) {
      errors[fieldName] = t('requiredField');
    } else if (pattern && !pattern.test(value)) {
      errors[fieldName] = t('invalidValue');
    } else {
      errors[fieldName] = '';
    }
    setValidationErrors(errors);
    dispatch(updateFunction(value));
  };

  const debouncedValidateFields = useDebounceFuntion(validateFields, 300);

  useEffect(() => {
    setHasError(Object.values(validationErrors).some((message) => message !== ''));
    // eslint-disable-next-line
  }, [validationErrors]);

  const getCities = async () => {
    const result = await getCitiesListRequest('de', _firstStepcountryChoice);
    const _cityOptions = result.data?.data?.cities?.map((city) => ({
      label: city.name,
      value: city.id,
    }));
    setCityOptions(_cityOptions);
  };

  // eslint-disable-next-line

  return (
    <div className='mt-4'>
      <h2 className='text-28 font-medium leading2'>{t('customerData')}</h2>
      <div className='bg-white rounded-xl p-6 mt-4'>
        {!isPrivateClient && (
          <div className='mb-4'>
            <label className='block text-base font-medium mb-1'>
              {t('companyName')}
              {!readonly && <span className='text-red'>*</span>}
            </label>
            <input
              className={`input-field input-field--regular input-field--h40 lato w-full font-semibold border2 ${readonly ? 'input-blur' : ''} ${validationErrors.companyName ? 'border-red' : ''}`}
              value={companyName}
              onChange={(event) => {
                debouncedValidateFields(
                  'companyName',
                  event.target.value,
                  setCompanyName,
                  VALIDATION_REGEX.ALPHANUMERIC
                );
              }}
              readOnly={readonly}
            />
            {validationErrors.companyName && (
              <span className='text-red text-sm'>{validationErrors.companyName}</span>
            )}
          </div>
        )}
        <div className='flex flex-col lg:flex-row -mx-2'>
          <div className='md:col-6 px-2'>
            <div className='mb-4'>
              <label className='block text-base font-medium mb-1'>
                {t('contactPersonFirstName')}
                {!readonly && <span className='text-red'>*</span>}
              </label>
              <input
                className={`input-field input-field--regular input-field--h40 lato w-full font-semibold border2 ${readonly ? 'input-blur' : ''} ${validationErrors.firstName ? 'border-red' : ''}`}
                type='text'
                value={firstName}
                onChange={(event) => {
                  debouncedValidateFields(
                    'firstName',
                    event.target.value,
                    setFirstName,
                    VALIDATION_REGEX.ALPHANUMERIC
                  );
                }}
                readOnly={readonly}
              />
              {validationErrors.firstName && (
                <span className='text-red text-sm'>{validationErrors.firstName}</span>
              )}
            </div>
          </div>
          <div className='md:col-6 px-2'>
            <div className='mb-4'>
              <label className='block text-base font-medium mb-1'>
                {t('contactPersonLastName')}
                {!readonly && <span className='text-red'>*</span>}
              </label>
              <input
                className={`input-field input-field--regular input-field--h40 lato w-full font-semibold border2 ${readonly ? 'input-blur' : ''} ${validationErrors.lastName ? 'border-red' : ''}`}
                type='text'
                value={lastName}
                onChange={(event) => {
                  debouncedValidateFields(
                    'lastName',
                    event.target.value,
                    setLastName,
                    VALIDATION_REGEX.ALPHANUMERIC
                  );
                }}
                readOnly={readonly}
              />
              {validationErrors.lastName && (
                <span className='text-red text-sm'>{validationErrors.lastName}</span>
              )}
            </div>
          </div>
        </div>
        <div className='flex flex-col lg:flex-row -mx-2'>
          <div className='md:col-6 px-2'>
            <div className='mb-4'>
              <label className='block text-base font-medium mb-1'>
                {t('phone')}
                {!readonly && <span className='text-red'>*</span>}
              </label>
              <input
                className={`input-field input-field--regular input-field--h40 lato w-full font-semibold border2 ${readonly ? 'input-blur' : ''} ${validationErrors.phone ? 'border-red' : ''}`}
                type='text'
                value={phone}
                placeholder='+911234567890'
                onChange={(event) => {
                  debouncedValidateFields(
                    'phone',
                    event.target.value,
                    setPhone,
                    VALIDATION_REGEX.PHONE_NUMBER
                  );
                }}
                readOnly={readonly}
              />
              {validationErrors.phone && (
                <span className='text-red text-sm'>{validationErrors.phone}</span>
              )}
            </div>
          </div>
          <div className='md:col-6 px-2'>
            <div className='mb-4'>
              <label className='block text-base font-medium mb-1'>
                {t('email')}
                {!readonly && <span className='text-red'>*</span>}
              </label>
              <input
                className={`input-field input-field--regular input-field--h40 lato w-full font-semibold border2 ${readonly ? 'input-blur' : ''} ${validationErrors.email ? 'border-red' : ''}`}
                type='email'
                value={email}
                onChange={(event) => {
                  debouncedValidateFields('email', event.target.value, setEmail, VALIDATION_REGEX.EMAIL);
                }}
                readOnly={readonly}
              />
              {validationErrors.email && (
                <span className='text-red text-sm'>{validationErrors.email}</span>
              )}
            </div>
          </div>
        </div>
        <h2 className='mb-4'>{t('propertyAddress')}</h2>
        <div className='flex mb-4 '>
          <div className='w-full mr-2'>
            <label className='block text-base font-medium mb-1'>
              {t('street')}
              {!readonly && <span className='text-red'>*</span>}
            </label>
            <input
              className={`input-field input-field--regular input-field--h40 lato w-full font-semibold border2 ${readonly ? 'input-blur' : ''} ${validationErrors.street ? 'border-red' : ''}`}
              type='text'
              value={street}
              onChange={(event) => {
                debouncedValidateFields('street', event.target.value, setStreet);
              }}
              readOnly={readonly}
            />
            {validationErrors.street && (
              <span className='text-red text-sm'>{validationErrors.street}</span>
            )}
          </div>
          <div className='w-full ml-2'>
            <label className='block text-base font-medium mb-1'>
              {t('houseNumber')}
              {!readonly && <span className='text-red'>*</span>}
            </label>
            <input
              className={`input-field input-field--regular input-field--h40 lato w-full font-semibold border2 ${readonly ? 'input-blur' : ''} ${validationErrors.consultingLocation ? 'border-red' : ''}`}
              type='text'
              value={houseNumber}
              onChange={(event) => {
                debouncedValidateFields('houseNumber', event.target.value, setHouseNumber);
              }}
              readOnly={readonly}
            />
            {validationErrors.houseNumber && (
              <span className='text-red text-sm'>{validationErrors.houseNumber}</span>
            )}
          </div>
        </div>
        <div className='flex mb-4 '>
          {readonly === true ? (
            <div className='w-full mr-2' onClick={() => getCities()}>
              <label className='block text-base font-medium mb-1'>
                {t('city')}
                {!readonly && <span className='text-red'>*</span>}
              </label>
              <input
                className={`input-field input-field--regular input-field--h40 lato w-full font-semibold border2 ${readonly ? 'input-blur' : ''} ${validationErrors.consultingLocation ? 'border-red' : ''}`}
                type='text'
                value={city}
                readOnly={readonly}
              />
              {validationErrors.city && (
                <span className='text-red text-sm'>{validationErrors.city}</span>
              )}
            </div>
          ) : (
            <div className='w-full mr-2' onClick={() => getCities()}>
              <label className='block text-base font-medium mb-1'>
                {t('city')}
                {!readonly && <span className='text-red'>*</span>}
              </label>
              <CustomSelect
                name='city'
                className={`input-field--regular input-field--h40 border2 w-full lato w-full text-sm font-semibold ${validationErrors.city ? 'border-red' : ''}`}
                placeholder={t('Select City')}
                options={cityOptions}
                maxMenuHeight={150}
                onChange={(selectedOption) => validateFields('city', selectedOption, setCity)}
                value={city}
              />
              {validationErrors.city && (
                <span className='text-red text-sm'>{validationErrors.city}</span>
              )}
            </div>
          )}

          <div className='w-full ml-2'>
            <label className='block text-base font-medium mb-1'>
              {t('postalCode')}
              {!readonly && <span className='text-red'>*</span>}
            </label>
            <input
              className={`input-field input-field--regular input-field--h40 lato w-full font-semibold border2 ${readonly ? 'input-blur' : ''} ${validationErrors.consultingLocation ? 'border-red' : ''}`}
              type='text'
              value={postalCode}
              onChange={(event) => {
                debouncedValidateFields('postalCode', event.target.value, setPostalCode);
              }}
              readOnly={readonly}
            />
            {validationErrors.postalCode && (
              <span className='text-red text-sm'>{validationErrors.postalCode}</span>
            )}
          </div>
        </div>
        <div className=' mb-4 '>
          <label className='block text-base font-medium mb-1'>
            {t('buildingApplication')}
            {!readonly && <span className='text-red'>*</span>}
          </label>
          <input
            className={`input-field input-field--regular input-field--h40 lato w-full font-semibold border2 ${readonly ? 'input-blur' : ''} ${validationErrors.consultingLocation ? 'border-red' : ''}`}
            type='text'
            value={buildingApplication}
            onChange={(event) => {
              debouncedValidateFields('buildingApplication', event.target.value, setBuildingApplication);
            }}
            readOnly={readonly}
          />
          {validationErrors.buildingApplication && (
            <span className='text-red text-sm'>{validationErrors.buildingApplication}</span>
          )}
        </div>
        {!readonly && (
          <div className='flex items-center mb-6 pt-2'>
            <div className='relative custom-checkbox x flex-shrink-0'>
              <input
                type='checkbox'
                id={'billingAddress'}
                className='custom-checkbox__input'
                onChange={(e) => {
                  setShowBillingAddress(e.target.checked);
                  setShowBilling(e.target.checked);
                }}
              />
              <label htmlFor={'billingAddress'} className='custom-checkbox__label'>
                <code className='custom-checkbox__label__box'></code>
              </label>
            </div>
            <h2 className='ml-2 mt-1'>{t('other_billing_address')}</h2>
          </div>
        )}

        {showBillingAddress && (
          <BillingAddress
            billingCompanyName={billingCompanyName}
            billingFirstName={billingFirstName}
            billingLastName={billingLastName}
            billingStreet={billingStreet}
            billingHouseNumber={billingHouseNumber}
            billingPostalCode={billingPostalCode}
            billingCity={billingCity}
            billingCountry={billingCountry}
            setBillingCompanyName={setBillingCompanyName}
            setBillingFirstName={setBillingFirstName}
            setBillingLastName={setBillingLastName}
            setBillingStreet={setBillingStreet}
            setBillingHouseNumber={setBillingHouseNumber}
            setBillingPostalCode={setBillingPostalCode}
            setBillingCity={setBillingCity}
            setBillingCountry={setBillingCountry}
            readonly={readonly}
            validateFields={debouncedValidateFields}
            validationErrors={validationErrors}
            getCities={getCities}
          />
        )}

        {!isPrivateClient && (
          <>
            <div className='mb-4'>
              <label className='block text-base font-medium mb-1'>
                {t('approxEmployees')}
                {!readonly && <span className='text-red'>*</span>}
              </label>
              <input
                className={`input-field input-field--regular input-field--h40 lato w-full font-semibold border2 ${readonly ? 'input-blur' : ''} ${validationErrors.approxEmployees ? 'border-red' : ''}`}
                type='number'
                value={approxEmployees}
                onChange={(event) => {
                  debouncedValidateFields('approxEmployees', event.target.value, setApproxEmployees);
                }}
                onWheel={(event) => event.target.blur()} // disables scrolling
                onKeyDown={(event) => preventExponentialInputInNumber(event)}
                readOnly={readonly}
              />
              {validationErrors.approxEmployees && (
                <span className='text-red text-sm'>{validationErrors.approxEmployees}</span>
              )}
            </div>
            <div className='mb-4'>
              <label className='block text-base font-medium mb-1'>
                {t('annualTurnover')}
                {!readonly && <span className='text-red'>*</span>}
              </label>
              <input
                className={`input-field input-field--regular input-field--h40 lato w-full font-semibold border2 ${readonly ? 'input-blur' : ''} ${validationErrors.annualTurnover ? 'border-red' : ''}`}
                type='number'
                value={annualTurnover}
                onChange={(event) => {
                  debouncedValidateFields('annualTurnover', event.target.value, setAnnualTurnover);
                }}
                onWheel={(event) => event.target.blur()} // disables scrolling
                onKeyDown={(event) => preventExponentialInputInNumber(event)}
                readOnly={readonly}
              />
              {validationErrors.annualTurnover && (
                <span className='text-red text-sm'>{validationErrors.annualTurnover}</span>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CustomerData;
