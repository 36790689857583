import { DPuserRoles, ENUM_API_STATUS, ORDER_STATUS, isEmpty } from '@/utils/_gConstant';
import React, { useEffect, useState } from 'react';
import { cancelOrderRequest, getOrderDetailsRequest } from '../store/request';
import {
  setAnnualTurnover,
  setApproxEmployees,
  setConsultingLocation,
  setEnergyCost,
} from '../store/_actions';
import { useDispatch, useSelector } from 'react-redux';

import CancelOrderConfirmation from './CancelOrderConfirmation';
import CustomerData from '../dpForms/Components/CustomerData';
import EnergyCost from '../dpForms/Components/EnergyCost';
import ProductOverview from '../dpForms/Components/ProductOverview';
import { formatAmountInGerman } from '@/utils/utils';
import { toastSuccess } from '@/components/ui-elements/_Toastify';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const OrderSummary = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const leadId = useParams()?.leadId;
  const _guserRole = useSelector((state) => state?.authReducer?.user?.role);

  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [refetchData, setRefetchData] = useState(false);
  const [showCancelOrderConfirmation, setShowCancelOrderConfirmation] = useState(false);

  const getOrderDetails = async () => {
    setIsLoading(true);
    const res = await getOrderDetailsRequest(leadId);
    setIsLoading(false);
    if (res?.status === ENUM_API_STATUS?.SUCCESS_GET_API) {
      setData(res?.data?.data);
      dispatch(setEnergyCost(res?.data?.data?.energy_costs));
      dispatch(setConsultingLocation(res?.data?.data?.consulting_location));
      dispatch(setApproxEmployees(res?.data?.data?.approx_employees));
      dispatch(setAnnualTurnover(res?.data?.data?.annual_turnover));
    }
  };

  useEffect(() => {
    getOrderDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchData]);

  const onCancelOrder = async (id) => {
    const result = await cancelOrderRequest(id);
    if (result.status === ENUM_API_STATUS?.SUCCESS_DELETE_API) {
      setShowCancelOrderConfirmation(false);
      toastSuccess(t('order_summary.order_cancelled_success'));
      setRefetchData(!refetchData);
    }
  };

  const formProductPrices = (prices) => {
    if (isEmpty(prices)) return;
    return prices.map((item) => {
      return {
        id: item.product.id,
        name: item.product.name,
        description: item.product.description,
        quantity: item.quantity,
        actual_price: item.actual_price,
        final_price: item.final_price,
      };
    });
  };

  return (
    <>
      {isLoading && (
        <div className='loader-wrapper'>
          <div className='loader'></div>
        </div>
      )}
      {!isEmpty(data) && (
        <div style={{ width: '75%' }}>
          <div>
            <ProductOverview products={formProductPrices(data.items)} readonly={true} />
          </div>
          <div
            className='mt-5 mb-7 pb-5 pt-5'
            style={{
              borderTop: '1px solid #D1D5DB',
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
            }}
          >
            <div className='mr-3' style={{ fontSize: '15px' }}>
              {t('order_summary.total')}
            </div>
            <div style={{ fontSize: '31px' }}>{formatAmountInGerman(data.total_amount)}</div>
          </div>
          <div>
            <CustomerData readonly={true} />
          </div>
          {!isEmpty(data.energy_costs) && (
            <div className='mb-4'>
              <EnergyCost readonly={true} />
            </div>
          )}
          {[ORDER_STATUS.CANCELLED, ORDER_STATUS.COMPLETED].indexOf(data.status) === -1 &&
            [DPuserRoles.DP_ADMIN, DPuserRoles.DP_MASTER, DPuserRoles.DP_USER].indexOf(
              _guserRole
            ) !== -1 && (
              <div className='w-full flex justify-center mt-4'>
                <button
                  className='btn btn--red btn--h38 px-6 py-2 text-base font-semibold'
                  onClick={() => setShowCancelOrderConfirmation(true)}
                >
                  {t('order_summary.cancel_order')}
                </button>
              </div>
            )}
        </div>
      )}
      <CancelOrderConfirmation
        open={showCancelOrderConfirmation}
        onClose={() => setShowCancelOrderConfirmation(false)}
        onConfirm={() => {
          onCancelOrder(data?.id);
        }}
      />
    </>
  );
};

export default OrderSummary;
