import {
  handleGetDataWithoutAuth,
  handlePutAPIwithout_token,
  handleSignUpAPI,
} from '@/utils/_gFunctions/_handleAPI';

import siteConfig from '@/services/_siteConfig';

export const getQuestionneirList = async () => {
  try {
    const res = await handleGetDataWithoutAuth(siteConfig.QUESTIONNEIR);
    return res;
  } catch (error) {
    console.error('Error in fetching filter options.');
  }
};

export const QuesstionneirListPostRequest = async (body) => {
  try {
    const res = await handleSignUpAPI(siteConfig?.QUESTIONNEIR_RESPONSE, body);
    return res;
  } catch (error) {
    console.log('Error in post request of question API', error);
  }
};

export const contactInformationPostRequest = async (leadId, body) => {
  try {
    const res = await handlePutAPIwithout_token(
      `${siteConfig?.CONTACT_INFORMATION}${leadId}/contact-information/`,
      body
    );
    return res;
  } catch (error) {
    console.log('Erron in the put request of contact API', error);
  }
};
