import { Route, Routes } from 'react-router-dom';

import DPDashboard from '@/pages/DistributionPartner/dpDashboard/DPDashboard';
import DPFormLayout from '@/pages/DistributionPartner/dpForms/DPFormLayout';
import DPOrderConfirmation from '@/pages/DistributionPartner/dpForms/DPOrderConfirmation';
import DPOrderForm from '@/pages/DistributionPartner/dpForms/DPOrderForm';
import DPServices from '@/pages/DistributionPartner/dpForms/DPServices';
import DPUsers from '@/pages/DistributionPartner/dpusers/DPUsers';
import DPUsersProfile from '@/pages/DistributionPartner/dpusers/DPUsersProfile';
import DPuserLeadTable from '@/pages/DistributionPartner/dpDashboard/DPuserLeadTable';
import LeadDetails from '@/pages/Admin/LeadDetails';
import { useTranslation } from 'react-i18next';

const DPRoutes = () => {
  const { t } = useTranslation();
  return (
    <main style={{ backgroundColor: '#F8F9FB' }}>
      <Routes>
        <Route path='/dashboard' element={<DPDashboard title={`${t('dashboard')}`} />} />
        <Route path='order/' element={<DPFormLayout title={`${t('create_order')}`} />}>
          {/* <Route path='' element={<Navigate to='/dp/order/form' />} /> */}
          <Route path='form' element={<DPOrderForm title={`${t('create_order')}`} />} />
          <Route path='services' element={<DPServices title={`${t('create_order')}`} />} />
          <Route
            path='confirmation'
            element={<DPOrderConfirmation title={`${t('create_order')}`} />}
          />
        </Route>
        <Route path='users/' element={<DPUsers title={`${t('user_management')}`}/>} />
        <Route path='users-profile/' element={<DPUsersProfile title='App | IKOSIA' />} />
        <Route path='user-lead-listing/' element={<DPuserLeadTable title='Leads' />} />
        <Route
          path='leads/details/:leadId/'
          element={<LeadDetails title='Lead Info' editIcon={false} />}
        />
      </Routes>
    </main>
  );
};

export default DPRoutes;
