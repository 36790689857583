import React from 'react';
import { useTranslation } from 'react-i18next';

const NewsCard = ({ image, title, description, htmlurl }) => {
  const { t } = useTranslation();
  return (
    <div className='w-full md-col-6 px-2'>
      <div className='h-full px-4 py-5 bg-white rounded-10'>
        <div className='flex flex-col sm-flex-row'>
          <figure
            className='news-pic-container flex-shrink-0 cursor-pointer '
            onClick={() => window.open(htmlurl)}
          >
            <img src={image} alt='news-pic' />
          </figure>
          <div className='ml-0 sm-ml-4 mt-4 sm-mt-0'>
            <h4 className='text-black text-lg font-medium'>{title}</h4>
            <p className='secondary-font text-secondary text-13 font-medium mt-4'>
              {description.split(' ').length > 10
                ? `${description.split(' ').slice(0, 10).join(' ')}...`
                : description}
            </p>
            <a
              href={htmlurl}
              target='_blank'
              className='inline-block secondary-font text-primary-brand text-13 font-medium mt-4'
            >
              {t('news.read_more')}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsCard;
