import { applyMiddleware, compose, createStore } from 'redux'; // Import compose from redux
import { persistReducer, persistStore } from 'redux-persist';

import { rootReducer } from './reducer';
import storage from 'redux-persist/lib/storage';
import { thunk } from 'redux-thunk';

const middleware = [thunk];

const persistConfig = {
  key: 'root', // Update the key to a string
  whitelist: ['authReducer', 'userAnswersReducer','createOrder','dpuserDetails'],
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Use compose to combine applyMiddleware and any other enhancers
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(...middleware))
);

export const persistor = persistStore(store);
