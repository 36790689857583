import React from 'react';
import checkIcon from '../../../assets/icons/check-icon-lg.svg';
import close from '../../../assets/icons/close.svg';

const AppointConfirmed = () => {
  return (
    <section className='modal'>
      <div className='modal__container' style={{ width: 595 }}>
        <div className='modal__header'>
          <h1 className='text-lg font-bold'>Appointment Confirmed!</h1>
          <div className='modal__close'>
            <img src={close} alt='' />
          </div>
        </div>
        <form>
          <div className='modal__body'>
            <div
              className='flex flex-col items-center py-6 px-5 rounded-lg mb-2'
              style={{ backgroundColor: '#EBF5EC' }}
            >
              <img src={checkIcon} alt='check-icon' />
              <p className='secondary-font text-lg leading4 mt-4'>
                We have scheduled your appointment for the following three slots and our team will
                shortly get in touch with you to confirm the appointment date..
              </p>
            </div>
          </div>
          <div className='modal__footer justify-end'>
            <button className='btn btn--secondary btn--h38 px-6 py-2 text-base font-semibold'>
              Finish
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default AppointConfirmed;
