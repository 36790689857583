import '../../assets/styles/scss/pages/admin-dashboard.scss';

import {
  DPuserRoles,
  ENUM_API_STATUS,
  ENUM_LEAD_CREATED_FROM,
  buildingTypeOptions,
  leadStageOptions,
} from '@/utils/_gConstant';
import React, { useCallback, useEffect, useState } from 'react';
import {
  resetData,
  setBillingCity,
  setBillingCompanyName,
  setBillingCountry,
  setBillingFirstName,
  setBillingHouseNumber,
  setBillingLastName,
  setBillingPostalCode,
  setBillingStreet,
  setBuildingApplication,
  setCity,
  setClientType,
  setCompanyName,
  setEmail,
  setFirstName,
  setHouseNumber,
  setLastName,
  setPhone,
  setPostalCode,
  setStreet,
} from '../DistributionPartner/store/_actions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import ActivityHistory from './ActivityHistory';
import AddEditLead from './AddEditLead';
import Document from './Document';
import FormAnswers from './FormAnswers';
import Header from '../DistributionPartner/components/Header';
import LeadInfo from './LeadInfo';
import Notes from './Notes';
import OrderSummary from '../DistributionPartner/components/OrderSummary';
import arrowLeft from '../../assets/icons/arrow-left.svg';
import editWhite from '../../assets/icons/edit-white.svg';
import emailIcon from '../../assets/icons/email-white.svg';
import { formatDate } from '@/utils/_gFunctions/_handleDateFormat';
import { getListDetailsGetRequest } from './_request';
import phoneIcon from '../../assets/icons/phone-white.svg';
import { useTranslation } from 'react-i18next';

const setPropertyAddress = (dispatch, address) => {
  const obj = {
    street: setStreet,
    house_number: setHouseNumber,
    city: setCity,
    zip_code: setPostalCode,
    building_application: setBuildingApplication,
  };

  for (const [key, setter] of Object.entries(obj)) {
    dispatch(setter(address[key]));
  }
};
const setBillingAddress = (dispatch, address) => {
  const obj = {
    first_name: setBillingFirstName,
    last_name: setBillingLastName,
    company: setBillingCompanyName,
    street: setBillingStreet,
    house_number: setBillingHouseNumber,
    zip_code: setBillingPostalCode,
    city: setBillingCity,
    country: setBillingCountry,
  };
  for (const [key, setter] of Object.entries(obj)) {
    dispatch(setter(address[key]));
  }
};

const LeadDetails = (props) => {
  const { t } = useTranslation();
  const { editIcon } = props;
  const dispatch = useDispatch();
  const _guserRole = useSelector((state) => state?.authReducer?.user?.role);
  const leadId = useParams()?.leadId;
  const navigate = useNavigate();
  const [leadData, setLeadData] = useState({});
  const [activeTab, setActiveTab] = useState(t('tabs.leadInfo'));
  const [editModal, setEditModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
  };

  const getLeadData = async () => {
    const res = await getListDetailsGetRequest(leadId);
    if (res?.status === ENUM_API_STATUS?.SUCCESS_GET_API) {
      setLeadData(res?.data);
      dispatch(setCompanyName(res?.data?.company_name));
      dispatch(setFirstName(res?.data?.first_name));
      dispatch(setLastName(res?.data?.last_name));
      dispatch(setPhone(res?.data?.mobile_number));
      dispatch(setEmail(res?.data?.email));
      dispatch(setClientType(res?.data.client_type));
      setPropertyAddress(dispatch, res?.data?.property_address);
      setBillingAddress(dispatch, res?.data?.billing_address);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    document.title = props.title;
    getLeadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.title]);

  const handleEditLeadModal = () => {
    setEditModal(!editModal);
  };

  const updateLead = useCallback(
    (leadData) => {
      setLeadData(leadData);
    },
    [setLeadData]
  );

  const goBack = () => {
    navigate(-1);
    if (!editIcon) {
      dispatch(resetData());
    }
  };

  return (
    <>
      {!editIcon && <Header />}
      {isLoading && (
        <div className='loader-wrapper'>
          <div className='loader'></div>
        </div>
      )}
      {!isLoading && (
        <>
          <section className='admin-dashboard-wrapper'>
            <main className='admin-dashboard__main pb-8 pt-5 px-10' style={{ paddingTop: 88 }}>
              <div className='flex items-center'>
                <div className='breadcrumb__back-icon' onClick={() => goBack()}>
                  <img src={arrowLeft} alt='arrow-left' />
                </div>
                <div className='breadcrumb'>
                  <div className='breadcrumb__list'>{t('breadcrumb.leads')}</div>
                  <div className='breadcrumb__list'>{leadData?.first_name}</div>
                </div>
              </div>
              <div className='flex items-start -mx-4 mt-4'>
                <div className='lead-brief-card px-4'>
                  <div className='lead-brief-card__header'>
                    <div className='flex items-center justify-between'>
                      <h1 className='text-36 font-semibold' style={{ color: '#F2F0E4' }}>
                        {leadData?.first_name}
                      </h1>
                      {editIcon && (
                        <div
                          className='flex items-center justify-center cursor-pointer'
                          onClick={handleEditLeadModal}
                        >
                          <img src={editWhite} alt='edit-white' />
                        </div>
                      )}
                    </div>
                    <p className='text-white opacity8 mt-2'>
                      {t(
                        `${
                          buildingTypeOptions?.find(
                            (option) => option?.value === leadData?.building_type
                          )?.label
                        }`
                      )}
                    </p>
                    <button className='btn btn--tertiary btn--h26 px-4 py-1 mt-3'>
                      {t(
                        `${leadStageOptions.find((option) => option.value === leadData?.stages)?.label}`
                      )}
                    </button>
                    <div className='mt-4'>
                      <div className='flex items-center'>
                        <div className='flex items-center flex-shrink-0 w-5 h-5'>
                          <img src={emailIcon} alt='email' />
                        </div>
                        <span className='secondary-font text-white opacity7 ml-4'>
                          {leadData?.email?.length > 30
                            ? `${leadData?.email.slice(0, 30)}...`
                            : leadData?.email}
                        </span>
                      </div>
                      <div className='flex items-center mt-3'>
                        <div className='flex items-center flex-shrink-0 w-5 h-5'>
                          <img src={phoneIcon} alt='phone' />
                        </div>
                        <span className='secondary-font text-white opacity7 ml-4'>
                          {leadData?.mobile_number}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='lead-brief-card__body'>
                    <div className='pb-4' style={{ borderBottom: '1px solid #d1d5db' }}>
                      <div className='flex items-center justify-between flex-wrap -mx-4 mb-2 mb-not-last-child'>
                        <label className='col-6 px-4 secondary-font text-secondary text-sm font-medium'>
                          {t('breadcrumb.enquiryId')}
                        </label>
                        <span className='col-6 px-4 secondary-font font-semibold text-right'>
                          {leadData?.enq_id}
                        </span>
                      </div>
                      <div className='flex items-center justify-between flex-wrap -mx-4 mb-2 mb-not-last-child'>
                        <label className='col-6 px-4 secondary-font text-secondary text-sm font-medium'>
                          {t('breadcrumb.enquiryDate')}
                        </label>
                        <span className='col-6 px-4 secondary-font font-semibold text-right'>
                          {formatDate(leadData?.enq_date)}
                        </span>
                      </div>
                      {_guserRole === DPuserRoles?.DP_ADMIN && (
                        <div className='flex items-center justify-between flex-wrap -mx-4 mb-2 mb-not-last-child'>
                          <label className='col-6 px-4 secondary-font text-secondary text-sm font-medium'>
                            {t('breadcrumb.consultantName')}
                          </label>
                          <span className='col-6 px-4 secondary-font font-semibold text-right'>
                            {leadData?.consultant_assigned?.first_name ||
                              t('breadcrumb.notAssigned')}
                          </span>
                        </div>
                      )}
                      <div className='flex items-center justify-between flex-wrap -mx-4 mb-2 mb-not-last-child'>
                        <label className='col-6 px-4 secondary-font text-secondary text-sm font-medium'>
                          {t('breadcrumb.country')}
                        </label>
                        <span className='col-6 px-4 secondary-font font-semibold text-right'>
                          {leadData?.country}
                        </span>
                      </div>
                      <div className='flex items-center justify-between flex-wrap -mx-4 mb-2 mb-not-last-child'>
                        <label className='col-6 px-4 secondary-font text-secondary text-sm font-medium'>
                          {t('breadcrumb.city')}
                        </label>
                        <span className='col-6 px-4 secondary-font font-semibold text-right'>
                          {leadData?.city}
                        </span>
                      </div>
                      <div className='flex items-center justify-between flex-wrap -mx-4 mb-2 mb-not-last-child'>
                        <label className='col-6 px-4 secondary-font text-secondary text-sm font-medium'>
                          {t('breadcrumb.postcode')}
                        </label>
                        <span className='col-6 px-4 secondary-font font-semibold text-right'>
                          {leadData?.postal_code}
                        </span>
                      </div>
                    </div>
                    <div className='tags mt-3'>
                      {leadData?.tags?.map((tag) => (
                        <div className='tags__list'>
                          {tag.length > 30 ? `${tag.slice(0, 30)}...` : tag}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className='lead-main-info px-4'>
                  <div className='tab-btn-wrapper'>
                    <input
                      type='radio'
                      id='tabBtn1'
                      className='tab-btn__input'
                      name='tab-btn'
                      checked={activeTab === t('tabs.leadInfo')}
                      onChange={() => handleTabChange(t('tabs.leadInfo'))}
                    />
                    <label htmlFor='tabBtn1' className='tab-btn'>
                      {t('tabs.leadInfo')}
                    </label>
                    <input
                      type='radio'
                      id='tabBtn2'
                      className='tab-btn__input'
                      name='tab-btn'
                      checked={activeTab === t('tabs.formAnswers')}
                      onChange={() => handleTabChange(t('tabs.formAnswers'))}
                    />
                    {/* <label htmlFor='tabBtn2' className='tab-btn'>
                      {t('tabs.formAnswers')}
                    </label> */}
                    <input
                      type='radio'
                      id='tabBtn3'
                      className='tab-btn__input'
                      name='tab-btn'
                      checked={activeTab === t('tabs.document')}
                      onChange={() => handleTabChange(t('tabs.document'))}
                    />
                    {/* <label htmlFor='tabBtn3' className='tab-btn'>
                      {t('tabs.document')}
                    </label> */}
                    <input
                      type='radio'
                      id='tabBtn4'
                      className='tab-btn__input'
                      name='tab-btn'
                      checked={activeTab === t('tabs.activityHistory')}
                      onChange={() => handleTabChange(t('tabs.activityHistory'))}
                    />
                    {/* <label htmlFor='tabBtn4' className='tab-btn'>
                      {t('tabs.activityHistory')}
                    </label> */}
                    <input
                      type='radio'
                      id='tabBtn5'
                      className='tab-btn__input'
                      name='tab-btn'
                      checked={activeTab === t('tabs.notes')}
                      onChange={() => handleTabChange(t('tabs.notes'))}
                    />
                    {/* <label htmlFor='tabBtn5' className='tab-btn'>
                      {t('tabs.notes')}
                    </label> */}
                    {leadData?.created_from?.type === ENUM_LEAD_CREATED_FROM?.DISTRIBUTOR && (
                      <>
                        <input
                          type='radio'
                          id='tabBtn6'
                          className='tab-btn__input'
                          name='tab-btn'
                          checked={activeTab === t('tabs.orderSummary')}
                          onChange={() => handleTabChange(t('tabs.orderSummary'))}
                        />
                        <label htmlFor='tabBtn6' className='tab-btn'>
                          {t('tabs.orderSummary')}
                        </label>
                      </>
                    )}
                  </div>
                  <div className='mt-10 px-6'>
                    {activeTab === t('tabs.orderSummary') && <OrderSummary />}
                    {activeTab === t('tabs.leadInfo') && <LeadInfo leadData={leadData} />}
                    {activeTab === t('tabs.formAnswers') && <FormAnswers />}
                    {activeTab === t('tabs.document') && <Document />}
                    {activeTab === t('tabs.activityHistory') && <ActivityHistory />}
                    {activeTab === t('tabs.notes') && <Notes />}
                  </div>
                </div>
              </div>
            </main>
          </section>
          {editModal && (
            <AddEditLead
              onClose={handleEditLeadModal}
              modalData={{ lead: leadData }}
              refreshList={getLeadData}
              updateLead={updateLead}
            />
          )}
        </>
      )}
    </>
  );
};
export default LeadDetails;
