import close from '../../assets/icons/close.svg';
import trashIcon from '../../assets/icons/trash-lg.svg';

const DeleteDP = (props) => {
  const { deleteDPuser, setShowDeleteModal, handleDelete } = props;
  const deletefunction = () => {
    handleDelete(deleteDPuser?.id);
    setShowDeleteModal(false);
  };
  return (
    <section className={`modal modal--delete-user open`}>
      <div className='modal__container' style={{ width: 550 }}>
        <div className='modal__header'>
          <h1 className='text-lg font-bold'>Are you sure?</h1>
          <div
            className='modal__close'
            onClick={() => {
              setShowDeleteModal(false);
            }}
          >
            <img src={close} alt='close' />
          </div>
        </div>
        <div className='modal__body' style={{ backgroundColor: '#FFF8F8' }}>
          <div className='flex flex-col items-center'>
            <img src={trashIcon} alt='trash' />
            <p className='font-medium mt-5'>
              Do you want to delete&nbsp;
              <span className='font-bold'>{deleteDPuser?.first_name}</span>? If yes, click 'delete.'
            </p>
          </div>
        </div>
        <div className='modal__footer justify-end'>
          <button
            className='btn btn--secondary btn--h40 px-6 py-2 text-base font-semibold'
            onClick={() => {
              setShowDeleteModal(false);
            }}
          >
            Cancel
          </button>
          <button
            className='btn btn--red btn--h40 px-6 py-2 text-base font-semibold'
            onClick={deletefunction}
          >
            Delete
          </button>
        </div>
      </div>
    </section>
  );
};

export default DeleteDP;
