import {
  handleImportConsultantFilesRequest,
  handleImportLeadFilesRequest,
  sampleExportConsultantFileRequest,
  sampleExportLeadFileRequest,
} from './_request';
import { toastErrors, toastSuccess } from '../../components/ui-elements/_Toastify';

import { ENUM_API_STATUS } from '../../utils/_gConstant';
import close from '../../assets/icons/close.svg';
import downloadColor from '../../assets/icons/download-color.svg';
import fileUpload from '../../assets/icons/file-upload.svg';
import { useState } from 'react';

const UploadFile = (props) => {
  const { toggleFileUploadModal, leadCard, energyConsultant } = props;
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      // Update state or perform actions with the selected file
      setSelectedFile(file);
    }
  };

  const handleSampleFileRequest = async () => {
    try {
      let apiRequest;
      let fileName;

      if (energyConsultant && !leadCard) {
        apiRequest = sampleExportConsultantFileRequest();
        fileName = 'consultant_file.csv';
      } else {
        apiRequest = sampleExportLeadFileRequest();
        fileName = 'lead_file.csv';
      }

      const res = await apiRequest;

      if (res?.status === ENUM_API_STATUS.SUCCESS_GET_API) {
        const blob = new Blob([res.data], { type: 'application/octet-stream' });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        toastErrors(res?.data);
      }
    } catch (error) {
      console.error('Error in sample file request:', error);
    }
  };

  const handleFileUploadRequest = async () => {
    if (selectedFile) {
      if (leadCard === false && energyConsultant === true) {
        const res = await handleImportConsultantFilesRequest(selectedFile);
        if (res?.status === ENUM_API_STATUS?.SUCCESS_POST_API_200) {
          toastSuccess('File uploaded successfully');
          toggleFileUploadModal();
          window.location.reload(true);
        }
      } else {
        const res = await handleImportLeadFilesRequest(selectedFile);
        if (res?.status === ENUM_API_STATUS?.SUCCESS_POST_API_200) {
          toastSuccess('File uploaded successfully');
          toggleFileUploadModal();
          window.location.reload(true);
        }
      }
    }
  };

  return (
    <section className='modal modal--upload-file open'>
      <div className='modal__container'>
        <div className='modal__header' style={{ border: 'none' }}>
          <h1 className='text-lg font-bold'>Import</h1>
          <div className='modal__close' onClick={() => toggleFileUploadModal()}>
            <img src={close} alt='close' />
          </div>
        </div>
        <div className='modal__body'>
          <div className='file-upload-wrapper'>
            <input
              type='file'
              id='fileUpload'
              className='file-upload__input'
              onChange={handleFileChange}
            />
            <label
              htmlFor='fileUpload'
              className='file-upload-container flex flex-col items-center justify-center'
            >
              <img src={fileUpload} alt='file-upload-icon' />
              <h4 className='font-medium mt-4'>Upload file</h4>
              <p className='color-grey2 text-xs mt-2'>CSV, file size no more than 10MB</p>
            </label>
          </div>
          {selectedFile && (
            <div className='selected-file'>
              <p className='font-medium mt-4'>{selectedFile.name}</p>
            </div>
          )}
          <div className='flex items-center mt-4' onClick={handleSampleFileRequest}>
            <img src={downloadColor} alt='download-icon' style={{ marginTop: -2 }} />
            <span className='text-primary-brand text-sm font-medium ml-2 cursor-pointer'>
              Download sample file
            </span>
          </div>
        </div>
        <div className='modal__footer justify-end'>
          <button
            className='btn btn--secondary-brand text-base px-8 py-3'
            onClick={handleFileUploadRequest}
          >
            Done
          </button>
        </div>
      </div>
    </section>
  );
};

export default UploadFile;
