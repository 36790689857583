import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LoginQuestionner from './LoginQuestionner';
import { surveyTypeOptions } from '../../utils/_gConstant';
import { useSearchParams } from 'react-router-dom';
import useThrottle from '@/utils/_gFunctions/handleDebouncing';

const QuestionnaireSection = ({
  currentQuestionIndex,
  currentQuestionSubIndex,
  setUserAnswers,
  userAnswers,
  questionsData,
  handleNext,
  setSelectedOptionId,
  onSelectedOptionChange,
  reachedLastQuestion,
  showQna,
  setShowQna,
  responseLeadId,
  leadId,
}) => {
  const [inputValid, setInputValid] = useState(false);
  const dispatch = useDispatch();
  const userAnswersFromRedux = useSelector((state) => state?.userAnswersReducer?.userAnswers);
  const leadIDredux = useSelector((state) => state?.userAnswersReducer?.leadId);
  // eslint-disable-next-line no-unused-vars
  const [_, setSearchQueryParams] = useSearchParams();

  useEffect(() => {
    const surveyTypeId =
      questionsData[currentQuestionIndex]?.questions[currentQuestionSubIndex]
        ?.energy_calculate_params;
    const surveyType = surveyTypeOptions[surveyTypeId]?.label;
    if (surveyType) {
      setSearchQueryParams((params) => ({ type: surveyType, ...params }));
    } else {
      setSearchQueryParams((params) => {
        const updatedParams = { ...params };
        delete updatedParams?.type;
        return updatedParams;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuestionSubIndex, currentQuestionIndex, questionsData]);

  useEffect(() => {
    const questionId = questionsData[currentQuestionIndex]?.questions[currentQuestionSubIndex]?.id;
    const value = userAnswersFromRedux[questionId];
    if (leadId === leadIDredux || leadId === 'true') {
      if (value !== userAnswers.value) {
        setUserAnswers({
          id: questionId,
          value: value,
        });

        setInputValid(!!value);
      }
    } else {
      dispatch({ type: 'RESET_USER_ANSWERS' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userAnswersFromRedux,
    questionsData,
    currentQuestionIndex,
    currentQuestionSubIndex,
    leadId,
    leadIDredux,
  ]);

  const handleRadioChange = (optionId) => {
    setSelectedOptionId(optionId);
    onSelectedOptionChange(optionId);
    setInputValid(true);
    setUserAnswers({
      id: questionsData[currentQuestionIndex]?.questions[currentQuestionSubIndex]?.id,
      value: optionId,
    });
  };
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setUserAnswers({
      id: questionsData[currentQuestionIndex]?.questions[currentQuestionSubIndex]?.id,
      value: inputValue,
    });
    setInputValid(!!inputValue.trim());
  };
  useEffect(() => {
    const handleEnterKey = (e) => {
      if (e.key === 'Enter' && inputValid) {
        setShowQna(false);
        handleNext();
        setInputValid(false);
      }
    };

    document.addEventListener('keydown', handleEnterKey);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener('keydown', handleEnterKey);
    };
  }, [inputValid, setShowQna, handleNext]);
  const handleButtonClick = () => {
    setShowQna(false);
    handleNext();
    setInputValid(false);
  };
  const throttledHandleClick = useThrottle(handleButtonClick, 300); 
  const renderCurrentQuestion = () => {
    const currentCategory = questionsData[currentQuestionIndex];

    
    return (
      <>
        {currentCategory &&
          (reachedLastQuestion ? (
            <>
              <LoginQuestionner showQna={showQna} responseLeadId={responseLeadId} />
            </>
          ) : (
            <Fragment key={currentCategory.id}>
              <div className={`qna-container pt-10 ${showQna ? 'show' : ''}`}>
                <h2 className='heading-level2 leading4'>
                  {currentCategory.questions[currentQuestionSubIndex]?.text}
                </h2>
                <p className='text-secondary font-regular mt-1'>
                  {currentCategory.questions[currentQuestionSubIndex]?.hint}
                </p>
                {currentCategory.questions[currentQuestionSubIndex]?.option &&
                  currentCategory.questions[currentQuestionSubIndex]?.option.length > 0 &&
                  currentCategory.questions[currentQuestionSubIndex]?.option[0].kind === 1 && (
                    <div className='mt-8'>
                      <input
                        type='text'
                        className='input-field input-field--md input-field--h52 qna-container__input-field lato font-medium'
                        placeholder={
                          currentCategory.questions[currentQuestionSubIndex]?.placeholder ||
                          'Deine Antwort'
                        }
                        style={{ width: '33%' }}
                        value={userAnswers.value}
                        onChange={(e) => handleInputChange(e)}
                      />
                    </div>
                  )}
                {currentCategory.questions[currentQuestionSubIndex]?.option &&
                  currentCategory.questions[currentQuestionSubIndex]?.option.length > 0 &&
                  currentCategory.questions[currentQuestionSubIndex]?.option[0].kind === 2 && (
                    <div className='mt-6 flex flex-wrap'>
                      {currentCategory.questions[currentQuestionSubIndex]?.option.map((option) => (
                        <Fragment key={option?.id}>
                          {option?.image_url ? (
                            <div className='home-angle-type__col col-3 px-2 mb-3'>
                              <div className=''>
                                <input
                                  type='radio'
                                  id={`radioBtn${option?.id}`}
                                  className='home-angle-type__input'
                                  name={`custom-radio${currentQuestionSubIndex}`}
                                  value={option?.text}
                                  checked={userAnswers.value === option?.id}
                                  onChange={() => handleRadioChange(option?.id)}
                                />
                                <label
                                  htmlFor={`radioBtn${option?.id}`}
                                  className='home-angle-type'
                                >
                                  <img
                                    src={option?.image_url}
                                    alt='home-angle-type'
                                    className='home-angle-type__pic'
                                  />
                                  <span className='home-angle-type__title'>{option?.text}</span>
                                </label>
                              </div>
                            </div>
                          ) : (
                            <div className='w-full' style={{ marginBottom: 18 }}>
                              <input
                                type='radio'
                                id={`radio${option?.id}`}
                                name={`yesno${currentQuestionSubIndex}`}
                                className='radio__input'
                                checked={userAnswers.value === option?.id}
                                onChange={() => handleRadioChange(option?.id)}
                              />
                              <label htmlFor={`radio${option?.id}`} className='radio-label md'>
                                <code className='radio__circle'></code>
                                <span className='text-lg font-medium ml-4'>{option?.text}</span>
                              </label>
                            </div>
                          )}
                        </Fragment>
                      ))}
                    </div>
                  )}
              </div>
              <div className='qna-container__btn-next-wrapper'>
                <button
                  className={`btn btn--primary btn--h52 btn--md qna-container__btn-next text-base ${
                    !inputValid ? 'disabled' : ''
                  }`}
                  style={{ minWidth: 180 }}
                  onClick={throttledHandleClick}
                  disabled={!inputValid}
                >
                  {currentQuestionSubIndex === 0
                    ? 'Weiter'
                    : currentQuestionSubIndex === currentCategory?.questions?.length - 1 &&
                        leadId !== 'true'
                      ? 'Einsparpotenzial anschauen'
                      : 'Nächste Frage'}
                </button>
              </div>
            </Fragment>
          ))}
      </>
    );
  };

  return renderCurrentQuestion();
};

export default QuestionnaireSection;
