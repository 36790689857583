import * as Yup from 'yup';

import React, { useState } from 'react';
import { toastErrors, toastSuccess } from '../../ui-elements/_Toastify';

import Cookies from 'universal-cookie';
import { ENUM_API_STATUS } from '../../../utils/_gConstant';
import { changePasswordRequest } from '../core/_request';
import close from '../../../assets/icons/close.svg';
import eyeClosed from '../../../assets/icons/eye-close.png';
import eyeOpen from '../../../assets/icons/eye-open.png';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';

const ChangePassword = (props) => {
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { closeModal } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const validationSchema = Yup.object().shape({
    current_password: Yup.string().required('Aktuelles Passwort ist erforderlich'),
    new_password: Yup.string().required('Neues Passwort ist erforderlich'),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('new_password'), null], 'Die Passwörter müssen übereinstimmen')
      .required('Bestätigen Sie, dass ein Passwort erforderlich ist'),
  });

  const formik = useFormik({
    initialValues: {
      current_password: '',
      new_password: '',
      confirm_password: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      const res = await changePasswordRequest(values);
      if (res?.status === ENUM_API_STATUS?.SUCCESS_PATCH_API) {
        toastSuccess('Password changed successfully ');
        closeModal();
        localStorage.clear();
        dispatch({ type: 'USER_LOGOUT' });
        cookies.remove('dashboard', {
          domain: process.env.REACT_APP_DOMAIN,
          path: '/',
        });

        cookies.remove('userName', {
          domain: process.env.REACT_APP_DOMAIN,
          path: '/',
        });
        navigate('/');
      } else {
        toastErrors(res?.data);
      }
    },
  });

  return (
    <section className='modal modal--new-user open'>
      <div className='modal__container'>
        <div className='modal__header'>
          <h1 className='text-lg font-bold'>Kennwort ändern</h1>
          <div className='modal__close' onClick={closeModal}>
            <img src={close} alt='' />
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className='modal__body'>
            <div className='input-field-wrapper'>
              <label className='input-field-label'>Aktuelles Passwort</label>
              <div className='relative'>
                <input
                  type={showPassword ? 'text' : 'password'}
                  name='current_password'
                  className='input-field input-field--regular input-field--password input-field--h40 w-full lato'
                  placeholder='********'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.current_password}
                />
                {showPassword ? (
                  <img
                    src={eyeOpen}
                    style={{ height: 14 }}
                    alt='eye'
                    className='eye-icon'
                    onClick={() => {
                      setShowPassword(!showPassword);
                      formik.setFieldValue('current_password', formik.values.current_password);
                    }}
                  />
                ) : (
                  <img
                    src={eyeClosed}
                    style={{ height: 15 }}
                    alt='eye'
                    className='eye-icon'
                    onClick={() => {
                      setShowPassword(!showPassword);
                      formik.setFieldValue('current_password', formik.values.current_password);
                    }}
                  />
                )}
              </div>
              {formik.touched.current_password && formik.errors.current_password ? (
                <div className='error-message'>{formik.errors.current_password}</div>
              ) : null}
            </div>
            <div className='input-field-wrapper'>
              <label className='input-field-label'>Neues Kennwort</label>
              <div className='relative'>
                <input
                  type={showNewPassword ? 'text' : 'password'}
                  name='new_password'
                  className='input-field input-field--regular input-field--password input-field--h40 w-full lato'
                  placeholder='********'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.new_password}
                />
                {showNewPassword ? (
                  <img
                    src={eyeOpen}
                    style={{ height: 14 }}
                    alt='eye'
                    className='eye-icon'
                    onClick={() => {
                      setShowNewPassword(!showNewPassword);
                      formik.setFieldValue('new_password', formik.values.new_password);
                    }}
                  />
                ) : (
                  <img
                    src={eyeClosed}
                    style={{ height: 15 }}
                    alt='eye'
                    className='eye-icon'
                    onClick={() => {
                      setShowNewPassword(!showNewPassword);
                      formik.setFieldValue('new_password', formik.values.new_password);
                    }}
                  />
                )}
              </div>
              {formik.touched.new_password && formik.errors.new_password ? (
                <div className='error-message'>{formik.errors.new_password}</div>
              ) : null}
            </div>

            <div className='input-field-wrapper'>
              <label className='input-field-label'>Bestätige das Passwort</label>
              <div className='relative'>
                <input
                  type={showConfirmPassword ? 'text' : 'password'}
                  name='confirm_password'
                  className='input-field input-field--regular input-field--password input-field--h40 w-full lato'
                  placeholder='********'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.confirm_password}
                />
                {showConfirmPassword ? (
                  <img
                    src={eyeOpen}
                    style={{ height: 14 }}
                    alt='eye'
                    className='eye-icon'
                    onClick={() => {
                      setShowConfirmPassword(!showConfirmPassword);
                      formik.setFieldValue('confirm_password', formik.values.confirm_password);
                    }}
                  />
                ) : (
                  <img
                    src={eyeClosed}
                    style={{ height: 15 }}
                    alt='eye'
                    className='eye-icon'
                    onClick={() => {
                      setShowConfirmPassword(!showConfirmPassword);
                      formik.setFieldValue('confirm_password', formik.values.confirm_password);
                    }}
                  />
                )}
              </div>
              {formik.touched.confirm_password && formik.errors.confirm_password ? (
                <div className='error-message'>{formik.errors.confirm_password}</div>
              ) : null}
            </div>
          </div>
          <div className='modal__footer justify-end'>
            <button
              className='btn btn--secondary btn--h40 px-6 py-2 text-base font-semibold'
              onClick={closeModal}
            >
              Stornieren
            </button>
            <button
              type='submit'
              className='btn btn--primary btn--h40 px-6 py-2 text-base font-semibold'
            >
              Bestätigen
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default ChangePassword;
