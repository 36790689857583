export const selectConsultant = (consultant) => ({
  type: 'SELECT_CONSULTANT',
  payload: consultant,
});
export const selectedLead = (lead) => ({
  type: 'SELECT_LEAD',
  payload: lead,
});

export const editListViewLead = (lead) => ({
  type: 'SELECT_LIST_VIEW_LEAD',
  payload: lead,
});

export const selecteditConsultant = (consultantData) => ({
  type: 'SELECT_EDIT_CONSULTANT',
  payload: consultantData,
});

export const selectedTagName = (tagname) => ({
  type: 'SELECT_TAG_NAME',
  payload: tagname,
});
