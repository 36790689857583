import '../../../assets/styles/scss/pages/dp-dashboard.scss';

import { CLIENT_TYPE, ENUM_API_STATUS, isEmpty } from '@/utils/_gConstant';
import React, { useEffect, useState } from 'react';
import { calculatePricePostRequest, createOrderPostRequest } from '../store/request';
import { resetData, setProducts } from '../store/_actions';
import { useDispatch, useSelector } from 'react-redux';

import CustomerData from './Components/CustomerData';
import EnergyCost from './Components/EnergyCost';
import Loader from '@/components/common/Loader';
import ProductOverview from './Components/ProductOverview';
import { formatAmountInGerman } from '@/utils/utils';
import { toastSuccess } from '@/components/ui-elements/_Toastify';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const DPOrderConfirmation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.createOrder);
  const { products = [], residentialUnits, clientType } = useSelector((state) => state.createOrder);

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [showBilling, setShowBilling] = useState(false);
  const [hasEnergyCostError, setHasEnergyCostError] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [prices, setPrices] = useState();

  const calculatePrices = async () => {
    setInProgress(true);
    const data = {
      residential_units: residentialUnits,
      products,
      category: clientType,
    };
    const result = await calculatePricePostRequest(data);
    setInProgress(false);
    if (result?.status === ENUM_API_STATUS?.SUCCESS_GET_API) {
      setPrices(result.data.data);
    }
  };

  useEffect(() => {
    calculatePrices();
    // eslint-disable-next-line
  }, [products]);

  const totalServicesCount = () => {
    return products.reduce((total, item) => total + item.quantity, 0);
  };

  const isPrivateClient = data.clientType === CLIENT_TYPE[0].value;

  const onCreateOrder = async () => {
    setIsLoading(true);
    const privateClientData = {
      client_type: data.clientType,
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      mobile_number: data.phone,
      country: data.country.label,
      city: data.city.label,
      postal_code: data.postalCode,
      products: data.products.map((item) => {
        return {
          id: item.id,
          quantity: item.quantity,
        };
      }),
      residential_units: data.residentialUnits,
      consulting_location: data.consultingLocation,

      // total_amount: calculatePrice().totalPrice,
      // service_fee: calculatePrice().serviceFee,
      property_address: {
        first_name: data.firstName,
        last_name: data.lastName,
        street: data.street,
        house_number: data.houseNumber,
        zip_code: data.postalCode,
        city: data.city.label,
        country: data.country.label,
        company: data.companyName,
        building_application: data.buildingApplication,
      },
      ...(showBilling
        ? {
            billing_address: {
              first_name: data.billingFirstName,
              last_name: data.billingLastName,
              company: data.billingCompanyName,
              street: data.billingStreet,
              house_number: data.billingHouseNumber,
              zip_code: data.billingPostalCode,
              city: data.billingCity.label,
              country: data.billingCountry.label,
            },
          }
        : {}),
    };
    const businessClientData = {
      company_name: data.companyName,
      annual_energy_cost: data.annualEnergyCost,
      annual_turnover: data.annualTurnover,
      approx_employees: data.approxEmployees,
      energy_costs: data.energy_costs,
    };

    const formData = isPrivateClient
      ? privateClientData
      : { ...privateClientData, ...businessClientData };

    const res = await createOrderPostRequest(formData);
    setIsLoading(false);
    if (res?.status === ENUM_API_STATUS?.SUCCESS_POST_API) {
      dispatch(resetData());
      toastSuccess(`${t('order_created_successfully')}`);
      navigate('/dp/dashboard');
    }
  };

  const isButtonDisabled = () => {
    const privateClientFields =
      isEmpty(data.firstName) ||
      isEmpty(data.lastName) ||
      isEmpty(data.phone) ||
      isEmpty(data.email) ||
      isEmpty(data.street) ||
      isEmpty(data.postalCode) ||
      isEmpty(data.houseNumber) ||
      isEmpty(data.city.value) ||
      isEmpty(data.country.value) ||
      (showBilling &&
        (isEmpty(data.billingFirstName) ||
          isEmpty(data.billingLastName) ||
          isEmpty(data.billingStreet) ||
          isEmpty(data.billingHouseNumber) ||
          isEmpty(data.billingPostalCode) ||
          isEmpty(data.billingCountry.value) ||
          isEmpty(data.billingCity.value)));
    const businessClientFields =
      isEmpty(data.companyName) || isEmpty(data.approxEmployees) || isEmpty(data.annualTurnover);
    return isPrivateClient ? privateClientFields : privateClientFields || businessClientFields;
  };

  const onDeleteProduct = (id) => {
    const _products = [...products];
    const index = _products.findIndex((item) => item.id === id);
    _products.splice(index, 1);
    
    dispatch(setProducts(_products));
    // calculatePrices();
    if (products?.length === 1) {
      navigate('/dp/order/services');
    }
  };

  const formProductPrices = (prices, selectedProducts) => {
    if (isEmpty(prices)) return;
    return selectedProducts.map((item) => {
      return {
        id: item.id,
        ...prices[item.id],
      };
    });
  };

  if (inProgress) {
    return <Loader />;
  }

  return (
    <div className='w-full xl-col-10 mx-auto mt-5'>
      <div className='flex dp-checkout__content-wrapper'>
        <div className='dp-checkout__content-left'>
          <ProductOverview
            products={formProductPrices(prices?.products, products)}
            readonly={false}
            onDelete={(id) => onDeleteProduct(id)}
          />
          <CustomerData
            readonly={false}
            setHasError={setHasError}
            setShowBilling={setShowBilling}
          />
          {!isPrivateClient && <EnergyCost readonly={false} setHasError={setHasEnergyCostError} />}
        </div>
        <div className='dp-checkout__content-right'>
          <div className='bg-white rounded-xl p-6'>
            <h2 className='text-32 font-medium'>{t('dpOrderConfirmation.orderOverview')}</h2>
            <div className='mt-8 pb-4' style={{ borderBottom: '1px solid #D1D5DB' }}>
              <div className='flex items-center justify-between -mx-2'>
                <div className='col-8 px-2 text-black text-base font-medium'>
                  {t('dpOrderConfirmation.totalServices')} ({totalServicesCount()})
                </div>
                <div className='col-4 px-2 font-medium text-base text-right'>
                  {formatAmountInGerman(prices?.price_breakup.product_total)}
                </div>
              </div>
              <div className='flex items-center justify-between -mx-2 mt-4'>
                <div className='col-8 px-2 text-black text-base font-medium'>
                  {t('dpOrderConfirmation.salesTax')}
                </div>
                <div className='col-4 px-2 font-medium text-base text-right'>
                  {formatAmountInGerman(prices?.price_breakup.sales_tax)}
                </div>
              </div>
              <div className='flex items-center justify-between -mx-2 mt-4'>
                <div className='col-8 px-2 text-black text-base font-medium'>
                  <strong>{t('dpOrderConfirmation.grossFee')}</strong>
                </div>
                <div className='col-4 px-2 font-medium text-base text-right'>
                  <strong>{formatAmountInGerman(prices?.price_breakup.gross_amount)}</strong>
                </div>
              </div>
              <div className='flex items-center justify-between -mx-2 mt-4'>
                <div className='col-8 px-2 text-black text-base font-medium'>
                  {t('dpOrderConfirmation.bafaFunding')}
                </div>
                <div className='col-4 px-2 font-medium text-base text-right'>
                  -{formatAmountInGerman(prices?.price_breakup.bafa_subsidy)}
                </div>
              </div>
            </div>
            <div className='flex items-center justify-between -mx-2 mt-5'>
              <div className='text-black text-xl font-medium'>
                {t('dpOrderConfirmation.totalPrice')}
              </div>
              <div className='text-xl font-medium text-right'>
                {formatAmountInGerman(prices?.price_breakup.total_amount)}
              </div>
            </div>
            <button
              className={`btn btn--primary btn--h52 w-full text-base px-4 py-2 mt-8 relative ${isButtonDisabled() || isLoading || hasError || hasEnergyCostError ? 'disabled' : ''}`}
              onClick={() => onCreateOrder()}
              disabled={isButtonDisabled() || isLoading || hasError || hasEnergyCostError}
            >
              {isLoading ? <Loader white size='xs' /> : `${t('dpOrderConfirmation.createOrder')}`}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DPOrderConfirmation;
