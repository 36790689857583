import React from 'react';
import close from '@/icons/close.svg';
import trashIcon from '@/icons/trash-lg.svg';
import { useTranslation } from 'react-i18next';

const CancelOrderConfirmation = (props) => {
  const { open, onClose, onConfirm } = props;
  const { t } = useTranslation();

  return (
    <section className={`modal modal--delete-user ${open ? 'open' : ''}`}>
      <div className='modal__container' style={{ width: 550 }}>
        <div className='modal__header'>
          <h1 className='text-lg font-bold'>{t('are_you_sure')}</h1>
          <div className='modal__close' onClick={onClose}>
            <img src={close} alt='close' />
          </div>
        </div>
        <div className='modal__body' style={{ backgroundColor: '#FFF8F8' }}>
          <div className='flex flex-col items-center'>
            <img src={trashIcon} alt='trash' />
            <p className='font-medium mt-5'>{t('cancel_order_confirmation')}</p>
          </div>
        </div>
        <div className='modal__footer justify-end'>
          <button
            className='btn btn--secondary btn--h40 px-6 py-2 text-base font-semibold'
            onClick={onClose}
          >
            {t('no')}
          </button>
          <button
            className='btn btn--red btn--h40 px-6 py-2 text-base font-semibold'
            onClick={onConfirm}
          >
            {t('yes')}
          </button>
        </div>
      </div>
    </section>
  );
};

export default CancelOrderConfirmation;
