import React, { useState } from 'react';

import EditMyProfile from './EditMyProfile';
import { UserRolesOptions } from '../../../utils/_gConstant';
import close from '../../../assets/icons/close-grey.svg';
import emailIcon from '../../../assets/icons/email-white.svg';
import phoneIcon from '../../../assets/icons/phone-white.svg';
import { useSelector } from 'react-redux';

const MyProfile = (props) => {
  const { closeModal } = props;
  const user = useSelector((state) => state?.authReducer?.user);
  const [editProfileVisibility, setEditProfileVisibility] = useState(false);
  const toggleEditMyProfile = () => {
    setEditProfileVisibility(!editProfileVisibility);
  };

  return (
    <>
      <section className='modal modal--my-profile open'>
        <div className='modal__container'>
          <div className='modal__header' style={{ backgroundColor: '#00381B', padding: '16px' }}>
            <div className='w-full'>
              <div
                className='flex items-center justify-center w-5 h-5 ml-auto cursor-pointer'
                onClick={closeModal}
              >
                <img src={close} alt='close-icon' />
              </div>
              <div className='flex items-start mt-4 truncate'>
                {/*<figure*/}
                {/*  className="avatar-container avatar-container--cover"*/}
                {/*  style={{ width: 70, height: 70 }}*/}
                {/*>*/}
                {/*  <img*/}
                {/*    src="https://images.unsplash.com/photo-1704303928271-775bb222ab46?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"*/}
                {/*    alt="profile"*/}
                {/*  />*/}
                {/*</figure>*/}
                <div className='ml-6 truncate'>
                  <h2 className='color-tertiary-brand text-36 font-semibold word-break-all truncate'>
                    {user?.first_name}
                  </h2>
                  <div
                    className='inline-flex items-center justify-center secondary-font text-sm font-medium py-1 px-4 mt-2 rounded-2xl truncate'
                    style={{ backgroundColor: '#FED07B' }}
                  >
                    {UserRolesOptions.find((option) => option.value === user?.kind)?.label}
                  </div>
                </div>
              </div>
              <div className='flex items-center mt-4'>
                <div className='flex items-center justify-center flex-shrink-0 w-5 h-5'>
                  <img src={emailIcon} alt='email' />
                </div>
                <span className='secondary-font text-white leading2 opacity7 ml-4'>
                  {user?.email}
                </span>
              </div>
              <div className='flex items-center mt-2'>
                <div className='flex items-center justify-center flex-shrink-0 w-5 h-5'>
                  <img src={phoneIcon} alt='email' style={{ maxHeight: 14 }} />
                </div>
                <span className='secondary-font text-white leading2 opacity7 ml-4'>
                  {user?.mobile_number}
                </span>
              </div>
            </div>
          </div>
          <div className='modal__body' style={{ padding: 16 }}>
            <div className='flex flex-wrap -mx-4'>
              {/*<div className="col-6 secondary-font color-grey1 text-sm font-medium px-4 mb-4">*/}
              {/*Angestellten ID*/}
              {/*</div>*/}
              {/*<div className="col-6 secondary-font text-sm font-semibold text-right px-4 mb-4">*/}
              {/*  12652*/}
              {/*</div>*/}
              <div className='col-6 secondary-font color-grey1 text-sm font-medium px-4 mb-4'>
                Land
              </div>
              <div className='col-6 secondary-font text-sm font-semibold text-right px-4 mb-4'>
                {user?.country}
              </div>
              <div className='col-6 secondary-font color-grey1 text-sm font-medium px-4 mb-4'>
                Stadt
              </div>
              <div className='col-6 secondary-font text-sm font-semibold text-right px-4 mb-4'>
                {user?.city}
              </div>
              <div className='col-6 secondary-font color-grey1 text-sm font-medium px-4 mb-4'>
                Postleitzahl
              </div>
              <div className='col-6 secondary-font text-sm font-semibold text-right px-4 mb-4'>
                {user?.postal_code}
              </div>
            </div>
          </div>
          <div className='modal__footer justify-end' style={{ padding: 16 }}>
            <button
              className='btn btn--secondary btn--h40 px-6 py-2 text-base font-semibold'
              onClick={closeModal}
            >
              Stornieren
            </button>
            <button
              className='btn btn--primary btn--h40 px-6 py-2 text-base font-semibold'
              onClick={toggleEditMyProfile}
            >
              Bearbeiten
            </button>
          </div>
        </div>
      </section>
      {editProfileVisibility && <EditMyProfile closeModal={closeModal} />}
    </>
  );
};

export default MyProfile;
