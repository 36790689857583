import { Navigate, Route, Routes } from 'react-router-dom';

import CreatePassword from '@/components/auth/component/CreatePassword';
import HomeOwnerDashboard from '@/pages/HomeOwner/HomeOwnerDashboard';
import OTPVerification from '@/components/auth/component/OTPVerification';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

const HomeOwnerRoutes = () => {
  const leadId = useSelector((state) => state?.authReducer?.user?.lead_id);

  const userKind = useSelector((state) => state.authReducer?.user?.kind);
  if (userKind === 4 && leadId === undefined) {
    return <Navigate to='/survey/true/0/' />;
  } else if (userKind !== undefined && userKind !== 4) {
    return <Navigate to='/' />;
  } else {
    return (
      <Routes>
        <Route path='' element={<HomeOwnerDashboard title='Dashboard | IKOSIA' />} />
        <Route path='verify/' element={<OTPVerification title='App | IKOSIA' />} />
        <Route path='set-password/' element={<CreatePassword title='App | IKOSIA' />} />
      </Routes>
    );
  }
};

HomeOwnerRoutes.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
    PropTypes.node,
    PropTypes.object,
  ]).isRequired,
};

export default HomeOwnerRoutes;
