import './auth-module.scss';

import { requestOtpPostRequest, verifyOtpPostRequest } from '../core/_request';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import AppointConfirmed from './AppointConfirmed';
import { ENUM_API_STATUS } from '../../../utils/_gConstant';
import loginBanner from '../../../assets/img/login-banner.svg';
import logo from '../../../assets/img/ikosia-logo.svg';

const OTPVerification = (props) => {
  const [otp, setOtp] = useState(new Array(4).fill(''));
  const [email, setEmail] = useState('');
  const otpBoxReference = useRef([]);
  const { leadId } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    document.title = props.title;
  }, [props.title]);

  const requestOtp = useCallback((leadId) => {
    requestOtpPostRequest({ lead_id: leadId }).then((r) => {
      if (r?.status === ENUM_API_STATUS?.SUCCESS_GET_API) {
        setEmail(r?.data?.email);
      }
    });
  }, []);

  useEffect(() => {
    requestOtp(leadId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadId]);

  const submitOtp = useCallback(() => {
    const otpText = otp.join('');
    verifyOtpPostRequest({ email: email, otp: otpText }).then((r) => {
      if (r?.status === ENUM_API_STATUS?.SUCCESS_GET_API)
        navigate(`/homeowner/${leadId}/set-password/`, {
          state: { token: r.data.token },
          replace: true,
        });
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otp , email , leadId]);

  const handleChange = useCallback((value, index) => {
    if (!/^[0-9]*$/.test(value)) return;
    if (value.length > 1) return;
    setOtp((otp) => {
      let newArr = [...otp];
      newArr[index] = value;
      return newArr;
    });

    if (value && index < 3) {
      otpBoxReference.current[index + 1].focus();
    }
  }, []);

  const handleBackspaceAndEnter = useCallback(
    (e, index) => {
      if (e.key === 'Backspace' && !e.target.value && index > 0) {
        otpBoxReference.current[index - 1].focus();
      }
      if (e.key === 'Enter' && e.target.value && index < 3) {
        otpBoxReference.current[index + 1].focus();
      }
      if (e.key === 'Enter' && e.target.value && index === 3) {
        submitOtp();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <section className='auth-module'>
        <div className='auth-module__container'>
          <div className='auth-module__main'>
            <div className='logo-container'>
              <img src={logo} alt='logo' style={{ height: 32 }} />
            </div>
            <form className='auth-module__form' onSubmit={(event) => event.preventDefault()}>
              <h1 className='text-48 font-medium'>OTP-Verifizierung</h1>
              <p className='secondary-font text-secondary text-lg leading4 mt-2'>
                {`Bitte gib den OTP-Code ein, der an deine registrierte E-Mail-Adresse gesendet wurde ${email}.`}
              </p>
              <div className='mt-8'>
                <div className='input-field-wrapper'>
                  {otp.map((digit, index) => (
                    <input
                      key={index}
                      type='text'
                      id={'otp' + index}
                      name=''
                      className='input-field input-field--h52 input-field--otp font-medium lato'
                      placeholder=''
                      value={digit}
                      onChange={(e) => handleChange(e.target.value, index)}
                      onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
                      ref={(reference) => (otpBoxReference.current[index] = reference)}
                    />
                  ))}
                </div>
                <button
                  className='btn btn--primary btn--h52 px-6 text-base w-full mt-12'
                  onClick={() => submitOtp()}
                >
                  Überprüfen und fortfahren
                </button>
                <div className='text-black font-medium leading3 mt-8'>
                  Du hast keinen OTP-Code erhalten?{' '}
                  <span className='link' onClick={() => requestOtp(leadId)}>
                    Code erneut senden
                  </span>
                </div>
              </div>
            </form>
          </div>
          <div className='auth-module__banner'>
            <div className='w-full h-full rounded-2xl'>
              <img
                src={loginBanner}
                alt='banner'
                className='w-full h-full object-cover rounded-2xl'
              />
            </div>
          </div>
        </div>
      </section>
      <AppointConfirmed />
    </>
  );
};

export default OTPVerification;
