import '../../../assets/styles/scss/pages/dp-dashboard.scss';

import * as actions from '../store/_actions';

import { CLIENT_TYPE, ENUM_API_STATUS, VALIDATION_REGEX, isEmpty } from '@/utils/_gConstant';
import React, { useEffect, useState } from 'react';
import {
  getCitiesListRequest,
  getCountriesListRequest,
  getValidatePriceRequest,
} from '../store/request';
import { useDispatch, useSelector } from 'react-redux';

import CustomSelect from '@/components/common/CustomeSelect';
import infoIcon from '@/icons/info-icon.svg';
import { preventExponentialInputInNumber } from '@/utils/utils';
import { toastCustomInfo } from '@/components/ui-elements/_Toastify';
import { useNavigate } from 'react-router-dom';
import useThrottle from '@/utils/_gFunctions/handleDebouncing';
import { useTranslation } from 'react-i18next';

const DPOrderForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { clientType, companyName, postalCode, country, city, residentialUnits, annualEnergyCost } =
    useSelector((state) => state.createOrder);

  const {
    setClientType,
    setCompanyName,
    setPostalCode,
    setCountry,
    setCity,
    setResidentialUnits,
    setAnnualEnergyCost,
    resetData,
  } = actions;

  const [countryOptions, setCountryOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});

  const isPrivateClient = clientType === CLIENT_TYPE[0].value ? true : false;

  useEffect(() => {
    const fetchCountries = async () => {
      const result = await getCountriesListRequest('de');
      console.log(result?.data?.data);
      setCountryOptions(result.data?.data);
    };
    fetchCountries();
  }, []);

  useEffect(() => {
    if (!country) return;

    const getCities = async () => {
      const result = await getCitiesListRequest('de', country.value);

      const _cityOptions = result?.data?.data?.cities?.map((city) => ({
        label: city.name,
        value: city.id,
      }));
      setCityOptions(_cityOptions);
    };

    getCities();
    // eslint-disable-next-line
  }, [country]);

  const validateFields = (fieldName, value, updateFunction, pattern = '') => {
    const errors = { ...validationErrors };
    const requiredFields = {
      companyName: !isPrivateClient,
      postalCode: true,
      country: true,
      city: true,
      residentialUnits: isPrivateClient,
      annualEnergyCost: !isPrivateClient,
    };

    const required = requiredFields[fieldName];

    if (required && isEmpty(value)) {
      errors[fieldName] = t('This is a required field');
    } else if (pattern && !pattern.test(value)) {
      errors[fieldName] = t('Invalid value');
    } else {
      errors[fieldName] = '';
    }
    setValidationErrors(errors);
    dispatch(updateFunction(value));
  };

  const isButtonDisabled = () => {
    const privateClientFields =
      isEmpty(postalCode) || isEmpty(country) || isEmpty(city) || isEmpty(residentialUnits);
    const businessClientFields =
      isEmpty(postalCode) ||
      isEmpty(country) ||
      isEmpty(city) ||
      isEmpty(companyName) ||
      isEmpty(annualEnergyCost);
    return isPrivateClient ? privateClientFields : businessClientFields;
  };

  const onNextStep = async () => {
    const result = await getValidatePriceRequest(residentialUnits, clientType);
    if (result.status === ENUM_API_STATUS?.SUCCESS_GET_API) {
      const { data } = result;
      data.data?.valid
        ? navigate('/dp/order/services')
        : toastCustomInfo(
            t('The residential unit is out of range. Please contact the team for custom pricing.')
          );
    }
  };

  const throttledOnclick = useThrottle(onNextStep, 300);

  const hasErros = () => {
    return Object.values(validationErrors).some((message) => message !== '');
  };

  return (
    <div className='w-full col-6-md mx-auto dp-order-form'>
      <div className='p-6 bg-white rounded-12 mt-4'>
        <label className='block font-medium'>
          {t('Client type')}
          <span className='text-red'>*</span>
        </label>
        <div className='flex items-center mt-3'>
          {CLIENT_TYPE.map((item, idx) => {
            return (
              <div key={idx} className='mr-3'>
                <input
                  type='radio'
                  id={item.label}
                  name='clienttype'
                  className='radio__input'
                  value={item.value}
                  checked={item.value === clientType}
                  onChange={(event) => {
                    dispatch(resetData());
                    setValidationErrors({});
                    dispatch(setClientType(event.target.value));
                  }}
                />
                <label htmlFor={item.label} className='radio-label lg'>
                  <code className='radio__circle'></code>
                  <span className='text-secondary font-medium ml-2'>{t(item.label)}</span>
                </label>
              </div>
            );
          })}
        </div>
        {!isPrivateClient && (
          <div className='mt-4'>
            <label className='block font-medium mb-1'>
              {t('Company Name')}
              <span className='text-red'>*</span>
            </label>
            <input
              className={`input-field input-field--regular input-field--h40 lato w-full font-semibold border2 ${validationErrors.companyName ? 'border-red' : ''}`}
              type='text'
              value={companyName}
              onChange={(event) =>
                validateFields(
                  'companyName',
                  event.target.value,
                  setCompanyName
                  // VALIDATION_REGEX.ALPHANUMERIC
                )
              }
            />
            {validationErrors.companyName && (
              <span className='text-red text-sm'>{validationErrors.companyName}</span>
            )}
          </div>
        )}
        <div className='mt-4'>
          <label className='block font-medium mb-1'>
            {t('Postcode')}
            <span className='text-red'>*</span>
          </label>
          <input
            className={`input-field input-field--regular input-field--h40 lato w-full text-sm font-semibold border2 ${validationErrors.postalCode ? 'border-red' : ''}`}
            type='number'
            value={postalCode}
            onChange={(event) => {
              if (event.target.value.length > 6) return;
              validateFields(
                'postalCode',
                event.target.value,
                setPostalCode,
                VALIDATION_REGEX.POSTAL_CODE
              );
            }}
            onWheel={(event) => event.target.blur()} // disables scrolling
            onKeyDown={(event) => preventExponentialInputInNumber(event)}
          />
          {validationErrors.postalCode && (
            <span className='text-red text-sm'>{validationErrors.postalCode}</span>
          )}
        </div>
        <div className='mt-4'>
          <label className='block font-medium mb-1'>
            {t('Country')}
            <span className='text-red'>*</span>
          </label>
          <CustomSelect
            name='country'
            className={`input-field--regular input-field--h40 border2 text-sm w-full lato w-full font-semibold ${validationErrors.country ? 'border-red' : ''}`}
            placeholder={t('Select Country')}
            options={countryOptions?.map((country) => ({
              label: country.translated_name,
              value: country.id,
            }))}
            maxMenuHeight={150}
            value={country}
            onChange={(selectedOption) => {
              dispatch(setCity(''));
              setCityOptions([]);
              validateFields('country', selectedOption, setCountry);
            }}
          />
          {validationErrors.country && (
            <span className='text-red text-sm'>{validationErrors.country}</span>
          )}
        </div>
        <div className='mt-4'>
          <label className='block font-medium mb-1'>
            {t('City')}
            <span className='text-red'>*</span>
          </label>
          <CustomSelect
            name='city'
            className={`input-field--regular input-field--h40 border2 w-full lato w-full text-sm font-semibold ${validationErrors.city ? 'border-red' : ''}`}
            placeholder={t('Select City')}
            options={cityOptions}
            maxMenuHeight={150}
            onChange={(selectedOption) => validateFields('city', selectedOption, setCity)}
            value={city}
          />
          {validationErrors.city && (
            <span className='text-red text-sm'>{validationErrors.city}</span>
          )}
        </div>
        {!isPrivateClient && (
          <>
            <div className='mt-4'>
              <div className='flex items-center mb-1'>
                <label className='block font-medium '>
                  {t('Annual Energy Costs')}
                  <span className='text-red'>*</span>
                </label>
                <div className='relative info-icon flex items-center justify-center flex-shrink-0 w-4 h-4 ml-2 ml-auto cursor-pointer'>
                  <img src={infoIcon} alt='info-icon' />
                  <div className='tooltip-container-black   pl-5 py-5 pr-6' style={{ width: 360 }}>
                    {/* <h3 className='text-black font-medium leading4'>
                      Förderung und Einsparpotential werden wie folgt berechnet:
                    </h3> */}
                    <p
                      className='secondary-font text-secondary text-sm leading4 mt-2'
                      style={{ color: 'white' }}
                    >
                      {t('TooltipforAnnual')}
                    </p>
                  </div>
                </div>
              </div>
              <input
                className={`input-field input-field--regular input-field--h40 lato w-full text-sm font-semibold border2 ${validationErrors.annualEnergyCost ? 'border-red' : ''}`}
                type='number'
                value={annualEnergyCost}
                onChange={(event) =>
                  validateFields('annualEnergyCost', event.target.value, setAnnualEnergyCost)
                }
                onWheel={(event) => event.target.blur()} // disables scrolling
                onKeyDown={(event) => preventExponentialInputInNumber(event)}
              />
              {validationErrors.annualEnergyCost && (
                <span className='text-red text-sm'>{validationErrors.annualEnergyCost}</span>
              )}
            </div>
          </>
        )}
        <div className='mt-4'>
          <div className='flex items-center mb-1'>
            <label className='block font-medium '>
              {t('Residential Units')}
              {isPrivateClient && <span className='text-red'>*</span>}
            </label>
            <div className='relative info-icon flex items-center justify-center flex-shrink-0 w-4 h-4 ml-2 ml-auto cursor-pointer'>
              <img src={infoIcon} alt='info-icon' />
              <div className='tooltip-container-black   pl-5 py-5 pr-6' style={{ width: 360 }}>
                {/* <h3 className='text-black font-medium leading4'>
                      Förderung und Einsparpotential werden wie folgt berechnet:
                    </h3> */}
                <p
                  className='secondary-font text-secondary text-sm leading4 mt-2'
                  style={{ color: 'white' }}
                >
                  {t(
                    'The residential unit is out of range. Please contact the team for custom pricing.'
                  )}
                </p>
              </div>
            </div>
          </div>

          <input
            className={`input-field input-field--regular input-field--h40 lato w-full text-sm font-semibold border2 ${validationErrors.residentialUnits ? 'border-red' : ''}`}
            type='number'
            value={residentialUnits}
            min={1}
            onChange={(event) => {
              validateFields(
                'residentialUnits',
                event.target.value,
                setResidentialUnits,

                isPrivateClient ? VALIDATION_REGEX.POSITIVE_INTEGER : ''
              );
            }}
            onWheel={(event) => event.target.blur()} // disables scrolling
            onKeyDown={(event) => preventExponentialInputInNumber(event)}
          />
          {isPrivateClient && validationErrors.residentialUnits && (
            <span className='text-red text-sm'>{validationErrors.residentialUnits}</span>
          )}
        </div>
      </div>
      <div className='flex justify-end mt-3'>
        <button
          className={`btn btn--primary btn--h52 text-base px-6 py-2 mt-2 ${isButtonDisabled() || hasErros() ? 'disabled' : ''}`}
          onClick={() => throttledOnclick()}
          disabled={isButtonDisabled() || hasErros() ? true : false}
        >
          {t('Next step')}
        </button>
      </div>
    </div>
  );
};

export default DPOrderForm;
