import React, { useEffect, useState } from 'react';
import { toastError, toastErrors } from '../ui-elements/_Toastify';

import loginBanner from '../../assets/img/home-owner-banner.svg';
import logo from '../../assets/img/ikosia-logo.svg';
import { useSearchParams } from 'react-router-dom';
import { verifyEmail } from '../auth/core/_request';

const VerifyEmailPage = (props) => {
  const [emailVerified, setEmailVerified] = useState(false);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [error, setError] = useState(null);

  const verifyEmailRequest = async (token) => {
    try {
      const res = await verifyEmail({ token: token });
      if (res?.status === 200) {
        setEmailVerified(true);
      } else if (res?.status === 409) {
        setError(res?.data?.detail);
      } else if (res?.status === 400) {
        setError(res?.data?.token);
      } else {
        toastErrors(res?.data);
      }
    } catch (error) {
      console.error('Error verifying email:', error);
      toastError('Beim Verifizieren der E-Mail-Adresse ist ein Fehler aufgetreten.');
    }
  };
  useEffect(() => {
    document.title = props.title;
  }, [props.title]);
  useEffect(() => {
    verifyEmailRequest(token);
  }, [token]);

  return (
    <>
      <section className='auth-module'>
        <div className='auth-module__container'>
          <div className='auth-module__main'>
            <div className='logo-container'>
              <img src={logo} alt='logo' style={{ height: 32 }} />
            </div>
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                gap: '44px',
              }}
            >
              {emailVerified ? (
                <h1
                  style={{
                    color: '#2c2c2c',
                    fontFamily: 'Josefin Sans',
                    fontSize: '48px',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: '60px',
                    marginBottom: '3rem',
                  }}
                >
                  Email überprüft
                </h1>
              ) : error ? (
                <h1
                  style={{
                    color: '#2c2c2c',
                    fontFamily: 'Josefin Sans',
                    fontSize: '48px',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: '60px',
                    marginBottom: '3rem',
                  }}
                  className={'error-message'}
                >
                  {error}
                </h1>
              ) : (
                <>
                  <h1
                    style={{
                      color: '#2c2c2c',
                      fontFamily: 'Josefin Sans',
                      fontSize: '48px',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      lineHeight: '60px',
                      marginBottom: '3rem',
                    }}
                  >
                    E-Mail wird überprüft. Bitte warten...
                  </h1>
                  <div className='loader'></div>
                </>
              )}
            </div>
          </div>
          <div className='auth-module__banner'>
            <div className='w-full h-full rounded-2xl'>
              <img
                src={loginBanner}
                alt='banner'
                className='w-full h-full object-cover rounded-2xl'
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default VerifyEmailPage;
