import { AssignConsultantGetRequest, assignConsultantPatchRequest } from './_request';
import React, { useEffect, useState } from 'react';

import { ENUM_API_STATUS } from '../../utils/_gConstant';
import close from '../../assets/icons/close.svg';
import { useSelector } from 'react-redux';

const AssignConsultant = (props) => {
  const { handleAssignConsultantModal, updateLead, handleClearAll } = props;
  const [data, setData] = useState([]);
  const [selectedConsultant, setSelectedConsultant] = useState(null);
  const [searchValue, setSearchValue] = useState();

  const leadData = useSelector((state) => state?.lead?.selectedLead);

  const handleCloseModal = () => {
    handleAssignConsultantModal();
  };

  const handleSave = async () => {
    const res = await assignConsultantPatchRequest(leadData?.id, selectedConsultant?.id);
    if (res.status === ENUM_API_STATUS?.SUCCESS_PATCH_API) {
      updateLead(res?.data);

      handleCloseModal();
      handleClearAll();
    }
  };

  const handleConsultantSelection = (consultant) => {
    setSelectedConsultant(consultant);
  };

  const getAssignConsultant = async () => {
    const res = await AssignConsultantGetRequest({ search: searchValue });
    if (res?.status === ENUM_API_STATUS?.SUCCESS_GET_API);
    setData(res?.data);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getAssignConsultant();
      if (leadData?.consultant_assigned) {
        setSelectedConsultant(leadData?.consultant_assigned);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, leadData]);

  return (
    <section className='modal modal--add-lead open'>
      <div className='modal__container'>
        <div className='modal__header'>
          <h1 className='text-lg font-bold'>Assign Consultant</h1>
          <div className='modal__close' onClick={handleCloseModal}>
            <img src={close} alt='close' />
          </div>
        </div>
        <div className='modal__body'>
          <div>
            <input
              type='text'
              className='input-field input-field--search input-field--h36 w-full'
              placeholder='Search '
              onChange={(e) => setSearchValue(e?.target?.value)}
            />
          </div>
          <div className='mt-4'>
            <p className='secondary-font color-grey1 text-13 font-medium'>
              Total energy consultant ({data?.length})
            </p>
            <ul className='mt-2'>
              {data?.map((item, index) => (
                <li
                  key={index}
                  className={`flex items-center justify-between py-2 px-4 mb-1 list-hover ${
                    selectedConsultant && selectedConsultant.id === item.id ? 'selected' : ''
                  }`}
                  onClick={() => handleConsultantSelection(item)}
                >
                  <span className='secondary-font text-sm font-semibold'>{item?.first_name}</span>
                  <span className='secondary-font color-grey2 text-sm font-medium'>
                    {item?.location}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className='modal__footer justify-end'>
          <button
            className='btn btn--secondary btn--h40 px-6 py-2 text-base font-semibold'
            onClick={handleCloseModal}
          >
            Cancel
          </button>
          <button
            className='btn btn--primary btn--h40 px-6 py-2 text-base font-semibold'
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </div>
    </section>
  );
};

export default AssignConsultant;
