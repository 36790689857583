import React from 'react';
import downloadIcon from '../../assets/icons/download.svg';
import trashIcon from '../../assets/icons/trash.svg';

const Notes = () => {
  return (
    <>
      <div className='lead-main-info__notes mt-8'>
        <div className='flex'>
          <div className='col-3 flex-shrink-0 pr-8 border1-r' style={{ width: 334 }}>
            <label className='secondary-font color-grey2 font-reular'>12 notes</label>
            <input
              type='text'
              className='input-field input-field--search input-field--h44 mt-2'
              placeholder='Search'
            />
            <button
              className='btn btn--outline w-full btn--h40 font-bold px-6 mt-4'
              style={{
                borderRadius: 34,
                justifyContent: 'flex-start',
              }}
            >
              <div className='flex items-center justify-center w-5 h-5 mr-2'>
                <svg width='13' height='12' viewBox='0 0 13 12' fill='none'>
                  <path
                    d='M6.5 10.6551V1.34473'
                    stroke='#00BD1E'
                    stroke-width='2'
                    stroke-miterlimit='10'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                  <path
                    d='M1.84375 6H11.1541'
                    stroke='#00BD1E'
                    stroke-width='2'
                    stroke-miterlimit='10'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              </div>
              Add note
            </button>
            <ul className='mt-4'>
              <li className='notes-list active'>
                <div className='flex items-center justify-between'>
                  <div className='flex items-center pr-2'>
                    <code
                      className='w-2 h-2 rounded-full'
                      style={{ backgroundColor: '#FC6128' }}
                    ></code>
                    <span className='secondary-font color-grey2 text-xs font-regular ml-1'>
                      General
                    </span>
                  </div>
                  <span className='secondary-font color-grey2 text-xs font-regular'>
                    01/04/2023
                  </span>
                </div>
                <h3 className='secondary-font font-semibold mt-2'>Enquiry success story of Leo</h3>
                <p className='secondary-font text-secondary text-sm font-regular leading4 truncate-text-3 mt-2'>
                  Harnessing renewable sources and hello ho optimizing energy usage pave the way
                </p>
              </li>
              <li className='notes-list'>
                <div className='flex items-center justify-between'>
                  <div className='flex items-center pr-2'>
                    <code
                      className='w-2 h-2 rounded-full'
                      style={{ backgroundColor: '#FED07B' }}
                    ></code>
                    <span className='secondary-font color-grey2 text-xs font-regular ml-1'>
                      Important
                    </span>
                  </div>
                  <span className='secondary-font color-grey2 text-xs font-regular'>
                    01/04/2023
                  </span>
                </div>
                <h3 className='secondary-font font-semibold mt-2'>Enquiry success story of Leo</h3>
                <p className='secondary-font text-secondary text-sm font-regular leading4 truncate-text-3 mt-2'>
                  Harnessing renewable sources and hello ho optimizing energy usage pave the way
                </p>
              </li>
            </ul>
          </div>
          <div className='col-9 flex-1 notes-details-section'>
            <div className='pl-8'>
              <div className='flex items-center justify-between'>
                <div className='flex items-center'>
                  <code className='dots-indicator'></code>
                  <div className='custom-select-wrapper ml-3'>
                    <input
                      type='text'
                      className='input-field input-field--select input-field--h36'
                      placeholder='Select'
                      style={{ width: 120 }}
                    />
                    <ul className='select-dropdown-menu'>
                      <li className='select-dropdown-menu__list'>doc type1</li>
                      <li className='select-dropdown-menu__list'>doc type2</li>
                      <li className='select-dropdown-menu__list'>doc type3</li>
                    </ul>
                  </div>
                </div>
                <div className='flex items-center'>
                  <div className='flex items-center justify-center flex-shrink-0 w-5 h-5 cursor-pointer'>
                    <img src={downloadIcon} alt='downloadIcon' />
                  </div>
                  <div className='flex items-center justify-center flex-shrink-0 w-5 h-5 ml-4 cursor-pointer'>
                    <img src={trashIcon} alt='deleteIcon' />
                  </div>
                </div>
              </div>
              <div className='mt-6 pb-6' style={{ borderBottom: '0.5px solid #D1D5DB' }}>
                <input
                  type='text'
                  className='input-field--add-notes-title'
                  placeholder='Add your Title'
                />
                <input
                  type='text'
                  className='input-field--add-notes-date'
                  placeholder='01/04/2023'
                />
              </div>
              <div className='mt-6'>
                <textarea
                  rows={10}
                  color='5'
                  className='input-field--add-notes-message'
                  placeholder='Enter your notes'
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notes;
