import * as Yup from 'yup';

import { ENUM_API_STATUS, isEmpty } from '@/utils/_gConstant';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import {
  distributionPartnerAcceptInvitePostRequest,
  distributionPartnerfetchDetailsrequest,
} from '../core/_request';
import { toastError, toastErrors } from '@/components/ui-elements/_Toastify';
import { useLocation, useNavigate } from 'react-router-dom';

import Loader from '@/components/common/Loader';
import loginBanner from '../../../assets/img/login-banner.svg';
import logo from '../../../assets/icons/Group 776.svg';
import { useTranslation } from 'react-i18next';

const DistributionPartnerRegistration = () => {
  
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [token, setToken] = useState('');
  const [data, setData] = useState(null);
  const [tokenexpired, setTokenExpired] = useState(false);

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required(t('firstNameRequired')),
    last_name: Yup.string().required(t('lastNameRequired')),
    company_name: Yup.string().required(t('companyNameRequired')),
    // phone: Yup.string().required(t('phoneRequired')),
    password: Yup.string().required(t('passwordRequired')),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('password'), null], t('passwordsMustMatch'))
      .required(t('confirmPasswordRequired')),
    acceptTerms: Yup.boolean().required('').oneOf([true], t('pleaseAcceptTerms')),
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tokenFromUrl = searchParams.get('token');
    setToken(tokenFromUrl);
  }, [location.search]);

  useEffect(() => {
    const fetchDetails = async (token) => {
      if (!token) return;
      try {
        const res = await distributionPartnerfetchDetailsrequest({ token });
        if (res.status === ENUM_API_STATUS.SUCCESS_POST_API_200) {
          setData(res.data);
        } else if (res?.status === ENUM_API_STATUS?.ERROR_400_BAD_REQ) {
          setTokenExpired(true);
        } else {
          setTokenExpired(true);
          toastErrors(res.data);
        }
      } catch (error) {
        setTokenExpired(true);
        console.error(error);
      }
    };

    fetchDetails(token);
  }, [token]);

  const headerAuthorizationtoken = data?.access;
  const refreshToken = data?.refresh;

  const handleSubmit = async (values) => {
    const res = await distributionPartnerAcceptInvitePostRequest(values, headerAuthorizationtoken);
    if (res?.status === ENUM_API_STATUS?.SUCCESS_POST_API_200) {
      const hostURL = res?.data?.data?.host_url;
      window.location.href = hostURL + `/?auth=${refreshToken}`;
    }
  };

  const initialValues = isEmpty(data)
    ? {}
    : {
        first_name: data.first_name || '',
        last_name: data.last_name || '',
        company_name: data.company_name || '',
        phone: '',
        password: '',
        confirm_password: '',
        email: data.email || '',
      };

  if (tokenexpired) {
    toastError(t('tokenExpired'));
    navigate('/');
  }

  return data === null ? (
    <Loader />
  ) : (
    <section className='auth-module'>
      <div className='auth-module__container'>
        <div className='auth-module__main'>
          <div className='logo-container'>
            <img src={logo} alt='logo' style={{ height: 32 }} />
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched }) => (
              <Form className='auth-module__form'>
                <h1 className='text-48 font-medium'>{t('welcome')} {data?.first_name}</h1>
                <p className='secondary-font text-secondary text-lg leading4 mt-2'>
                  {t('pleaseEnterDetails')}
                </p>
                <div className='mt-8'>
                  <div className='flex flex-wrap -mx-3'>
                    <div className='col-6 px-3 mb-6'>
                      <label htmlFor='first_name' className='input-field-label'>
                        {t('firstName')}
                      </label>
                      <Field
                        type='text'
                        id='first_name'
                        name='first_name'
                        className={`input-field input-field--md font-medium lato ${
                          errors.first_name && touched.first_name && 'input-field--error'
                        }`}
                        placeholder={t('firstNamePlaceholder')}
                      />
                      <ErrorMessage name='first_name' component='p' className='error-message' />
                    </div>
                    <div className='col-6 px-3 mb-6'>
                      <label htmlFor='last_name' className='input-field-label'>
                        {t('lastName')}
                      </label>
                      <Field
                        type='text'
                        id='last_name'
                        name='last_name'
                        className={`input-field input-field--md font-medium lato ${
                          errors.last_name && touched.last_name && 'input-field--error'
                        }`}
                        placeholder={t('lastNamePlaceholder')}
                      />
                      <ErrorMessage name='last_name' component='p' className='error-message' />
                    </div>
                    <div className='col-6 px-3 mb-6'>
                      <label htmlFor='company_name' className='input-field-label'>
                        {t('company')}
                      </label>
                      <Field
                        type='text'
                        id='company_name'
                        name='company_name'
                        className={`input-field input-field--md font-medium lato ${
                          errors.company_name && touched.company_name && 'input-field--error'
                        }`}
                        placeholder={t('companyPlaceholder')}
                      />
                      <ErrorMessage name='company_name' component='p' className='error-message' />
                    </div>
                    <div className='col-6 px-3 mb-6'>
                      <label htmlFor='phone' className='input-field-label'>
                        {t('phone')}
                      </label>
                      <Field
                        type='text'
                        id='phone'
                        name='phone'
                        className={`input-field input-field--md font-medium lato ${
                          errors.phone && touched.phone && 'input-field--error'
                        }`}
                        placeholder={t('phonePlaceholder')}
                      />
                      <ErrorMessage name='phone' component='p' className='error-message' />
                    </div>
                    <div className='col-6 px-3 mb-6'>
                      <label htmlFor='password' className='input-field-label'>
                        {t('password')}
                      </label>
                      <Field
                        type='password'
                        id='password'
                        name='password'
                        className={`input-field input-field--md font-medium lato ${
                          errors.password && touched.password && 'input-field--error'
                        }`}
                        placeholder={t('passwordPlaceholder')}
                      />
                      <ErrorMessage name='password' component='p' className='error-message' />
                    </div>
                    <div className='col-6 px-3 mb-6'>
                      <label htmlFor='confirm_password' className='input-field-label'>
                        {t('confirmPassword')}
                      </label>
                      <Field
                        type='password'
                        id='confirm_password'
                        name='confirm_password'
                        className={`input-field input-field--md font-medium lato ${
                          errors.confirm_password &&
                          touched.confirm_password &&
                          'input-field--error'
                        }`}
                        placeholder={t('confirmPasswordPlaceholder')}
                      />
                      <ErrorMessage
                        name='confirm_password'
                        component='p'
                        className='error-message'
                      />
                    </div>
                  </div>
                  <div className='custom-checkbox md mt-2'>
                    <Field
                      type='checkbox'
                      id='acceptTerms'
                      name='acceptTerms'
                      className={`custom-checkbox__input ${
                        errors?.acceptTerms && 'input-field--error'
                      }`}
                    />

                    <label htmlFor='acceptTerms' className='custom-checkbox__label'>
                      <code className='custom-checkbox__label__box'></code>
                      <a
                        className='secondary-font text-black text-base font-medium ml-2'
                        href={`${process.env.REACT_APP_LANDING_PAGE_URL}nutzungsbedingungen`}
                        target='_blank'
                      >
                        {t('acceptTerms')}
                      </a>
                    </label>
                    {errors.acceptTerms && (
                      <p name='acceptTerms' component='p' className='error-message mt-2'>
                        {errors.acceptTerms}
                      </p>
                    )}
                  </div>
                </div>
                <div className='mt-7'>
                  <button
                    className='btn btn--primary btn--h52 text-base px-8'
                    type='submit'
                    disabled={!values.acceptTerms}
                    style={{
                      opacity: values.acceptTerms ? 1 : 0.5,
                      cursor: values.acceptTerms ? 'pointer' : 'not-allowed',
                    }}
                  >
                    {t('registerNow')}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div className='auth-module__banner'>
          <div className='w-full h-full rounded-2xl'>
            <img
              src={loginBanner}
              alt='banner'
              className='w-full h-full object-cover rounded-2xl'
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default DistributionPartnerRegistration;
