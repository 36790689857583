import '../../assets/styles/scss/pages/admin-dashboard.scss';

import {
  DistributionPartnerViewGetRequest,
  deleteDistributionPartnerRequest,
  resendPostRequest,
} from './_request';
import { ENUM_API_STATUS, ENUM_DP_STATUS } from '@/utils/_gConstant';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import AddDistributionPartner from './AddDistributionPartner';
import DeleteDP from './DeleteDP';
import deleteIcon from '../../assets/icons/delete-with-circle.svg';
import { getInitials } from '@/utils/_gFunctions/_handleGetInitials';
import plusIcon from '../../assets/icons/plus-with-circle.svg';
import { toastSuccess } from '@/components/ui-elements/_Toastify';
import { useDebounce } from '@/utils/_gFunctions/handleDebouncing';

const DistributionPartners = (props) => {
  const usersCount = 10;
  const [users, setUsers] = useState([]);
  const [nextPageNumber, setNextPageNumber] = useState(null);
  const [prevPageNumber, setPrevPageNumber] = useState(null);
  const [totalUsers, setTotalUsers] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [deleteUser, setDeleteUser] = useState(null);
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };
  const fetchUsers = useCallback(
    async (page) => {
      const queryParams = {};
      if (page) {
        queryParams.page = page;
      } else {
        page = currentPage;
      }
      if (debouncedSearchValue !== '') {
        queryParams.search = debouncedSearchValue;
      }
      setLoading(true);
      const res = await DistributionPartnerViewGetRequest(queryParams);
      if (res?.status === ENUM_API_STATUS?.SUCCESS_GET_API) {
        setUsers(res?.data?.results);
        setNextPageNumber(res?.data?.next);
        setPrevPageNumber(res?.data?.previous);
        setTotalUsers(res?.data?.count);
        setCurrentPage(page || 1);
      }
      setLoading(false);
    },
    [debouncedSearchValue, currentPage]
  );
  useEffect(() => {
    document.title = props.title;
  }, [props.title]);

  useEffect(() => {
    fetchUsers(currentPage).then(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchValue, currentPage]);

  const fromAndToText = useMemo(() => {
    const from = (currentPage - 1) * usersCount + 1;
    const to = from + users?.length - 1;
    return `${from}-${to}`;
  }, [currentPage, users?.length]);

  const toggleAddpartner = useCallback(() => {
    setShowAddModal((addModal) => {
      return !addModal;
    });
  }, []);

  const toggleDeleteModal = (user) => {
    setShowDeleteModal(!showDeleteModal);
    setDeleteUser(user);
  };

  const closeModal = useCallback(() => {
    setShowAddModal(false);
  }, []);
  const [isSticky, setIsSticky] = useState(false);
  const tableBody = useRef();
  useEffect(() => {
    const handleScroll = () => {
      const offset = tableBody?.current?.scrollTop;
      const threshold = 30;
      setIsSticky(offset > threshold);
    };

    const tableBodyRef = tableBody?.current;
    tableBodyRef?.addEventListener('scroll', handleScroll);

    return () => {
      tableBodyRef?.removeEventListener('scroll', handleScroll);
      setIsSticky(false);
    };
  }, [tableBody]);
  const handleDelete = async (id) => {
    const res = await deleteDistributionPartnerRequest(id);
    if (res?.status === ENUM_API_STATUS?.SUCCESS_DELETE_API) {
      toastSuccess('Distribution Partner Deleted');
      setCurrentPage(1);
      fetchUsers(1); 
      setShowDeleteModal(false);
    }
  };

  const handleResendButton = async (id) => {
    const res = await resendPostRequest(id);
    if (res?.status === ENUM_API_STATUS?.SUCCESS_POST_API_200) {
      toastSuccess(res?.data?.message);
    }
  };
  return (
    <>
      <section className='admin-dashboard-wrapper'>
        <main
          className='admin-dashboard__main admin-dashboard__main--table-details pb-4'
          style={{ paddingTop: 88 }}
        >
          <div className='flex items-center w-full px-10'>
            <h1 className='text-32 font-semibold'>Distribution Partners</h1>
            {(users.length > 0 ? true : searchValue !== '') && (
              <div className='flex flex-1 ml-14'>
                <div className='flex flex-1 items-center'>
                  <input
                    type='text'
                    className='input-field input-field--search input-field--h44 input-field--md lato'
                    placeholder='Search'
                    value={searchValue}
                    onChange={handleChange}
                    style={{ width: '60%' }}
                  />
                </div>
                {(users.length > 0 ? true : searchValue !== '') && (
                  <button
                    className='btn btn--primary btn--h48 text-base px-8 ml-6'
                    onClick={toggleAddpartner}
                  >
                    <img src={plusIcon} alt='plus-icon' className='mr-3' />
                    New Distribution Partner
                  </button>
                )}
              </div>
            )}
          </div>
          <div className='h-full px-10 mt-6 overflow-y-auto' ref={tableBody}>
            {users.length === 0 ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  height: '75vh',
                }}
              >
                <div style={{ marginBottom: '5vh', fontSize: '30px', fontWeight: 'bold' }}>
                  {loading ? 'Loading...' : 'No Data Found'}
                </div>

                {searchValue === '' && !loading && (
                  <button
                    className='btn btn--primary btn--h48 text-base px-8 ml-6'
                    onClick={toggleAddpartner}
                  >
                    <img src={plusIcon} alt='plus-icon' className='mr-3' />
                    New Distribution Partner
                  </button>
                )}
              </div>
            ) : (
              <table className='table-styles table--user' style={{ maxHeight: '100%' }}>
                <thead className={isSticky ? 'box-shadow' : ''}>
                  <tr>
                    <td>S.No.</td>
                    <td>Name</td>
                    <td>Email</td>
                    <td>Company Name</td>
                    <td>Status</td>
                    <td>Action</td>
                  </tr>
                </thead>

                <tbody>
                  {users?.map((user, index) => (
                    <tr key={user?.id}>
                      <td>{(currentPage - 1) * 10 + index + 1}</td>
                      <td>
                        <div className='flex items-center'>
                          <figure
                            className='avatar-container'
                            style={{
                              width: 32,
                              height: 32,
                              backgroundColor: '#FF767B',
                              border: '1px solid #F3F2EC',
                            }}
                          >
                            <p className='text-white text-13 font-medium'>
                              {getInitials(user?.first_name)}
                            </p>
                          </figure>
                          <span className='text-lg font-medium ml-3'>
                            {user?.first_name?.length > 15
                              ? `${user?.first_name.substring(0, 12)}...`
                              : user?.first_name}{' '}
                            {user?.last_name?.length > 15
                              ? `${user?.last_name.substring(0, 12)}...`
                              : user?.last_name}
                          </span>
                        </div>
                      </td>
                      <td>{user?.email}</td>
                      <td>{user?.company_name}</td>
                      <td>
                        {user?.status === ENUM_DP_STATUS?.ACCEPTED ? (
                          <div
                            className='tags'
                            style={{
                              color: '#1F7B10',
                              backgroundColor: 'rgba(107, 173, 91, 0.2)',
                            }}
                          >
                            Accepted
                          </div>
                        ) : (
                          <div
                            className='tags'
                            style={{
                              color: '#FFD700',
                              backgroundColor: 'rgba(255, 215, 0, 0.2)',
                            }}
                          >
                            Pending
                          </div>
                        )}
                      </td>
                      <td>
                        <div className='flex items-center'>
                          <img
                            src={deleteIcon}
                            alt='delete'
                            className='mr-3 cursor-pointer'
                            onClick={() => toggleDeleteModal(user)}
                            style={{ height: '25px' }}
                          />

                          {user.status !== ENUM_DP_STATUS?.PENDING ? (
                            <span style={{ color: '#07BC0C', cursor: 'pointer' }}></span>
                          ) : (
                            <span
                              className='btn btn--primary btn--h30 text-base px-2 ml-3'
                              style={{ fontSize: '12px' }}
                              onClick={() => handleResendButton(user?.id)}
                            >
                              Resend
                            </span>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
          <div className='flex items-center justify-end px-10 mt-3'>
            <label className='secondary-font color-grey2 text-xs font-medium'>
              {fromAndToText} of {totalUsers}
            </label>
            <div className='flex items-center ml-6'>
              <button
                className={
                  'btn-pagination btn-pagination--previous ' + (prevPageNumber && 'active')
                }
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={!prevPageNumber}
              >
                <svg width='8' height='12' viewBox='0 0 8 12' fill='none'>
                  <path
                    d='M7.12407 10.9757C7.35838 11.2101 7.35838 11.5899 7.12407 11.8243C6.88975 12.0586 6.50985 12.0586 6.27554 11.8243L0.87554 6.42426C0.641225 6.18995 0.641225 5.81005 0.87554 5.57573L6.27554 0.175735C6.50986 -0.0585795 6.88976 -0.0585795 7.12407 0.175735C7.35838 0.410049 7.35838 0.789949 7.12407 1.02426L2.14833 6L7.12407 10.9757Z'
                    fill='#D1D5DB'
                  />
                </svg>
              </button>
              <button
                className={'btn-pagination btn-pagination--next ' + (nextPageNumber && 'active')}
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={!nextPageNumber}
              >
                <svg width='8' height='12' viewBox='0 0 8 12' fill='none'>
                  <path
                    d='M7.12407 10.9757C7.35838 11.2101 7.35838 11.5899 7.12407 11.8243C6.88975 12.0586 6.50985 12.0586 6.27554 11.8243L0.87554 6.42426C0.641225 6.18995 0.641225 5.81005 0.87554 5.57573L6.27554 0.175735C6.50986 -0.0585795 6.88976 -0.0585795 7.12407 0.175735C7.35838 0.410049 7.35838 0.789949 7.12407 1.02426L2.14833 6L7.12407 10.9757Z'
                    fill='#D1D5DB'
                  />
                </svg>
              </button>
            </div>
          </div>
        </main>
      </section>
      {showAddModal && <AddDistributionPartner closeModal={closeModal} refreshList={fetchUsers} />}
      {showDeleteModal && (
        <DeleteDP
          deleteDPuser={deleteUser}
          setShowDeleteModal={setShowDeleteModal}
          refreshList={fetchUsers}
          handleDelete={handleDelete}
        />
      )}
    </>
  );
};

export default DistributionPartners;
