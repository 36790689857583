import { Navigate, useParams } from 'react-router-dom';

import HomeOwner from '@/pages/HomeOwner/HomeOwner';
import PropTypes from 'prop-types';
import React from 'react';

const SurveyRoutes = () => {
  const { leadId } = useParams();

  return leadId ? <HomeOwner title='App | IKOSIA' /> : <Navigate to='/' />;
};

SurveyRoutes.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
    PropTypes.node,
    PropTypes.object,
  ]).isRequired,
};

export default SurveyRoutes;
