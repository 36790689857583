import './index.scss';
import "./i18n";

import { persistor, store } from './state';

import AppRoutes from './routes/AppRoutes';
import { AuthProvider } from './components/auth/core/Auth';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
// import rootReducer from "../src/state/reducers";
import { PersistGate } from 'redux-persist/integration/react';
// import "./global.css"
import { Provider } from 'react-redux';
import { Tostify } from './components/ui-elements/_Toastify';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root');

if (container) {
  createRoot(container).render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AuthProvider>
          <DndProvider backend={HTML5Backend}>
            <AppRoutes />
            {/* <CookieChangeDetector/> */}
          </DndProvider>
          <Tostify />
        </AuthProvider>
      </PersistGate>
    </Provider>
  );
}

reportWebVitals();
