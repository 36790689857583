import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { preventExponentialInputInNumber } from '@/utils/utils';
import { updateEnergyCost } from '../../store/_actions';
import { useDebounceFuntion } from '@/utils/_gFunctions/handleDebouncing';
import { useTranslation } from 'react-i18next';

const predefinedTypes = ['Storm', 'Erd-order Flussigas', 'Heizol', 'Fernwarme', 'Sonstiges'];

const EnergyCost = ({ readonly, setHasError = () => {} }) => {
  const [isSticky, setIsSticky] = useState(false);
  const tableBody = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const energyCosts = useSelector((state) => state.createOrder.energy_costs);

  useEffect(() => {
    const handleScroll = () => {
      const offset = tableBody?.current?.scrollTop;
      const threshold = 30;
      setIsSticky(offset > threshold);
    };

    tableBody?.current?.addEventListener('scroll', handleScroll);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      tableBody?.current?.removeEventListener('scroll', handleScroll);
      setIsSticky(false);
    };
  }, [tableBody]);

  const handleInputChange = (e, name, type) => {
    const value = parseFloat(e.target.value);
    const currentItem = energyCosts.values.find((item) => item.name === name) || {};
    dispatch(
      updateEnergyCost(
        name,
        t('energyCost.unit.kwh'),
        t('energyCost.unit.euro'),
        type === 'value' ? value : currentItem.value,
        type === 'amount' ? value : currentItem.amount
      )
    );
  };
  
  const debouncedHandleInputChange = useDebounceFuntion(handleInputChange,300)

  const validateEnergyCost = (arr) => {
    if (arr.length !== predefinedTypes.length) {
      return false;
    }

    for (let obj of arr) {
      if (
        obj.value == null ||
        obj.amount == null ||
        isNaN(obj.value) ||
        isNaN(obj.amount) ||
        obj.amount === 0 ||
        obj.value === 0
      ) {
        return false;
      }
    }

    return true;
  };

  useEffect(() => {
    const hasError = validateEnergyCost(energyCosts.values);
    setHasError(!hasError);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [energyCosts]);

  return (
    <div className='mt-4'>
      <h2 className='text-28 font-medium leading2'>{t('energyCost.title')}</h2>
      <div className='h-full mt-4 overflow-y-auto' ref={tableBody}>
        <table className='table-styles' style={{ maxHeight: '100%' }}>
          <thead className={isSticky ? 'box-shadow' : ''}>
            <tr>
              <td>{t('energyCost.tableHeader.type')}</td>
              <td>{t('energyCost.tableHeader.kwh')}</td>
              <td></td>
              <td>{t('energyCost.tableHeader.euro')}</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {predefinedTypes.map((type) => {
              const currentItem = energyCosts?.values?.find((item) => item.name === type) || {};
              return (
                <tr key={type}>
                  <td>{type}</td>
                  <td>
                    <input
                      type='number'
                      className={`input-field border2 ${readonly ? 'input-blur' : ''}`}
                      value={currentItem.value || ''}
                      onChange={(e) => debouncedHandleInputChange(e, type, 'value')}
                      onWheel={(event) => event.target.blur()} // disables scrolling
                      onKeyDown={(event) => preventExponentialInputInNumber(event)}
                      readOnly={readonly}
                    />
                  </td>
                  <td>{t('energyCost.unit.kwh')}</td>
                  <td>
                    <input
                      type='number'
                      className={`input-field border2 ${readonly ? 'input-blur' : ''}`}
                      value={currentItem.amount || ''}
                      onChange={(e) => debouncedHandleInputChange(e, type, 'amount')}
                      onWheel={(event) => event.target.blur()} // disables scrolling
                      onKeyDown={(event) => preventExponentialInputInNumber(event)}
                      readOnly={readonly}
                    />
                  </td>
                  <td>{t('energyCost.unit.euro')}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EnergyCost;
