import './auth-module.scss';

import * as Yup from 'yup';

import { toastErrors, toastSuccess } from '../../ui-elements/_Toastify';

import { ENUM_API_STATUS } from '../../../utils/_gConstant';
import LogoImage from '@/components/common/LogoImage';
import { forgotEmailPassword } from '../core/_request';
import loginBanner from '../../../assets/img/home-owner-banner.svg';
import { useEffect } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';

const ResetPassword = (props) => {
  const host = window.location.host;
  const [slug] = host.split('.', 1);
  const navigate = useNavigate();
  useEffect(() => {
    document.title = props.title;
  }, [props.title]);
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Ungültige E-Mail-Adresse').required('Erforderlich'),
    }),
    onSubmit: (values) => {
      const sendingEmail = async () => {
        const res = await forgotEmailPassword(values);
        if (res?.status === ENUM_API_STATUS?.SUCCESS_GET_API) {
          toastSuccess(res?.data?.message);
          navigate('/');
        } else {
          toastErrors(res?.data);
        }
      };

      sendingEmail();
    },
  });

  return (
    <section className='auth-module'>
      <div className='auth-module__container'>
        <div className='auth-module__main'>
          <div className='logo-container'>
            <LogoImage slug={slug} />
          </div>
          <form onSubmit={formik.handleSubmit} className='auth-module__form'>
            <h1 className='text-48 font-medium'>Passwort zurücksetzen</h1>
            <p className='secondary-font text-secondary text-lg leading4 mt-2'>
              Der Link wird an Ihre E-Mail-Adresse gesendet
            </p>
            <div className='mt-8'>
              <div className='input-field-wrapper'>
                <input
                  type='email'
                  id='email'
                  name='email'
                  {...formik.getFieldProps('email')}
                  className={`input-field input-field--h52 input-field--md font-medium lato ${
                    formik.touched.email && formik.errors.email ? 'border-red-500' : ''
                  }`}
                  placeholder='Email'
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='error-message'>{formik.errors.email}</div>
                )}
              </div>

              <button
                type='submit'
                className='btn btn--primary btn--h52 px-6 text-base w-full mt-12'
              >
                Bestätigen
              </button>
            </div>
          </form>
        </div>
        <div className='auth-module__banner'>
          <div className='w-full h-full rounded-2xl'>
            <img
              src={loginBanner}
              alt='banner'
              className='w-full h-full object-cover rounded-2xl'
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResetPassword;
