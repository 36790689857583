import '../../assets/styles/scss/pages/admin-dashboard.scss';

import { ENUM_API_STATUS, leadStageColors, leadStageOptionsObj } from '../../utils/_gConstant';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import InfiniteScroll from 'react-infinite-scroll-component';
import LeadCard from './LeadCard';
import { LeadListSingleViewGetRequest } from './_request';
import ShimmerUI from '../../components/ui-elements/shimmerUI/ShimmerUI';
import { useDrop } from 'react-dnd';

const Stages = (props) => {
  const {
    stageId,
    leads,
    setLeadsData,
    setIsModalOpen,
    setModalData,
    handleAssignConsultantModal,
    deleteLead,
    filterSelectedObject,
    searchValue,
    setFilterSelectedValue,
    totalLeadsInStages,
    setTotalLeadsInStages,
  } = props;

  // eslint-disable-next-line no-unused-vars
  const [page, setPage] = useState(null);
  const [nextPage, setNextPage] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [loadedData, setLoadedData] = useState(0);

  const totalLeads = useMemo(() => {
    return totalLeadsInStages[stageId] || 0;
  }, [stageId, totalLeadsInStages]);

  const setTotalLeads = useCallback(
    (value) => {
      setTotalLeadsInStages((oldData) => ({
        ...oldData,
        [stageId]: value,
      }));
    },
    [setTotalLeadsInStages, stageId]
  );

  // const isLoading = useMemo(() => {
  //   return totalLeads !== loadedData && nextPage !== null;
  // }, [totalLeads, loadedData, nextPage]);

  const updateLeads = useCallback(
    (page, reset = false) => {
      if (page !== null) {
        LeadListSingleViewGetRequest(
          { page, search: searchValue, ...filterSelectedObject },
          stageId,
          true
        ).then((res) => {
          if (res?.status === ENUM_API_STATUS.SUCCESS_GET_API) {
            setLeadsData((leads) => {
              if (reset) {
                const oldLeads = leads.filter((lead) => lead.stages !== stageId);
                return [
                  ...oldLeads,
                  ...(res && res.data && res.data.results ? res.data.results : []),
                ];
              }
              return [...leads, ...(res && res.data && res.data.results ? res.data.results : [])];
            });
            setPage(res?.data?.page);
            setTotalLeads(res?.data?.count);
            setNextPage(res?.data?.next);
            setLoadedData((loadedData) => {
              if (reset) return res?.data?.results?.length;
              return loadedData + res?.data?.results?.length;
            });
          } else {
            setNextPage(null);
          }
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stageId, filterSelectedObject, searchValue]
  );

  useEffect(() => {
    updateLeads(1, true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSelectedObject, searchValue]);

  // eslint-disable-next-line no-unused-vars
  const [{ isOver }, drop] = useDrop({
    accept: 'CARD',
    drop: (item) => props?.onCardDrop(item?.LeadData, stageId),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const openAddModal = () => {
    setIsModalOpen(true);
    setModalData({
      add: true,
      stageId: stageId,
    });
  };

  return (
    <>
      <li className='leads-list' ref={drop}>
        <div
          className='leads-list__label'
          style={{
            borderTop: `3px solid ${leadStageColors[stageId]?.color || {}}`,
          }}
        >
          {leadStageOptionsObj[stageId]} ({totalLeads})
        </div>
        <div
          className='leads-list__btn-add-lead mt-4'
          style={{ cursor: 'pointer' }}
          onClick={openAddModal}
        >
          + Add New
        </div>
        <div
          className='h-full mt-4 pb-4 overflow-y-auto overflow-x-hidden'
          id={`scrollableDiv-${stageId}`}
          style={{ msOverflowStyle: 'none', scrollbarWidth: 'none' }}
        >
          <InfiniteScroll
            dataLength={leads.length}
            next={() => updateLeads(nextPage)}
            hasMore={!!nextPage}
            loader={<ShimmerUI />}
            scrollableTarget={`scrollableDiv-${stageId}`}
          >
            {leads.map((lead) => (
              <LeadCard
                key={lead?.id}
                lead={lead}
                setIsModalOpen={setIsModalOpen}
                setModalData={setModalData}
                handleAssignConsultantModal={handleAssignConsultantModal}
                deleteLead={deleteLead}
                setFilterSelectedValue={setFilterSelectedValue}
              />
            ))}
          </InfiniteScroll>
        </div>
      </li>
    </>
  );
};

export default Stages;
