import React, { useEffect, useState } from 'react';
import { getLeadFilterOptionsGetRequest, getLeadFilterValuesGetRequest } from './_request';

import { ENUM_API_STATUS } from '../../utils/_gConstant';
import filter from '../../assets/icons/filter.svg';

const LeadFilter = (props) => {
  const { filterSelectedValue, setFilterSelectedValue } =
    props && props.filterSelectedValue ? props.filterSelectedValue : {};
  const [isFilterDropdownVisible, setFilterDropdownVisible] = useState(false);
  const [isValueDropdownVisible, setIsValueDropdownVisible] = useState(false);
  const [filterOptions, setFilterOptions] = useState([]);
  const [filterValues, setFilterValues] = useState([]);
  const [filterValueSearch, setFilterValueSearch] = useState('');
  const [selectedKey, setSelectedKey] = useState(null);

  useEffect(() => {
    if (selectedKey) {
      getLeadFilterValuesGetRequest(selectedKey, filterValueSearch).then((res) => {
        if (res?.status === ENUM_API_STATUS?.SUCCESS_GET_API) {
          setFilterValues(res?.data);
        }
      });
    }
  }, [selectedKey, filterValueSearch]);

  const toggleFilterDropdown = () => {
    setFilterDropdownVisible(!isFilterDropdownVisible);
    setIsValueDropdownVisible(false);
    getLeadFilterOptionsGetRequest().then((res) => {
      if (res?.status === ENUM_API_STATUS?.SUCCESS_GET_API) {
        setFilterOptions(res?.data);
      }
    });
  };

  const toggleFilterValuesDropdown = (key) => {
    setFilterDropdownVisible(false);
    setIsValueDropdownVisible(!isValueDropdownVisible);
    setSelectedKey(key);
  };

  const handleCheckboxChange = (key) => {
    if (filterSelectedValue.includes(key)) {
      setFilterSelectedValue((prevValues) => prevValues.filter((prevValue) => prevValue !== key));
    } else {
      setFilterSelectedValue((prevValues) => [...prevValues, key]);
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest('#filter-values-dropdown')) {
        setFilterValueSearch('');
        setIsValueDropdownVisible(false);
      }
      if (!event.target.closest('#filter-dropdown')) {
        setFilterDropdownVisible(false);
      }
    };
    window.addEventListener('mousedown', handleOutsideClick);
    return () => {
      window.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <div className='relative ml-2' id='filter-dropdown'>
      <button className='btn btn--h40 px-6 py-2 font-semibold' onClick={toggleFilterDropdown}>
        <img src={filter} alt='filter-icon' className='mr-3' />
        Filters
      </button>
      <ul
        className={`dropdown-menu md ${isFilterDropdownVisible ? 'show' : ''}`}
        style={{ minWidth: 200 }}
      >
        {filterOptions?.map((option) => (
          <li
            key={option?.key}
            className='dropdown-menu__list'
            onClick={() => toggleFilterValuesDropdown(option?.key)}
          >
            {option?.value}
          </li>
        ))}
      </ul>
      <ul
        className={`dropdown-menu overflow-y-auto ${isValueDropdownVisible ? 'show' : ''}`}
        id='filter-values-dropdown'
        style={{ width: 225, maxHeight: 245 }}
      >
        <li className='px-4 py-2 mb-1 bg-white z10' style={{ position: 'sticky', top: 0 }}>
          <input
            type='text'
            className='input-field input-field--search input-field--h32 input-field--md lato w-full'
            placeholder='Search'
            value={filterValueSearch}
            onChange={(e) => setFilterValueSearch(e?.target?.value)}
          />
        </li>
        {filterValues.map((value, index) => (
          <li className='dropdown-menu__list px-4' key={index}>
            <div className='custom-checkbox md'>
              <input
                type='checkbox'
                id={`name${index}`}
                className='custom-checkbox__input'
                checked={filterSelectedValue.includes(`${selectedKey}-${value}`)}
                onChange={() => handleCheckboxChange(`${selectedKey}-${value}`)}
              />
              <label htmlFor={`name${index}`} className='custom-checkbox__label'>
                <code className='custom-checkbox__label__box'></code>
                <span className='text-secondary text-sm font-semibold ml-2'>{value}</span>
              </label>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LeadFilter;
