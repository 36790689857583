import { Link, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { hearderCreateButtonHide, resetData } from '../store/_actions';
import { useDispatch, useSelector } from 'react-redux';

import DropdownMenu from './DropdownMenu';
import hamburgerMenu from '../../../assets/icons/hamburger-menu.svg';
import logo from '../../../assets/icons/Group 776.svg';
import useThrottle from '@/utils/_gFunctions/handleDebouncing';
import { useTranslation } from 'react-i18next';

const Header = ({ onCreateOrder, onMenuClick }) => {
  const { t } = useTranslation();
  const _gcreateButtonState = useSelector(
    (state) => state?.headerCreateNewButtonStateHandle?.hearderCreateButtonHide
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);

  const handleClick = () => {
    setShowDropdown(!showDropdown);
  };
  const location = useLocation();
  if (location.pathname.includes('dashboard')) {
    dispatch(hearderCreateButtonHide(true));
  } else {
    dispatch(hearderCreateButtonHide(false));
  }

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest('#dropdownMenu')) {
        setShowDropdown(false);
      }
    };
    window.addEventListener('mousedown', handleOutsideClick);
    return () => {
      window.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);
  const handleLogoclick = () => {
    dispatch(resetData());
    navigate('/dp/dashboard/');
  };
  const throttledhandleLogoClick = useThrottle(handleLogoclick , 300)
  return (
    <header className='page-header'>
      <div className='max-container h-full flex items-center justify-between w-full'>
        <div
          className='flex items-center justify-center cursor-pointer'
          onClick={() => throttledhandleLogoClick()}
        >
          <img src={logo} alt='logo' style={{ height: '34px' }} />
        </div>
        <div className='flex items-center'>
          {_gcreateButtonState && (
            <Link
              to='/dp/order/form'
              className='hide sm-flex btn btn--primary btn--h40 text-base px-8 py-1'
            >
              <svg width='15' height='15' viewBox='0 0 15 15' fill='none' className='mr-3'>
                <path
                  d='M7.5 1.5L7.5 13.5M13.5 7.5L1.5 7.5'
                  stroke='white'
                  strokeWidth='2'
                  strokeLinecap='round'
                />
              </svg>
              {t('create_new_order')}
            </Link>
          )}

          <div
            className='sm-hidden flex items-center justify-center flex-shrink-0 bg-primary-brand rounded-full'
            style={{ width: 32, height: 32 }}
          >
            <svg width='15' height='15' viewBox='0 0 15 15' fill='none'>
              <path
                d='M7.5 1.5L7.5 13.5M13.5 7.5L1.5 7.5'
                stroke='white'
                strokeWidth='2'
                strokeLinecap='round'
              />
            </svg>
          </div>
          <div className='relative ml-5' onClick={handleClick} id='dropdownMenu'>
            <img src={hamburgerMenu} alt='hamburger-menu' className='cursor-pointer' />
            <DropdownMenu showDropdown={showDropdown} />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
