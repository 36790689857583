import { DPuserRoles, ENUM_DP_USER_STATUS, Permissions } from '@/utils/_gConstant';

import DropdownMenu from './DropdownMenu';
import { Link } from 'react-router-dom';
import { getInitials } from '@/utils/_gFunctions/_handleGetInitials';
import { setDPUserDetails } from '../store/_actions';
import { useAuth } from '@/components/auth/core/Auth';
import { useDispatch } from 'react-redux';

const TableRow = (props) => {
  const { dpUsersList, refreshList } = props;
  const hasPermission = useAuth().hasPermission;
  const dispatch = useDispatch();

  const hasDeletePermission = (role) => {
    if (role === DPuserRoles.DP_MASTER) return hasPermission(Permissions.DELETE_DP_MASTER);
    if (role === DPuserRoles.DP_ADMIN) return hasPermission(Permissions.DELETE_DP_ADMIN);
    if (role === DPuserRoles.DP_USER) return hasPermission(Permissions.DELETE_BACKOPS_USER);
  };

  const hasChangeStatusPermission = (role) => {
    if (role === DPuserRoles.DP_MASTER) return hasPermission(Permissions.CHANGE_DP_MASTER);
    if (role === DPuserRoles.DP_ADMIN) return hasPermission(Permissions.CHANGE_DP_ADMIN);
    if (role === DPuserRoles.DP_USER) return hasPermission(Permissions.CHANGE_DP_USER);
  };

  return (
    <>
      {dpUsersList?.map((DPUser, index) => (
        <tr key={index}>
          <td>
            <div className='flex items-center'>
              <figure
                className='avatar-container'
                style={{
                  width: 32,
                  height: 32,
                  backgroundColor: '#FF767B',
                  border: '1px solid #F3F2EC',
                }}
              >
                <p className='text-white text-13 font-medium'>{getInitials(DPUser?.first_name)}</p>
              </figure>
              <Link to='/dp/users-profile/'onClick={()=>(dispatch(setDPUserDetails(DPUser)))} >
                <span className='secondary-font font-regular ml-4'>
                  {DPUser.first_name.length > 15
                    ? `${DPUser.first_name.substring(0, 12)}...`
                    : DPUser.first_name}{' '}
                  {DPUser.last_name.length > 15
                    ? `${DPUser.last_name.substring(0, 12)}...`
                    : DPUser.last_name}
                </span>
              </Link>
            </div>
          </td>
          <td className=''>
            {DPUser.status === ENUM_DP_USER_STATUS.ACTIVE ? (
              <span className='secondary-font text-green font-regular'>ACTIVE</span>
            ) : DPUser.status === ENUM_DP_USER_STATUS.PENDING ? (
              <span className='secondary-font text-warning font-regular'>PENDING</span>
            ) : DPUser.status === ENUM_DP_USER_STATUS.INACTIVE ? (
              <span className='secondary-font text-red font-regular'>INACTIVE</span>
            ) : null}
          </td>
          <td>{DPUser.email}</td>

          {DPUser.role === DPuserRoles.DP_ADMIN ? <td>ADMIN</td> : <td>USER</td>}
          <td>{DPUser.no_of_clients}</td>
          <td>
            <div className='flex items-center justify-between'>
              <span>{DPUser.count}</span>
              <DropdownMenu
                index={index}
                hasDeletePermission={hasDeletePermission(DPUser.role)}
                dpUser={DPUser}
                refreshList={refreshList}
                hasChangeStatusPermission={hasChangeStatusPermission(DPUser.role)}
              />
            </div>
          </td>
        </tr>
      ))}
    </>
  );
};

export default TableRow;
