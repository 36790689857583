import '../../assets/styles/scss/components/progress-bar.scss';

import React from 'react';

const ProgressBar = ({ width, backgroundColor, borderRadius = true }) => {
  const progressBarStyle = {
    width: width,
    backgroundColor: backgroundColor,
  };

  const parentStyle = {
    borderRadius: borderRadius ? '6px' : '0',
  };

  return (
    <div className='progress-bar' style={parentStyle}>
      <code className='progress-bar__container' style={progressBarStyle}></code>
    </div>
  );
};

export default ProgressBar;
