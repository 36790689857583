import { useDispatch, useSelector } from 'react-redux';

import { ENUM_API_STATUS } from '../../utils/_gConstant';
import close from '../../assets/icons/close.svg';
import { deleteTagRequest } from './_request';
import { selectedTagName } from './store/action';
import { toastSuccess } from '../../components/ui-elements/_Toastify';
import trashIcon from '../../assets/icons/trash-lg.svg';

const DeleteTag = (props) => {
  const { closeModal, refreshList } = props;
  const dispatch = useDispatch();
  const tag = useSelector((state) => state?.TagName?.selectedTagName);
  const handleCloseclick = () => {
    dispatch(selectedTagName(null));
    closeModal();
  };
  const handleDelete = async () => {
    const res = await deleteTagRequest(tag?.id);
    if (res?.status === ENUM_API_STATUS?.SUCCESS_DELETE_API) {
      refreshList();
      toastSuccess('Tag Deleted Successfully');
      handleCloseclick();
    }
  };
  return (
    <section className={`modal modal--delete-user open`}>
      <div className='modal__container' style={{ width: 550 }}>
        <div className='modal__header'>
          <h1 className='text-lg font-bold'>Are you sure?</h1>
          <div className='modal__close' onClick={handleCloseclick}>
            <img src={close} alt='close' />
          </div>
        </div>
        <div className='modal__body' style={{ backgroundColor: '#FFF8F8' }}>
          <div className='flex flex-col items-center'>
            <img src={trashIcon} alt='trash' />
            <p className='font-medium mt-5'>
              Do you want to delete&nbsp;
              <span className='font-bold'>{tag?.name}</span>? If yes, click 'delete.'
            </p>
          </div>
        </div>
        <div className='modal__footer justify-end'>
          <button
            className='btn btn--secondary btn--h40 px-6 py-2 text-base font-semibold'
            onClick={handleCloseclick}
          >
            Cancel
          </button>
          <button
            className='btn btn--red btn--h40 px-6 py-2 text-base font-semibold'
            onClick={handleDelete}
          >
            Delete
          </button>
        </div>
      </div>
    </section>
  );
};

export default DeleteTag;
