import {
  ENUM_API_STATUS,
  ORDER_STATUS,
  isEmpty,
  leadStageColors,
  leadStageOptions,
} from '@/utils/_gConstant';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import DPLeadFilters from './DPLeadFilters';
import Header from '../components/Header';
import arrowGroup from '../../../assets/icons/arrow-group.svg';
import closeSmall from '@/icons/close-sm.svg';
import eyeIcon from '../../../assets/icons/eye-outline.svg';
import filter from '@/icons/filter.svg';
import { formatDate } from '@/utils/_gFunctions/_handleDateFormat';
import { getDPLeadsRequest } from '../store/request';
import loader from '@/icons/loading.gif';
import { useDebounce } from '@/utils/_gFunctions/handleDebouncing';
import { useTranslation } from 'react-i18next';

const DPuserLeadTable = (props) => {
  const { t } = useTranslation();
  const [isloading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState();
  const [searchValue, setSearchValue] = useState('');
  const [sortValue, setSortValue] = useState({
    label: t('leads.newest_first'),
    value: '-enq_date',
  });
  const dpList = useRef(null);
  const [showSort, setShowSort] = useState(false);
  const searchDebouncedValue = useDebounce(searchValue, 500);
  const [nextPage, setNextPage] = useState(1);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    document.title = props.title;
  }, [props.title]);
  const initialFilters = useMemo(() => {
    if (location.state?.kpi) {
      return [
        {
          key: 'kpi',
          value: location.state.kpi.value,
          label: location.state.kpi.label,
        },
      ];
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [filterValues, setFilterValues] = useState(initialFilters);

  const sortOptions = useMemo(() => {
    return [
      {
        label: t('leads.newest_first'),
        value: '-enq_date',
      },
      {
        label: t('leads.oldest_first'),
        value: 'enq_date',
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterParams = useMemo(() => {
    const filters = {};
    if (searchValue) filters['search'] = searchDebouncedValue;
    if (filterValues.length > 0) {
      filterValues.forEach((filter) => {
        filters[filter.key] = filter.value;
      });
    }
    if (sortValue.value) filters['o'] = sortValue.value;
    return filters;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchDebouncedValue, filterValues, sortValue]);

  const updateSortingOption = useCallback(
    (option) => {
      setSortValue(option);
      setShowSort(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSortValue, setSortValue]
  );

  const fetchLeadData = async (page = 1, reset = false) => {
    const queryParams = { page, page_size: 20, ...filterParams };
    const res = await getDPLeadsRequest(queryParams);
    if (res?.status === ENUM_API_STATUS?.SUCCESS_GET_API) {
      setTableData((prevData) => {
        const data = res?.data?.results;
        setNextPage(res?.data?.next);
        setIsLoading(false);
        if (prevData && !reset) {
          return [...prevData, ...data];
        } else {
          return data;
        }
      });
    }
  };

  useEffect(() => {
    fetchLeadData(1, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterParams]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest('#sort-dropdown')) {
        setShowSort(false);
      }
    };
    window.addEventListener('mousedown', handleOutsideClick);
    return () => {
      window.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    const scrollHandler = () => {
      if (
        dpList.current &&
        dpList.current.scrollTop + dpList.current.clientHeight >= dpList.current.scrollHeight &&
        nextPage
      ) {
        fetchLeadData(nextPage, false);
      }
    };
    dpList?.current?.addEventListener('scroll', scrollHandler);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (dpList.current) dpList.current.removeEventListener('scroll', scrollHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dpList, nextPage, dpList.current]);
  return (
    <>
      <Header />
      <main
        className='admin-dashboard__main admin-dashboard__main--leads-card'
        style={{ paddingTop: 88 }}
      >
        <div className='flex items-center w-full px-9'>
          <h1 className='text-36 font-semibold'>{t('leads.title')}</h1>
          <div className='flex items-center ml-9'>
            <div className='relative'>
              <input
                type='text'
                className='input-field input-field--search input-field--h40 input-field--md lato'
                style={{ width: 282 }}
                placeholder={t('leads.search_placeholder')}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
            <DPLeadFilters filterValues={filterValues} setFilterValues={setFilterValues} />
            <div className='relative ml-2' id='sort-dropdown'>
              <button
                className='btn btn--h40 px-6 py-2 font-semibold'
                onClick={() => setShowSort((show) => !show)}
              >
                <img src={arrowGroup} alt='arrow-group-icon' className='mr-3' />
                {t(sortValue.label)}
              </button>
              <ul className={`dropdown-menu md${showSort ? ' show' : ''}`}>
                {sortOptions.map((option) => (
                  <li
                    key={option.value}
                    className='dropdown-menu__list'
                    onClick={() => updateSortingOption(option)}
                  >
                    {t(option.label)}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        {filterValues.length !== 0 && (
          <div className='mt-4 px-9'>
            <div className='flex items-start'>
              <figure
                className='avatar-container avatar-container--contain'
                style={{ width: 28, height: 28, backgroundColor: '#E3E1D8' }}
              >
                <img src={filter} alt='funnel' />
              </figure>
              {filterValues?.map((item) => (
                <ul className='pills-wrapper ml-4'>
                  <li
                    className='pills-container font-semibold h28 mr-4'
                    style={{ backgroundColor: '#E3E1D9' }}
                  >
                    {t(item.label)}
                    <div
                      className='pills__close'
                      onClick={() =>
                        setFilterValues((prevState) => {
                          return prevState.filter((filterItem) => filterItem.key !== item.key);
                        })
                      }
                    >
                      <img src={closeSmall} alt='close-btn' />
                    </div>
                  </li>
                </ul>
              ))}

              <button
                className='btn btn--text btn--h28 flex-shrink-0 secondary-font px-2 font-semibold'
                onClick={() => setFilterValues([])}
              >
                {t('leads.clear_all')}
              </button>
            </div>
          </div>
        )}

        <div className='h-full px-10 mt-6 pb-8 overflow-y-auto' ref={dpList}>
          <table className='table-styles' style={{ maxHeight: '100%' }}>
            <thead>
              <tr>
                <td>{t('leads.enq_id')}</td>
                <td>{t('leads.name')}</td>
                <td>{t('leads.mobile')}</td>
                <td>{t('leads.email')}</td>
                <td>{t('leads.postcode')}</td>
                <td>
                  <div className='secondary-font flex items-center text-sm font-bold'>
                    {t('leads.enquiry_date')}
                  </div>
                </td>
                <td>{t('leads.stage')}</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {tableData?.map((item, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td>{item?.enq_id}</td>
                    <td>
                      <div className='primary-font text-lg font-medium'>
                        {item?.full_name}
                        {item?.order?.status === ORDER_STATUS.CANCELLED && (
                          <span className='legend legend--red secondary-font ml-1'>
                            {t('cancelled')}
                          </span>
                        )}
                      </div>
                    </td>
                    <td>{item?.mobile_number}</td>
                    <td>{item?.email}</td>
                    <td>{item?.postal_code}</td>
                    <td>{formatDate(item?.enq_date)}</td>
                    <td>
                      <div className='tags' style={leadStageColors[item?.stages] || {}}>
                        {t(leadStageOptions.find((option) => option.value === item?.stages)?.label)}
                      </div>
                    </td>
                    <td>
                      <div className='flex items-center justify-end'>
                        <div
                          className='flex items-center w-5 h-5 ml-1 cursor-pointer'
                          onClick={() => navigate(`/dp/leads/details/${item?.id}/`)}
                        >
                          <img src={eyeIcon} alt='icon-eye' />
                        </div>
                      </div>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
              {nextPage !== null ? (
                <img
                  src={loader}
                  alt='loader'
                  style={{ width: '25px', height: '25px', margin: '10px' }}
                />
              ) : null}
            </tbody>
          </table>
          {isEmpty(tableData) && !isloading ? (
            <div className='flex w-full mt-10' style={{ justifyContent: 'center' }}>
              <div style={{ marginBottom: '5vh', fontSize: '30px', fontWeight: 'bold' }}>
                {t('leads.no_data_found')}
              </div>
            </div>
          ) : null}
        </div>
      </main>
    </>
  );
};

export default DPuserLeadTable;
