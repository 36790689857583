import {
  ENUM_API_STATUS,
  ENUM_LEAD_CREATED_FROM,
  ORDER_STATUS,
  leadStageColors,
  leadStageOptions,
} from '../../utils/_gConstant';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { LeadListViewGetRequest } from './_request';
import editIcon from '../../assets/icons/edit.svg';
import eyeIcon from '../../assets/icons/eye-outline.svg';
import { formatDate } from '../../utils/_gFunctions/_handleDateFormat';
import loader from '../../assets/icons/loading.gif';
import { useNavigate } from 'react-router-dom';

const LeadTable = (props) => {
  const {
    leadsData,
    setModalData,
    setIsModalOpen,
    setLeadsData,
    filterSelectedObject,
    searchValue,
  } = props;

  // eslint-disable-next-line no-unused-vars
  const [page, setPage] = useState(null);
  const leadsSection = useRef(null);
  const [nextPage, setNextPage] = useState(1);

  const updateLeads = useCallback(
    (page, reset = false) => {
      if (page !== null) {
        LeadListViewGetRequest(
          { page, page_size: 20, search: searchValue, ...filterSelectedObject },
          true
        ).then((res) => {
          if (res?.status === ENUM_API_STATUS.SUCCESS_GET_API) {
            if (!reset) {
              setLeadsData((leads) => [
                ...leads,
                ...(res && res.data && res.data.results ? res.data.results : []),
              ]);
            } else {
              setLeadsData(res?.data?.results);
            }
            setPage(res?.data?.page);
            // setTotalLeads(res?.data?.count);
            setNextPage(res?.data?.next);
          }
        });
      }
    },
    [filterSelectedObject, searchValue, setLeadsData]
  );

  useEffect(() => {
    updateLeads(1, true);
  }, [updateLeads]);

  useEffect(() => {
    const scrollHandler = () => {
      if (
        leadsSection.current &&
        leadsSection.current.scrollTop + leadsSection.current.clientHeight >=
          leadsSection.current.scrollHeight
      ) {
        updateLeads(nextPage);
      }
    };
    leadsSection.current.addEventListener('scroll', scrollHandler);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (leadsSection.current) leadsSection.current.removeEventListener('scroll', scrollHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateLeads, nextPage, leadsSection.current]);

  const handleEditModal = (Lead) => {
    setModalData({
      add: false,
      lead: Lead,
    });
    setIsModalOpen(true);
  };
  const navigate = useNavigate();
  return (
    <div className='h-full px-10 mt-6 pb-8 overflow-y-auto' ref={leadsSection}>
      <table className='table-styles' style={{ maxHeight: '100%' }}>
        <thead>
          <tr>
            <td>Enq Id</td>
            <td>Name</td>
            <td>Mobile</td>
            <td>Email</td>
            <td>Postcode</td>
            <td>
              <div className='secondary-font flex items-center text-sm font-bold'>Enquiry Date</div>
            </td>
            <td>Stage</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {leadsData.map((item, index) => (
            <React.Fragment key={index}>
              <tr>
                <td>{item?.enq_id}</td>
                <td>
                  <div className='primary-font text-lg font-medium'>
                    <span className='mb-1 block w-full'>
                      {item?.first_name} {item?.last_name}
                    </span>
                    {item?.created_from?.type !== ENUM_LEAD_CREATED_FROM?.DISTRIBUTOR ? (
                      <span className='legend legend--ikosia secondary-font'>IKOSIA</span>
                    ) : (
                      <>
                        <span className='legend legend--dp secondary-font'>
                          {item?.created_from?.distributor?.name?.toUpperCase()}
                        </span>
                        {item?.order?.status === ORDER_STATUS.CANCELLED && (
                          <span className='legend legend--red secondary-font ml-1'>
                            {item?.order?.status}
                          </span>
                        )}
                      </>
                    )}
                  </div>
                </td>
                <td>{item?.mobile_number}</td>
                <td>{item?.email}</td>
                <td>{item?.postal_code}</td>
                <td>{formatDate(item?.enq_date)}</td>
                <td>
                  <div className='tags' style={leadStageColors[item?.stages] || {}}>
                    {leadStageOptions.find((option) => option.value === item?.stages)?.label}
                  </div>
                </td>
                <td>
                  <div className='flex items-center justify-end'>
                    <div
                      className='flex items-center w-5 h-5 cursor-pointer'
                      onClick={() => handleEditModal(item)}
                    >
                      <img src={editIcon} alt='icon-edit' />
                    </div>
                    <div
                      className='flex items-center w-5 h-5 ml-1 cursor-pointer'
                      onClick={() => navigate(`/admin/leads/details/${item?.id}/`)}
                    >
                      <img src={eyeIcon} alt='icon-eye' />
                    </div>
                  </div>
                </td>
              </tr>
            </React.Fragment>
          ))}
          {nextPage != null ? (
            <img
              src={loader}
              alt='loader'
              style={{ width: '25px', height: '25px', margin: '10px' }}
            />
          ) : null}
        </tbody>
      </table>
    </div>
  );
};

export default LeadTable;
