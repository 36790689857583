import Stages from './Stages';
import { leadStageOptions } from '../../utils/_gConstant';

export const LeadCardView = (props) => {
  const {
    onCardDrop,
    setIsModalOpen,
    setModalData,
    handleAssignConsultantModal,
    setLeadsData,
    deleteLead,
    leadsData,
    filterSelectedObject,
    searchValue,
    setFilterSelectedValue,
    totalLeadsInStages,
    setTotalLeadsInStages,
  } = props;
  return (
    <ul className='leads-list-wrapper px-10 pt-6'>
      {leadStageOptions?.map((stage, index) => (
        <Stages
          filterSelectedObject={filterSelectedObject}
          searchValue={searchValue}
          key={index}
          stageId={stage.value}
          leads={leadsData.filter((lead) => lead.stages === stage.value)}
          setLeadsData={setLeadsData}
          onCardDrop={onCardDrop}
          setIsModalOpen={setIsModalOpen}
          setModalData={setModalData}
          handleAssignConsultantModal={handleAssignConsultantModal}
          deleteLead={deleteLead}
          setFilterSelectedValue={setFilterSelectedValue}
          totalLeadsInStages={totalLeadsInStages}
          setTotalLeadsInStages={setTotalLeadsInStages}
        />
      ))}
    </ul>
  );
};
