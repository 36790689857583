import React from 'react';
import Select from 'react-select';

const CustomSelect = ({
  name,
  className,
  placeholder,
  options,
  maxMenuHeight = 150,
  value,
  onChange,
  theme,
  validationError,
}) => {
  const defaultTheme = (theme) => ({
    ...theme,
    borderRadius: 8,
    colors: {
      ...theme.colors,
      primary25: '#c6eccb',
      primary: '#00bd1e',
    },
  });

  return (
    <Select
      name={name}
      className={className}
      placeholder={placeholder}
      options={options}
      maxMenuHeight={maxMenuHeight}
      value={value}
      onChange={onChange}
      theme={theme || defaultTheme}
    />
  );
};

export default CustomSelect;
