import '@/styles/scss/pages/home-owner-dashboard.scss';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import ChangePassword from '../../components/auth/component/ChangePassword';
import Cookies from 'universal-cookie';
import { ENUM_API_STATUS } from '../../utils/_gConstant';
import MyProfile from '../../components/auth/component/MyProfile';
import PageFooter from '../../components/common/PageFooter';
import { PopupModal } from 'react-calendly';
import ProgressBar from '../../components/common/ProgressBar';
import calendarCheck from '@/icons/calendar-check.svg';
import cloud from '../../assets/icons/cloud.svg';
import cloudWhite from '../../assets/icons/cloud-white.svg';
import euro from '../../assets/icons/euro-sm.svg';
import euroWhite from '../../assets/icons/euro-white.svg';
import evCharger from '../../assets/icons/ev-charger.svg';
import finance from '../../assets/icons/finance.svg';
import floorSlab from '../../assets/icons/floor-slab.svg';
import { getDashboardDataGetRequest } from '../../components/auth/core/_request';
import heatPump from '../../assets/icons/heat-pump.svg';
import houseLayout from '../../assets/img/house-layout.svg';
import infoIcon from '../../assets/icons/info-icon.svg';
import insulate from '../../assets/icons/insulate.svg';
import logo from '../../assets/img/ikosia-logo.svg';
import plug from '../../assets/icons/plug.svg';
import plugLg from '../../assets/icons/plug-lg-white.svg';
import plugWhite from '../../assets/icons/plug-white.svg';
import profile from '../../assets/icons/profile-fill.svg';
import redoIcon from '../../assets/icons/redo.svg';
import slandingArrowBottomleft from '../../assets/icons/slanding-arrow-bottom-left-color.svg';
import slandingArrowTopRight from '../../assets/icons/slanding-arrow-top-right.svg';
import tasks from '../../assets/icons/tasks.svg';
import tick from '../../assets/icons/tick-white.svg';
import { toastSuccess } from '../../components/ui-elements/_Toastify';
import userFill from '../../assets/icons/user-fill.svg';
import userIcon from '../../assets/icons/user.svg';
import userWhite from '../../assets/icons/user-white.svg';
import windowIcon from '../../assets/icons/window.svg';

const projectStatusData = [
  {
    id: 1,
    count: 1,
    title: 'Analyse',
    desc: '',
    key: 'analyse',
  },
  {
    id: 2,
    count: 2,
    title: 'Kostenloses Erstgespräch',
    desc: 'Wir beantworten alle deine Fragen',
    key: 'initial_consultation',
  },
  {
    id: 3,
    count: 3,
    title: 'Vor-Ort-Termin',
    desc: 'Ein Experte ist in 14 Tagen für dich verfügbar',
    key: 'on_site_appointment',
  },
  {
    id: 4,
    count: 4,
    title: 'Handwerkerangebote und Sanierungsfahrplan',
    desc: '',
    key: 'renovation_schedule',
  },
];

const HomeOwnerDashboard = (props) => {
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState({});
  const [turnCalendly, setTurnCalendly] = useState(false);
  const navigate = useNavigate();
  const user = useSelector((state) => state?.authReducer?.user);
  const { leadId } = useParams();
  const [selectedSummary, setSelectedSummary] = useState('euro');

  const [isProfileDropdownVisible, setProfileDropdownVisible] = useState(false);
  const toggleProfileDropdown = () => {
    setProfileDropdownVisible(!isProfileDropdownVisible);
  };
  const [myProfileVisible, setMyProfileVisible] = useState(false);
  const toggleMyProfile = () => {
    setMyProfileVisible(!myProfileVisible);
    document.querySelector('#appBody').style.overflow = 'hidden';
    if (myProfileVisible) {
      document.querySelector('#appBody').style.overflow = 'initial';
    }
  };
  const [changePasswordVisible, setChangePasswordVisible] = useState(false);
  const toggleChangePassword = () => {
    setChangePasswordVisible(!changePasswordVisible);
    document.querySelector('#appBody').style.overflow = 'hidden';
    if (changePasswordVisible) {
      document.querySelector('#appBody').style.overflow = 'initial';
    }
  };
  useEffect(() => {
    document.title = props.title;
    const handleOutsideClick = (event) => {
      if (!event.target.closest('#profile-section')) {
        setProfileDropdownVisible(false);
      }
    };
    window.addEventListener('mousedown', handleOutsideClick);
    return () => {
      window.removeEventListener('mousedown', handleOutsideClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getLeadStatistics(leadId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadId]);

  const renovations = useMemo(() => {
    let renovations = [
      {
        id: 2,
        icon: insulate,
        title: 'Fassade dämmen',
        renovationCost: dashboardData?.renovations?.wall?.renovation_cost,
        annualSavings: dashboardData?.renovations?.wall?.annual_savings,
        paybackPeriod: dashboardData?.renovations?.wall?.payback_period,
        // percentage: dashboardData?.renovations?.wall?.percentage,
      },
      {
        id: 3,
        icon: windowIcon,
        title: 'Fenster & Haustür austauschen',
        renovationCost: dashboardData?.renovations?.window_and_door?.renovation_cost,
        annualSavings: dashboardData?.renovations?.window_and_door?.annual_savings,
        paybackPeriod: dashboardData?.renovations?.window_and_door?.payback_period,
        // percentage: dashboardData?.renovations?.window_and_door?.percentage,
      },
      {
        id: 4,
        icon: floorSlab,
        title: 'Bodenplatte dämmen',
        renovationCost: dashboardData?.renovations?.floor?.renovation_cost,
        annualSavings: dashboardData?.renovations?.floor?.annual_savings,
        paybackPeriod: dashboardData?.renovations?.floor?.payback_period,
        // percentage: dashboardData?.renovations?.floor?.percentage,
      },
    ];
    if (dashboardData?.renovations?.heat_pump) {
      renovations.unshift({
        id: 1,
        icon: heatPump,
        title: 'Wärmepumpe',
        renovationCost: dashboardData?.renovations?.heat_pump?.renovation_cost,
        annualSavings: dashboardData?.renovations?.heat_pump?.annual_savings,
        paybackPeriod: dashboardData?.renovations?.heat_pump?.payback_period,
        // percentage: dashboardData?.renovations?.heat_pump?.percentage,
      });
    }
    return renovations;
  }, [dashboardData]);

  const getLeadStatistics = useCallback((leadId) => {
    getDashboardDataGetRequest(leadId).then((r) => {
      if (r?.status === ENUM_API_STATUS?.SUCCESS_GET_API) {
        setDashboardData(r?.data);
      } else if (
        [ENUM_API_STATUS?.ERROR_401_BAD_REQ, ENUM_API_STATUS?.ERROR_403_BAD_REQ].includes(r.status)
      ) {
        // toastInfo("Bitte melden Sie sich an, um Ihr Dashboard anzuzeigen.");
        localStorage.clear();
        dispatch({ type: 'USER_LOGOUT' });
        cookies.remove('dashboard', {
          domain: process.env.REACT_APP_DOMAIN,
          path: '/',
        });

        cookies.remove('userName', {
          domain: process.env.REACT_APP_DOMAIN,
          path: '/',
        });
        navigate('/');
      } else if (r?.status === ENUM_API_STATUS?.REDIRECT_302) navigate(`/survey/${leadId}/0/`);
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedSummaryData = useMemo(() => {
    if (dashboardData.summary === undefined) return {};
    return dashboardData.summary[selectedSummary];
  }, [dashboardData, selectedSummary]);

  const selectedUnit = useMemo(() => {
    if (selectedSummary === 'euro') return '€';
    if (selectedSummary === 'electricity') return 'kWh';
    return (
      <>
        kg CO<sub>2</sub>
      </>
    );
  }, [selectedSummary]);

  const ComingSoon = useCallback(
    () => (
      <div className='coming-soon-container bg-white'>
        <p className='text-green'>Coming Soon</p>
      </div>
    ),
    []
  );

  return (
    <>
      {isLoading && (
        <div className='loader-wrapper'>
          <div className='loader'></div>
        </div>
      )}
      {!isLoading && (
        <>
          <section className='home-owner-dashboard'>
            <header className='home-owner-dashboard__header'>
              <div className='home-owner-dashboard__header__container max-container flex items-center justify-between w-full'>
                <div className='flex items-center justify-center home-owner-dashboard__logo-container'>
                  <img src={logo} alt='logo' />
                </div>
                <div className='flex items-center'>
                  {/* <div style={{ width: 248 }} className="hidden-sm">
                <input
                  type="text"
                  className="input-field input-field--search bg-transparent"
                  placeholder="Search"
                />
              </div> */}
                  {/* <div className="btn--action btn--action--search hidden-xl flex-sm">
                <img src={search} className="" alt="search-icon" />
              </div> */}
                  {/* <div className="btn--action btn--action--notification ml-2">
                <img src={bell} alt="notfication-icon" style={{ height: 18 }} />
              </div> */}
                  {/* <div className="btn--action btn--action--notification ml-2">
                <img src={settings} alt="settings-icon" />
              </div> */}
                  {user != null && (
                    <div
                      className='relative btn--action btn--action--user'
                      onClick={toggleProfileDropdown}
                      id='profile-section'
                    >
                      <img src={userIcon} alt='user-icon' />
                      <ul
                        className={`dropdown-menu md w-full ${
                          isProfileDropdownVisible ? 'show' : ''
                        }`}
                        style={{ width: 155, left: 'initial', right: 0 }}
                      >
                        <li
                          className='dropdown-menu__list'
                          style={{
                            borderBottom: '1px solid #E2E2E2',
                            marginBottom: 0,
                          }}
                          onClick={toggleMyProfile}
                        >
                          Mein Profil
                        </li>
                        <li
                          className='dropdown-menu__list'
                          style={{
                            borderBottom: '1px solid #E2E2E2',
                            marginBottom: 0,
                          }}
                          onClick={toggleChangePassword}
                        >
                          Kennwort ändern
                        </li>
                        <li
                          className='dropdown-menu__list'
                          style={{ marginBottom: 0 }}
                          onClick={() => {
                            localStorage.clear();
                            dispatch({ type: 'USER_LOGOUT' });
                            cookies.remove('dashboard', {
                              domain: process.env.REACT_APP_DOMAIN,
                              path: '/',
                            });

                            cookies.remove('userName', {
                              domain: process.env.REACT_APP_DOMAIN,
                              path: '/',
                            });

                            toastSuccess('Sie wurden abgemeldet!');
                            window.location.replace(process.env.REACT_APP_LANDING_PAGE_URL);
                          }}
                        >
                          Ausloggen
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </header>
            <main className='home-owner-dashboard__main'>
              <div className='max-container flex flex-wrap'>
                <div className='home-owner-dashboard__section-left'>
                  <h1 className='text-32 font-medium px-5-md-0 px-5'>{`Hello, ${
                    (user?.first_name || dashboardData?.contact_details?.first_name || '').length >
                    15
                      ? (
                          user?.first_name ||
                          dashboardData?.contact_details?.first_name ||
                          ''
                        ).slice(0, 15) + '...'
                      : user?.first_name || dashboardData?.contact_details?.first_name || ''
                  }!`}</h1>
                  <div className='mt-9'>
                    <div className='px-5 pb-8 pb-5-sm border1-b px-5-md-0'>
                      <div className='flex items-center'>
                        <div
                          className='relative flex items-center justify-center flex-shrink-0'
                          style={{ top: -1 }}
                        >
                          <img src={tasks} alt='task-icon' />
                        </div>
                        <span className='text-xl font-medium ml-3'>Deine nächsten Schritte</span>
                      </div>
                      <ul className='project-status mt-4'>
                        {projectStatusData.map((item, index) => (
                          <li
                            key={index}
                            className={`project-status__list ${
                              dashboardData?.steps[item.key] ? 'active' : ''
                            }`}
                          >
                            <div className='flex items-start'>
                              <div className='project-status__count'>
                                <img src={tick} alt='tick-icon' />
                                <span className='secondary-font color-tertiary-brand text-13 font-medium'>
                                  {item.count}
                                </span>
                              </div>
                              <div className='flex flex-col ml-3'>
                                <h3 className='project-status__title'>{item.title}</h3>
                                {item.desc && (
                                  <p className='project-status__desc mt-1'>{item.desc}</p>
                                )}
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className='px-5 mt-6 pb-5 pb-5-sm border1-b px-5-md-0'>
                      <div className='flex items-center'>
                        <div className='relative flex items-center justify-center flex-shrink-0 w-6 h-6'>
                          <img src={profile} alt='profile-icon' />
                        </div>
                        <span className='text-xl font-medium leading3 ml-3'>Meine Angaben</span>
                      </div>
                      <ul className='mt-4'>
                        <li className='flex flex-wrap items-center -mx-2'>
                          <div className='col-5 text-lg font-medium px-2 mb-3'>Vorname</div>
                          <div className='col-7 secondary-font font-medium px-2 mb-3 word-break-all'>
                            {(user?.first_name || dashboardData?.contact_details?.first_name || '')
                              .length > 15
                              ? (
                                  user?.first_name ||
                                  dashboardData?.contact_details?.first_name ||
                                  ''
                                ).slice(0, 15) + '...'
                              : user?.first_name ||
                                dashboardData?.contact_details?.first_name ||
                                ''}
                          </div>
                          <div className='col-5 text-lg font-medium px-2 mb-3'>Nachname</div>
                          <div className='col-7 secondary-font font-medium px-2 mb-3 word-break-all'>
                            {(user?.last_name || dashboardData?.contact_details?.last_name || '')
                              .length > 15
                              ? (
                                  user?.last_name ||
                                  dashboardData?.contact_details?.last_name ||
                                  ''
                                ).slice(0, 15) + '...'
                              : user?.last_name || dashboardData?.contact_details?.last_name || ''}
                          </div>
                          <div className='col-5 text-lg font-medium px-2 mb-3'>Telefon</div>
                          <div className='col-7 secondary-font font-medium px-2 mb-3 word-break-all'>
                            {user?.mobile_number || dashboardData?.contact_details?.mobile_number}{' '}
                          </div>
                          <div className='col-5 text-lg font-medium px-2 mb-3'>E-Mail</div>
                          <div className='col-7 secondary-font font-medium px-2 mb-3 word-break-all'>
                            {user?.email || dashboardData?.contact_details?.email}
                          </div>
                          <div className='col-5 text-lg font-medium px-2 mb-3'>Postleitzahl</div>
                          <div className='col-7 secondary-font font-medium px-2 mb-3 word-break-all'>
                            {user?.postal_code || dashboardData?.contact_details?.postal_code}
                          </div>
                        </li>
                      </ul>
                    </div>
                    {user === null && !dashboardData?.contact_details?.is_registered && (
                      <div className='px-5 mt-6 px-5-md-0'>
                        <div className='flex items-center'>
                          <div
                            className='relative flex items-center justify-center flex-shrink-0 w-6 h-6'
                            style={{ top: -1 }}
                          >
                            <img src={userFill} alt='user-icon' />
                          </div>
                          <span className='text-xl font-medium ml-3'>Registriere dich jetzt</span>
                        </div>
                        <p className='secondary-font text-secondary leading4 mt-4'>
                          24/7 Dashboard-Zugriff, schnelle Vertragsabwicklung, direkte
                          Kommunikation.
                        </p>
                        <button
                          className='btn btn--secondary btn--h48 w-full text-base mt-5'
                          onClick={() => navigate('verify/')}
                        >
                          <img src={userWhite} alt='user-white' className='mr-3' />
                          Konto erstellen
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div className='home-owner-dashboard__section-main'>
                  <div
                    className='py-3 px-5 bg-white'
                    style={{
                      borderRadius: 52,
                      boxShadow: '0px 0px 20px rgba(197, 195, 188, 0.1)',
                    }}
                  >
                    <div className='flex items-center justify-between flex-wrap-sm -mx-2'>
                      <div className='col-8 col-12-sm flex-1 px-2 flex items-center'>
                        <div className='flex items-center justify-cente flex-shrink-0 w-7 h-7 mr-2'>
                          <img src={evCharger} alt='gift' />
                        </div>
                        <p className='font-medium leading3'>
                          Steigere die Energieeffizienz deines Hauses und spare bis zu&nbsp;
                          {dashboardData?.summary?.euro?.percentage}% deiner Energiekosten.
                        </p>
                      </div>
                      <div className='col-4 col-12-sm px-2 flex items-center justify-end justify-start-sm mt-3-sm cursor-pointer'>
                        <div className='flex items-center justify-center w-6 h-6 flex-shrink-0'>
                          <img src={redoIcon} alt='redo-icon' />
                        </div>
                        <span
                          className='inline-block text-primary-brand font-medium text-underline leading3 ml-2'
                          onClick={() => navigate(`/survey/${leadId}/0/`)}
                        >
                          Energieberechnung wiederholen
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='flex flex-wrap-lg mt-6'>
                    <div className='flex-1 col-12-md pr-5 pr-5-md-0'>
                      <div className='flex items-center flex-wrap'>
                        <div className='flex items-center col-12-sm'>
                          <h2 className='text-xl font-semibold' style={{ color: '#161F3A' }}>
                            Analyse
                          </h2>
                          <div className='relative info-icon flex items-center justify-center flex-shrink-0 w-4 h-4 ml-2 ml-auto cursor-pointer'>
                            <img src={infoIcon} alt='info-icon' />
                            <div
                              className='tooltip-container tooltip-container--analyse-info pl-5 py-5 pr-6'
                              style={{ width: 360 }}
                            >
                              <h3 className='text-black font-medium leading4'>
                                Förderung und Einsparpotential werden wie folgt berechnet:
                              </h3>
                              <p className='secondary-font text-secondary text-sm leading4 mt-2'>
                                Förderung: Die Höhe der Fördermittel orientiert sich an der
                                möglichen Unterstützung für die empfohlenen Maßnahmen, die über
                                einige Jahre hinweg beantragt werden kann. Diese Berechnung basiert
                                auf den geschätzten Sanierungskosten und dem prozentualen Fördersatz
                                pro Sanierungsprojekt. Beachte, dass es hierbei zu Abweichungen
                                kommen kann.<br></br>
                                <br></br>
                                Einsparpotential: Das potenzielle jährliche Einsparen wird anhand
                                deiner Angaben im Energie-Check Fragebogen ermittelt. Auch hier sind
                                Abweichungen möglich. Für eine genauere Berechnung empfehlen wir die
                                Erstellung eines persönlichen Sanierungsplans, der einen
                                Vor-Ort-Termin einschließt.
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className='flex items-center ml-5 ml-0-sm mt-4-sm'>
                          <input
                            type='radio'
                            id='radio1'
                            className='summary-toggle-btn__input'
                            name='summary-toggle'
                            value={'euro'}
                            checked={selectedSummary === 'euro'}
                            onChange={(e) => setSelectedSummary(e.target.value)}
                          />
                          <label htmlFor='radio1' className='summary-toggle-btn'>
                            <div className='flex items-center justify-center flex-shrink-0 w-3 h-3'>
                              <img
                                src={euro}
                                alt='euro-icon'
                                style={{ maxHeight: 10 }}
                                className='summary-toggle-btn__icon summary-toggle-btn__icon--default'
                              />
                              <img
                                src={euroWhite}
                                alt='euro-icon'
                                style={{ maxHeight: 10 }}
                                className='summary-toggle-btn__icon summary-toggle-btn__icon--white'
                              />
                            </div>
                            <span className='summary-toggle-btn__text'>Euro</span>
                          </label>
                          <input
                            type='radio'
                            id='radio2'
                            className='summary-toggle-btn__input'
                            name='summary-toggle'
                            value={'electricity'}
                            checked={selectedSummary === 'electricity'}
                            onChange={(e) => setSelectedSummary(e.target.value)}
                          />
                          <label htmlFor='radio2' className='summary-toggle-btn'>
                            <div className='flex items-center justify-center flex-shrink-0 w-3 h-3'>
                              <img
                                src={plug}
                                alt='plug-icon'
                                style={{ maxHeight: 10 }}
                                className='summary-toggle-btn__icon summary-toggle-btn__icon--default'
                              />
                              <img
                                src={plugWhite}
                                alt='plug-icon'
                                style={{ maxHeight: 10 }}
                                className='summary-toggle-btn__icon summary-toggle-btn__icon--white'
                              />
                            </div>
                            <span className='summary-toggle-btn__text'>kWh</span>
                          </label>
                          <input
                            type='radio'
                            id='radio3'
                            className='summary-toggle-btn__input'
                            name='summary-toggle'
                            value={'carbon_cost'}
                            checked={selectedSummary === 'carbon_cost'}
                            onChange={(e) => setSelectedSummary(e.target.value)}
                          />
                          <label htmlFor='radio3' className='summary-toggle-btn'>
                            <div className='flex items-center justify-center flex-shrink-0 w-3 h-3'>
                              <img
                                src={cloud}
                                alt='cloud-icon'
                                style={{ maxHeight: 10 }}
                                className='summary-toggle-btn__icon summary-toggle-btn__icon--default'
                              />
                              <img
                                src={cloudWhite}
                                alt='cloud-icon'
                                style={{ maxHeight: 10 }}
                                className='summary-toggle-btn__icon summary-toggle-btn__icon--white'
                              />
                            </div>
                            <span className='summary-toggle-btn__text'>
                              CO
                              <sub className='secondary-font text-10 font-semibold'>2</sub>
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className='flex flex-wrap' style={{ margin: '16px -6px 0' }}>
                        <div className='col-8 col-12-sm' style={{ padding: '0 6px' }}>
                          <div
                            className='h-full bg-white rounded-xl-sm'
                            style={{ borderRadius: '20px 4px 4px 20px' }}
                          >
                            <div className='flex items-center justify-between pt-5 pr-2 pl-5'>
                              <h3 className='text-xl font-medium'>Jährliches Einsparpotenzial</h3>
                              <div className='flex items-center justify-end'>
                                <span className='text-primary-brand text-32 font-medium'>
                                  {selectedSummaryData?.percentage}%
                                </span>
                                <div className='flex items-center justify-center flex-shrink-0 w-6 h-6 ml-1'>
                                  <img src={slandingArrowBottomleft} alt='arrow' />
                                </div>
                              </div>
                            </div>

                            {/* <div className="flex items-start pl-8">
                          <div className="flex flex-col justify-between">
                            <label className="secondary-font text-secondary text-sm">
                              Saving Potential
                            </label>
                            <span className="text-48 font-medium mt-3">
                              {selectedSummaryData?.savings}{" "}
                            </span>
                          </div>
                        </div> */}
                            <div className='text-40 font-medium pl-5 pt-2'>
                              {selectedSummaryData?.savings}{' '}
                              <span
                                className='text-2xl'
                                // style={{ color: "#5C6982" }}
                              >
                                {selectedUnit}
                              </span>
                            </div>
                            <div className='flex items-end pl-6 pt-1 pr-2'>
                              <div className='flex flex-1 pb-6'>
                                <div className='flex flex-col'>
                                  <label className='text-primary-brand text-32 font-medium'>
                                    {dashboardData?.summary?.measures}
                                  </label>
                                  <span className='secondary-font text-secondary text-sm mt-1'>
                                    Maßnahmen
                                  </span>
                                </div>
                                <div className='flex flex-col ml-6'>
                                  <label className='text-primary-brand text-32 font-medium'>
                                    {dashboardData?.summary?.payback_period}
                                    <span>Jahre</span>
                                  </label>
                                  <span className='secondary-font text-secondary text-sm mt-1'>
                                    Amortisationszeit
                                  </span>
                                </div>
                              </div>
                              <img
                                src={houseLayout}
                                alt='house-layout'
                                className='flex-shrink-0 ml-auto house-layout-pic'
                              />
                            </div>
                          </div>
                        </div>
                        <div className='col-4 col-12-sm mt-4-sm' style={{ padding: '0 6px' }}>
                          <div className='flex flex-col h-full'>
                            <div
                              className='relative bg-primary-brand py-4 px-4 h-full rounded-xl-sm overflow-hidden'
                              style={{
                                borderRadius: '4px 20px 4px 4px',
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                if (dashboardData?.book_appointment)
                                  setTurnCalendly(dashboardData?.book_appointment);
                              }}
                            >
                              {dashboardData?.book_appointment ? (
                                <div className='flex flex-col'>
                                  <figure
                                    className='avatar-container avatar-container--contain bg-white'
                                    style={{
                                      width: 32,
                                      height: 32,
                                      borderRadius: 8,
                                    }}
                                  >
                                    <img src={calendarCheck} alt='calendar-check' />
                                  </figure>
                                  <span className='text-lg text-white font-medium leading3 mt-6'>
                                    Einen Termin vereinbaren
                                  </span>
                                </div>
                              ) : (
                                <ComingSoon />
                              )}
                            </div>
                            <div
                              className='relative bg-white py-4 px-4 mt-3 h-full rounded-xl-sm overflow-hidden'
                              style={{ borderRadius: '4px 4px 20px 4px' }}
                              onClick={() => {
                                if (dashboardData?.request_financing)
                                  setTurnCalendly(dashboardData?.request_financing);
                              }}
                            >
                              {dashboardData?.request_financing ? (
                                <div className='flex flex-col'>
                                  <figure
                                    className='avatar-container avatar-container--contain bg-primary-brand'
                                    style={{
                                      width: 32,
                                      height: 32,
                                      borderRadius: 8,
                                    }}
                                  >
                                    <img src={finance} alt='finance-icon' />
                                  </figure>
                                  <div className='flex items-center mt-2'>
                                    <span className='text-lg font-medium text-underline leading3 cursor-pointer'>
                                      Finanzierung anfordern
                                    </span>
                                    <div className='flex items-center justify-center flex-shrink-0 w-6 h-6 ml-1'>
                                      <img src={slandingArrowTopRight} alt='finance' />
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <ComingSoon />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='mt-5'>
                        <h2 className='text-xl font-semibold' style={{ color: '#161F3A' }}>
                          Einsparungen
                        </h2>
                        <div className='flex flex-wrap' style={{ margin: '12px -6px 0' }}>
                          <div className='col-6 col-12-sm' style={{ padding: '0 6px' }}>
                            <div
                              className='relative h-full bg-white pl-5 pr-3 py-6 rounded-xl-sm'
                              style={{ borderRadius: '20px 0px 0px 20px' }}
                            >
                              {dashboardData?.savings ? (
                                <>
                                  <div>
                                    <div className='flex items-start justify-between'>
                                      <h3 className='text-xl font-medium'>Einsparungen</h3>
                                      <div className='relative info-icon flex items-center justify-center flex-shrink-0 w-4 h-4 ml-2 ml-auto cursor-pointer'>
                                        <img src={infoIcon} alt='info-icon' />
                                        <div
                                          className='tooltip-container p-5'
                                          style={{ width: 272 }}
                                        >
                                          {/* <h3 className="text-xl font-semibold">
                                        Information
                                      </h3> */}
                                          <p className='secondary-font text-secondary text-sm leading4 mt-2'>
                                            Deine Einsparungen werden anhand des festgestellten
                                            Endenergiebedarfs berechnet, der sich aus deinen
                                            Antworten in unserem Energie-Check ergibt. Dabei sind
                                            Abweichungen möglich. Für präzisere Ergebnisse raten wir
                                            zu unserer Energieberatung, die auch einen Termin vor
                                            Ort beinhaltet.
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                    <h4 className='secondary-font font-regular leading3 mt-3'>
                                      Aktuelle jährliche Energiekosten
                                    </h4>

                                    {/* <div className="mt-3">
                                  <ProgressBar
                                    width="35%"
                                    backgroundColor="#00381B"
                                    borderRadius={true}
                                  />
                                </div> */}
                                    <div className='inline-block text-xl font-medium mt-2'>
                                      {dashboardData?.savings?.estimated_cost}
                                      {' €'}
                                      {/*<span*/}
                                      {/*  className="text-xl font-medium"*/}
                                      {/*  style={{ color: "#5C6982" }}*/}
                                      {/*>*/}
                                      {/*  €*/}
                                      {/*</span>*/}
                                    </div>
                                  </div>
                                  <div className='mt-4'>
                                    <h4 className='secondary-font font-regular leading3'>
                                      Einsparungen nach der Renovierung
                                    </h4>

                                    <div className='inline-block text-xl font-medium mt-2'>
                                      {dashboardData?.savings?.savings_after_renovation}
                                      {' €'}
                                    </div>
                                  </div>
                                  <div className='mt-3'>
                                    <ProgressBar
                                      width={dashboardData?.savings?.percentage + '%'}
                                      backgroundColor='#00BD1E'
                                      borderRadius={true}
                                    />
                                  </div>
                                </>
                              ) : (
                                <ComingSoon />
                              )}
                            </div>
                          </div>
                          <div className='col-6 col-12-sm mt-4-sm' style={{ padding: '0 6px' }}>
                            <div
                              className='relative h-full bg-white pl-5 pr-3 py-6 rounded-xl-sm'
                              style={{ borderRadius: '0px 20px 20px 0px' }}
                            >
                              {dashboardData?.renovation_cost ? (
                                <>
                                  <div>
                                    <div className='flex items-center justify-between'>
                                      <h3 className='text-xl font-medium'>Kosten</h3>
                                      <div className='relative info-icon flex items-center justify-center flex-shrink-0 w-4 h-4 ml-2 ml-auto cursor-pointer'>
                                        <img src={infoIcon} alt='info-icon' />
                                        <div
                                          className='tooltip-container p-5'
                                          style={{ width: 272 }}
                                        >
                                          {/* <h3 className="text-xl font-semibold">
                                        Information
                                      </h3> */}
                                          <p className='secondary-font text-secondary text-sm leading4 mt-2'>
                                            Deine Ausgaben werden auf Grundlage früherer,
                                            vergleichbarer Projekte geschätzt. Wir bieten
                                            Unterstützung an, um dir die höchstmögliche finanzielle
                                            Unterstützung für dein Sanierungsprojekt zu sichern,
                                            durch unseren Förderungsdienst und die Energieberatung.
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                    <h4 className='secondary-font font-regular leading3 mt-3'>
                                      Sanierungskosten
                                    </h4>

                                    <div className='inline-block text-xl font-medium mt-2'>
                                      {dashboardData?.renovation_cost?.renovation_cost}
                                      {' €'}
                                      {/*<span*/}
                                      {/*  className="text-xl font-medium"*/}
                                      {/*  style={{ color: "#5C6982" }}*/}
                                      {/*>*/}
                                      {/*  €*/}
                                      {/*</span>*/}
                                    </div>
                                  </div>
                                  <div className='mt-4'>
                                    <h4 className='secondary-font font-regular leading3'>
                                      Maximale Förderung
                                    </h4>
                                    {/* <div className="mt-3">
                                  <ProgressBar
                                    width="100%"
                                    backgroundColor="#00BD1E"
                                    borderRadius={true}
                                  />
                                </div> */}
                                    <div className='inline-block text-xl font-medium mt-2'>
                                      {dashboardData?.renovation_cost?.maximum_funding}
                                      {' €'}
                                      {/*<span*/}
                                      {/*  className="text-xl font-medium"*/}
                                      {/*  style={{ color: "#5C6982" }}*/}
                                      {/*>*/}
                                      {/*  €*/}
                                      {/*</span>*/}
                                    </div>
                                  </div>
                                  <div className='mt-3'>
                                    <ProgressBar
                                      width={dashboardData?.renovation_cost?.percentage + '%'}
                                      backgroundColor='#00BD1E'
                                      borderRadius={true}
                                    />
                                  </div>
                                </>
                              ) : (
                                <ComingSoon />
                              )}
                            </div>
                          </div>
                          <div
                            className='col-4 col-12-sm mt-4-sm'
                            style={{
                              padding: '0 6px',
                              filter: 'blur(3.3px)',
                              display: 'none',
                            }}
                          >
                            <div
                              className='relative h-full bg-white pl-5 pr-3 py-6 rounded-xl-sm overflow-hidden'
                              style={{ borderRadius: '0px 20px 20px 0px' }}
                            >
                              {!dashboardData?.property_value ? (
                                <>
                                  <div>
                                    <h3 className='text-xl font-medium'>Einsparungen</h3>
                                    <div className='flex items-center justify-between mt-4'>
                                      <h4 className='font-regular leading3'>Sanierungskosten</h4>
                                      <div className='relative flex items-center justify-center flex-shrink-0 w-4 h-4 ml-2 ml-auto cursor-pointer'>
                                        <img src={infoIcon} alt='info-icon' />
                                      </div>
                                    </div>
                                    {/* <div className="mt-3">
                                  <ProgressBar
                                    width="35%"
                                    backgroundColor="#00381B"
                                    borderRadius={true}
                                  />
                                </div> */}
                                    <span className='inline-block text-xl font-medium mt-2'>
                                      {/* {
                                    dashboardData?.property_value
                                      ?.current_annual_savings
                                  } */}
                                      100.928 €
                                    </span>
                                  </div>
                                  <div className='mt-4'>
                                    <h4 className='font-regular leading3'>Maximale Förderung</h4>
                                    {/* <div className="mt-3">
                                  <ProgressBar
                                    width="100%"
                                    backgroundColor="#00BD1E"
                                    borderRadius={true}
                                  />
                                </div> */}
                                    <span className='inline-block text-xl font-medium mt-2'>
                                      {/* {dashboardData?.property_value?.savings} */}
                                      20.185 €
                                    </span>
                                  </div>
                                </>
                              ) : (
                                <ComingSoon />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='mt-6'>
                        <div className='p-4 bg-white rounded-xl'>
                          <div className='flex items-center'>
                            <figure
                              className='avatar-container avatar-container--contain bg-primary-brand'
                              style={{ width: 45, height: 45, borderRadius: 8 }}
                            >
                              <img src={plugLg} alt='plug-icon' />
                            </figure>
                            <div className='flex flex-col ml-3'>
                              <h4
                                className='text-primary-brand text-xl font-medium text-underline leading4 cursor-pointer'
                                onClick={() => {
                                  if (dashboardData?.book_appointment)
                                    setTurnCalendly(dashboardData?.book_appointment);
                                }}
                              >
                                Vereinbare einen Termin für deine Energieberatung
                              </h4>
                              <div className='flex items-center flex-wrap-sm mt-2'>
                                <div className='flex items-center'>
                                  <div className='flex items-center justify-center flex-shrink-0 w-6 h-6'>
                                    <svg width='11' height='8' viewBox='0 0 11 8' fill='none'>
                                      <path
                                        d='M3.87508 5.75873L1.61633 3.49998L0.847168 4.26373L3.87508 7.29164L10.3751 0.791643L9.61133 0.0278931L3.87508 5.75873Z'
                                        fill='#00BD1E'
                                      />
                                    </svg>
                                  </div>
                                  <span className='secondary-font font-medium'>
                                    Vor-Ort-Analyse bei dir zu Hause
                                  </span>
                                </div>
                                <div className='flex items-center ml-2 mt-2-sm ml-sm-0'>
                                  <div className='flex items-center justify-center flex-shrink-0 w-6 h-6'>
                                    <svg width='11' height='8' viewBox='0 0 11 8' fill='none'>
                                      <path
                                        d='M3.87508 5.75873L1.61633 3.49998L0.847168 4.26373L3.87508 7.29164L10.3751 0.791643L9.61133 0.0278931L3.87508 5.75873Z'
                                        fill='#00BD1E'
                                      />
                                    </svg>
                                  </div>
                                  <span className='secondary-font font-medium'>
                                    Individuelle Sanierungs-Roadmap
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='aside-right mt-6'>
                      <h2 className='text-xl font-semibold' style={{ color: '#161F3A' }}>
                        Empfohlene Sanierungen
                      </h2>

                      {renovations.map((item, index) => (
                        <div key={index} className='pt-4 pb-6 pl-4 pr-2 mt-4 bg-white rounded-xl'>
                          <div className='flex items-center justify-between'>
                            <div className='flex items-center'>
                              <div className='flex items-center justify-center flex-shrink-0 w-8 h-8 rounded-md bg-primary-brand'>
                                <img src={item.icon} alt='renovation-type' />
                              </div>
                              <h3 className='text-lg font-medium ml-2'>{item.title}</h3>
                            </div>
                            {item?.percentage && (
                              <div className='flex items-center'>
                                <span className='text-lg font-medium'>{item.percentage}%</span>
                                <div className='flex items-center justify-center flex-shrink-0 w-6 h-6 ml-1'>
                                  <img src={slandingArrowBottomleft} alt='arrow' />
                                </div>
                              </div>
                            )}
                          </div>
                          <ul className='mt-2 pr-7'>
                            <li className='flex items-center justify-between mb-2 mb-not-last-child'>
                              <span className='secondary-font font-regular'>
                                Renovierungskosten
                              </span>
                              <div className='text-lg font-medium'>
                                {item.renovationCost}
                                {' €'}
                                {/*<span*/}
                                {/*  className="text-xl font-medium"*/}
                                {/*  style={{ color: "#5C6982" }}*/}
                                {/*>*/}
                                {/*  €*/}
                                {/*</span>*/}
                              </div>
                            </li>
                            <li className='flex items-center justify-between mb-2 mb-not-last-child'>
                              <span className='secondary-font font-regular'>
                                Jährliche Ersparnisse
                              </span>
                              <div className='text-lg font-medium'>
                                {item.annualSavings}
                                {' €'}
                                {/*<span*/}
                                {/*  className="text-xl font-medium"*/}
                                {/*  style={{ color: "#5C6982" }}*/}
                                {/*>*/}
                                {/*  */}
                                {/*</span>*/}
                              </div>
                            </li>
                            <li className='flex items-center justify-between mb-2 mb-not-last-child'>
                              <span className='secondary-font font-regular'>Amortisationszeit</span>
                              <span className='text-lg font-medium'>
                                {item.paybackPeriod}
                                {' Jahre'}
                              </span>
                            </li>
                          </ul>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <PageFooter />
            </main>
          </section>
          <PopupModal
            url={turnCalendly}
            pageSettings={props.pageSettings}
            utm={props.utm}
            prefill={props.prefill}
            onModalClose={() => setTurnCalendly(false)}
            open={turnCalendly !== false}
            rootElement={document.getElementById('root')}
          />
          {myProfileVisible && <MyProfile closeModal={toggleMyProfile} />}
          {changePasswordVisible && <ChangePassword closeModal={toggleChangePassword} />}
        </>
      )}
    </>
  );
};

export default HomeOwnerDashboard;
