import 'react-toastify/dist/ReactToastify.css';
import './_toast.css';

import { ToastContainer, toast } from 'react-toastify';

// import { css } from 'glamor';
function toastErrors(errors) {
  const errorValues = Object?.values(errors);
  const flattenedErrors = errorValues?.flat();

  if (flattenedErrors?.length === 0) {
    return;
  }

  flattenedErrors?.forEach((message) =>
    toast?.error(message, {
      autoClose: 3000,
      className: 'toast-error-style',
    })
  );
}

function toastError(message) {
  toast?.error(message, { autoClose: 3000, className: 'toast-error-style' });
}

function toastWarning(message) {
  toast?.warning(message, { autoClose: 3000 });
}

function toastInfo(message) {
  toast?.info(message, { autoClose: 2000 });
}

function toastSuccess(message) {
  toast?.success(message, {
    autoClose: 2000,
    className: 'toast-success toast-success-style ',
  });
}
function toastCustomInfo(message) {
  toast?.info(message, {
    autoClose: 30000,
    className: 'toast-custom-style',
  });
}

const Tostify = () => {
  return (
    <ToastContainer
      position='top-right'
      autoClose={1500}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme='colored'
      style={{ zIndex: 9999999 }}
    />
  );
};

export { Tostify, toastErrors, toastSuccess, toastInfo, toastWarning, toastError, toastCustomInfo };
