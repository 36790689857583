import { useCallback, useEffect, useRef, useState } from 'react';

// Debouncing Custom Hook

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const timeoutRef = useRef(null);

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [value, delay]);

  return debouncedValue;
}
// debouncing Function Custom hook
export const useDebounceFuntion = (func, delay) => {
  const [debouncedFunction, setDebouncedFunction] = useState(() => () => {});

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedFunction(() => func);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [func, delay]);

  return debouncedFunction;
};

// Throttling custom hook

const useThrottle = (callback, delay) => {
  const timeoutRef = useRef(null);
  const lastArgsRef = useRef(null);

  const throttledFunction = useCallback(
    (...args) => {
      if (!timeoutRef.current) {
        timeoutRef.current = setTimeout(() => {
          timeoutRef.current = null;
          if (lastArgsRef.current) {
            callback(...lastArgsRef.current);
            lastArgsRef.current = null;
          } else {
            callback(...args);
          }
        }, delay);
      } else {
        lastArgsRef.current = args;
      }
    },
    [callback, delay]
  );
  return throttledFunction;
};

export default useThrottle;
