import '../../assets/styles/scss/pages/admin-dashboard.scss';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { downloadFile, objectToQueryString, updateLeadStageRequest } from './_request';

import AddEditLead from './AddEditLead';
import AssignConsultant from './AssignConsultant';
import { ENUM_API_STATUS } from '@/utils/_gConstant';
import { LeadCardView } from './LeadCardView';
import LeadFilter from './LeadFilter';
import LeadTable from './LeadTable';
import UploadFile from './UploadFile';
import arrowGroup from '../../assets/icons/arrow-group.svg';
import closeSmall from '../../assets/icons/close-sm.svg';
import exportIcon from '../../assets/icons/export.svg';
import filter from '../../assets/icons/filter.svg';
import importIcon from '../../assets/icons/import.svg';
import { useDebounce } from '@/utils/_gFunctions/handleDebouncing';
import { useSearchParams } from 'react-router-dom';

export function LeadListing(props) {
  const [leadCardData, updateLeadCardData] = useState([]);
  const [leadListData, updateLeadListData] = useState([]);
  const [totalLeadsInStages, setTotalLeadsInStages] = useState({});

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState('');

  const [showSort, setShowSort] = useState(false);
  const [sortValue, setSortValue] = useState('Newest first');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [modalData, setModalData] = useState({});
  const [uploadFile, setUploadFile] = useState(false);
  const [isAssignConsultantModalOpen, setIsAssignConsultantModalOpen] = useState(false);
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };
  useEffect(() => {
    document.title = props.title;
  }, [props.title]);
  const toggleFileUploadModal = () => {
    setUploadFile(!uploadFile);
  };

  const handleAssignConsultantModal = () => {
    setIsAssignConsultantModalOpen(!isAssignConsultantModalOpen);
    // handleClearAll();
  };

  const filterSelectedValue = useMemo(() => {
    const filterValue = searchParams.get('filter');
    if (filterValue) {
      return filterValue.split(',');
    }
    return [];
  }, [searchParams]);

  const setFilterSelectedValue = useCallback(
    (filterValuesData) => {
      const convertValuesIntoList = (values) => {
        return values.join(',');
      };
      if (typeof filterValuesData === 'function') {
        setSearchParams((oldParams) => {
          let filterValues = oldParams.get('filter');
          if (filterValues) {
            filterValues = filterValues.split(',');
          } else {
            filterValues = [];
          }
          const newFilters = [...new Set(filterValuesData(filterValues))];

          if (newFilters.length === 0) {
            oldParams.delete('filter');
            return oldParams;
          }
          oldParams.set('filter', convertValuesIntoList(newFilters));
          return oldParams;
        });
      } else {
        setSearchParams((oldParams) => {
          filterValuesData = [...new Set(filterValuesData)];
          if (filterValuesData.length === 0) {
            oldParams.delete('filter');
            return oldParams;
          }
          oldParams.set('filter', convertValuesIntoList(filterValuesData));
          return oldParams;
        });
      }
    },
    [setSearchParams]
  );

  const filterSelectedObject = useMemo(() => {
    const object = {};
    filterSelectedValue.forEach((filter) => {
      const [key, value] = filter.split('-');
      if (object.hasOwnProperty(key)) {
        object[key].push(value);
      } else {
        object[key] = [value];
      }
    });
    return object;
  }, [filterSelectedValue]);

  const setLeadCardData = useCallback(
    (leadDatas) => {
      const sort_data = (data) => {
        return data.sort((a, b) => {
          if (sortValue === 'Newest first') {
            return new Date(b.enq_date) - new Date(a.enq_date);
          } else {
            return new Date(a.enq_date) - new Date(b.enq_date);
          }
        });
      };
      if (typeof leadDatas === 'function') {
        updateLeadCardData((data) => {
          const oldData = [...data];
          return sort_data(leadDatas(oldData));
        });
      } else {
        updateLeadCardData(sort_data(leadDatas));
      }
    },
    [sortValue]
  );

  const setLeadListData = useCallback(
    (leadDatas) => {
      const sort_data = (data) => {
        return data.sort((a, b) => {
          if (sortValue === 'Newest first') {
            return new Date(b.enq_date) - new Date(a.enq_date);
          } else {
            return new Date(a.enq_date) - new Date(b.enq_date);
          }
        });
      };
      if (typeof leadDatas === 'function') {
        updateLeadListData((data) => {
          const oldData = [...data];
          return sort_data(leadDatas(oldData));
        });
      } else {
        updateLeadListData(sort_data(leadDatas));
      }
    },
    [sortValue]
  );

  const deleteLead = useCallback(
    (id) => {
      setTotalLeadsInStages((oldData) => {
        const newData = { ...oldData };
        const stage = leadCardData.find((lead) => lead.id === id)?.stages;
        if (stage) {
          newData[stage] = newData[stage] - 1;
        }
        return newData;
      });
      setLeadCardData((oldData) => oldData.filter((lead) => lead.id !== id));
      setLeadListData((oldData) => oldData.filter((lead) => lead.id !== id));
    },
    [leadCardData, setTotalLeadsInStages, setLeadCardData, setLeadListData]
  );

  const addLead = useCallback(
    (leadData) => {
      setTotalLeadsInStages((oldData) => {
        const newData = { ...oldData };
        newData[leadData.stages] = (newData[leadData.stages] || 0) + 1;
        return newData;
      });
      setLeadCardData((oldData) => [leadData, ...oldData]);
      setLeadListData((oldData) => [leadData, ...oldData]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [leadCardData, setTotalLeadsInStages, setLeadCardData, setLeadListData]
  );

  const updateLead = useCallback(
    (leadData) => {
      setLeadCardData((oldData) => {
        return oldData.map((lead) => {
          if (lead.id === leadData.id) {
            return leadData;
          }
          return lead;
        });
      });
      setLeadListData((oldData) => {
        return oldData.map((lead) => {
          if (lead.id === leadData.id) {
            return leadData;
          }
          return lead;
        });
      });
    },
    [setLeadCardData, setLeadListData]
  );

  const toggleViewMode = (e) => {
    setSearchParams((prev) => {
      prev.set('viewMode', e.target.value);
      return prev;
    });
  };

  const onCardDrop = (leadData, targetStageId) => {
    leadData = leadData || {};
    targetStageId = targetStageId || '';

    // eslint-disable-next-line no-unused-vars
    const { id: leadId, stages, ...lead } = leadData;
    handleUpdateStages(leadId, targetStageId).then((r) => {
      setTotalLeadsInStages((oldData) => {
        const newData = { ...oldData };
        const oldStage = leadCardData.find((lead) => lead.id === leadId)?.stages;
        newData[oldStage] = newData[oldStage] - 1;
        newData[targetStageId] = (newData[targetStageId] || 0) + 1;
        return newData;
      });
      setLeadCardData((oldLeadData) => {
        return oldLeadData.map((lead) => {
          if (lead.id === leadId) {
            lead.stages = targetStageId;
          }
          return lead;
        });
      });
      setLeadListData((oldLeadData) => {
        return oldLeadData.map((lead) => {
          if (lead.id === leadId) {
            lead.stages = targetStageId;
          }
          return lead;
        });
      });
    });
  };

  const handleUpdateStages = async (id, stageId) => {
    const res = await updateLeadStageRequest(id, stageId);
    if (res?.status !== ENUM_API_STATUS?.SUCCESS_GET_API) {
      console.log('Leads Not updated');
    }
  };

  const setSortingValue = useCallback(
    (value) => {
      setSortValue(value);
      setShowSort(false);
    },
    [setSortValue, setShowSort]
  );

  useEffect(() => {
    if (sortValue === null) return;
    setLeadCardData((data) => {
      const oldData = [...data];
      return oldData;
    });
    setLeadListData((data) => {
      const oldData = [...data];
      return oldData;
    });
  }, [sortValue, setLeadCardData, setLeadListData]);

  const closeAddModal = () => {
    setIsModalOpen(false);
    setModalData({});
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest('#sort-dropdown')) {
        setShowSort(false);
      }
    };
    window.addEventListener('mousedown', handleOutsideClick);
    return () => {
      window.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleExportButtonClick = useCallback(() => {
    const filterParams = objectToQueryString({
      ...filterSelectedObject,
      search: searchValue,
    });
    downloadFile(`/api/leads/export/?${filterParams}`).then((res) => {
      const url = window.URL.createObjectURL(res);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'leads-details.csv'); // Extract filename
      link.click();
      window.URL.revokeObjectURL(url);
    });
    // window.open(`${siteConfig.BASE_URL}/api/leads/export/?${filterParams}`, '_blank');
  }, [filterSelectedObject, searchValue]);

  const handleClearAll = () => {
    setFilterSelectedValue([]);
  };

  const removeSelectedFilterValue = (index) => {
    setFilterSelectedValue((oldValues) => [
      ...oldValues.slice(0, index),
      ...oldValues.slice(index + 1),
    ]);
  };
  return (
    <>
      <main
        className='admin-dashboard__main admin-dashboard__main--leads-card'
        style={{ paddingTop: 88 }}
      >
        <div className='flex items-center w-full px-9'>
          <h1 className='text-36 font-semibold '>Leads</h1>
          <div className='flex items-center ml-9'>
            <div className='relative'>
              <input
                type='text'
                className='input-field input-field--search input-field--h40 input-field--md lato'
                style={{ width: 282 }}
                placeholder='Search'
                value={searchValue}
                onChange={handleChange}
              />
            </div>
            <LeadFilter
              filterSelectedValue={{
                filterSelectedValue,
                setFilterSelectedValue,
              }}
            />
            <div className='relative ml-2' id='sort-dropdown'>
              <button
                className='btn btn--h40 px-6 py-2 font-semibold'
                onClick={() => setShowSort((show) => !show)}
              >
                <img src={arrowGroup} alt='arrow-group-icon' className='mr-3' />
                {sortValue}
              </button>
              <ul className={`dropdown-menu md ${showSort && 'show'}`}>
                <li
                  className='dropdown-menu__list'
                  onClick={() => {
                    setSortingValue('Newest first');
                  }}
                >
                  Newest first
                </li>
                <li
                  className='dropdown-menu__list'
                  onClick={() => {
                    setSortingValue('Oldest first');
                  }}
                >
                  Oldest first
                </li>
              </ul>
            </div>
          </div>
          <div className='flex items-center ml-auto'>
            <button
              className='btn btn--secondary-brand btn--h42 btn--md font-bold'
              onClick={() => toggleFileUploadModal()}
            >
              <img src={importIcon} alt='import-icon' className='mr-3' />
              Import
            </button>
            {(leadCardData.length !== 0 || leadListData.length !== 0) && (
              <div>
                <button
                  className='btn btn--secondary-brand btn--h42 btn--md ml-4 font-bold'
                  onClick={() => handleExportButtonClick()}
                >
                  <img src={exportIcon} alt='import-icon' className='mr-3' />
                  Export
                </button>
              </div>
            )}

            <div className='flex items-center ml-4'>
              <input
                type='radio'
                className='toggle-table-view__input'
                name='table-view'
                id='boxView'
                value={'box'}
                checked={searchParams.get('viewMode') !== 'list'}
                onChange={toggleViewMode}
              />
              <label htmlFor='boxView' className='toggle-table-view'>
                <svg width='20' height='20' viewBox='0 0 20 20' fill='none'>
                  <path
                    d='M8 1H1V8H8V1Z'
                    stroke='black'
                    stroke-width='1.5'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                  <path
                    d='M19 1H12V8H19V1Z'
                    stroke='black'
                    stroke-width='1.5'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                  <path
                    d='M19 12H12V19H19V12Z'
                    stroke='black'
                    stroke-width='1.5'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                  <path
                    d='M8 12H1V19H8V12Z'
                    stroke='black'
                    stroke-width='1.5'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              </label>
              <input
                type='radio'
                className='toggle-table-view__input'
                name='table-view'
                id='listView'
                checked={searchParams.get('viewMode') === 'list'}
                value={'list'}
                onChange={toggleViewMode}
              />
              <label htmlFor='listView' className='toggle-table-view ml-4'>
                <svg width='20' height='14' viewBox='0 0 20 14' fill='none'>
                  <path
                    d='M6 1H19'
                    stroke='black'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                  <path
                    d='M6 7H19'
                    stroke='black'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                  <path
                    d='M6 13H19'
                    stroke='black'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                  <path
                    d='M1 1H1.01'
                    stroke='black'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                  <path
                    d='M1 7H1.01'
                    stroke='black'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                  <path
                    d='M1 13H1.01'
                    stroke='black'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              </label>
            </div>
          </div>
        </div>
        {filterSelectedValue?.length === 0 ? null : (
          <div className='mt-4 px-9'>
            <div className='flex items-start'>
              <figure
                className='avatar-container avatar-container--contain'
                style={{ width: 28, height: 28, backgroundColor: '#E3E1D8' }}
              >
                <img src={filter} alt='funnel' />
              </figure>

              <ul className='pills-wrapper ml-4'>
                {filterSelectedValue?.map((item, index) => (
                  <li
                    className='pills-container font-semibold h28 mr-4'
                    style={{ backgroundColor: '#E3E1D9' }}
                  >
                    {item.split('-')[1]}
                    <div className='pills__close' onClick={() => removeSelectedFilterValue(index)}>
                      <img src={closeSmall} alt='close-btn' />
                    </div>
                  </li>
                ))}
              </ul>
              {filterSelectedValue?.length === 0 ? null : (
                <button
                  className='btn btn--text btn--h28 flex-shrink-0 secondary-font px-2 font-semibold'
                  onClick={handleClearAll}
                >
                  Clear All
                </button>
              )}
            </div>
          </div>
        )}
        <>
          {searchParams.get('viewMode') === 'list' ? (
            <LeadTable
              setModalData={setModalData}
              leadsData={leadListData}
              setLeadsData={setLeadListData}
              setIsModalOpen={setIsModalOpen}
              filterSelectedObject={filterSelectedObject}
              searchValue={debouncedSearchValue}
            />
          ) : (
            <LeadCardView
              onCardDrop={onCardDrop}
              filterSelectedObject={filterSelectedObject}
              searchValue={debouncedSearchValue}
              leadsData={leadCardData}
              setLeadsData={setLeadCardData}
              setIsModalOpen={setIsModalOpen}
              setModalData={setModalData}
              handleAssignConsultantModal={handleAssignConsultantModal}
              deleteLead={deleteLead}
              setFilterSelectedValue={setFilterSelectedValue}
              totalLeadsInStages={totalLeadsInStages}
              setTotalLeadsInStages={setTotalLeadsInStages}
            />
          )}
        </>
      </main>
      {isModalOpen && (
        <AddEditLead
          onClose={closeAddModal}
          modalData={modalData}
          setLeadCardData={setLeadCardData}
          leadCardData={leadCardData}
          addLead={addLead}
          updateLead={updateLead}
          setSortValue={setSortValue}
          handleClearAll={handleClearAll}
        />
      )}
      {isAssignConsultantModalOpen && (
        <AssignConsultant
          updateLead={updateLead}
          handleAssignConsultantModal={handleAssignConsultantModal}
          handleClearAll={handleClearAll}
        />
      )}
      {uploadFile && (
        <UploadFile
          toggleFileUploadModal={toggleFileUploadModal}
          leadCard={true}
          energyConsultant={false}
        />
      )}
    </>
  );
}
