import * as Yup from 'yup';

import { ENUM_API_STATUS, UserRolesOptions } from '@/utils/_gConstant';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useCallback, useState } from 'react';
import { createUserPostRequest, editUserPatchRequest } from './_request';

import Select from 'react-select';
import close from '../../assets/icons/close.svg';
import { toastSuccess } from '@/components/ui-elements/_Toastify';

// eslint-disable-next-line no-unused-vars
const validationSchema = Yup.object().shape({
  first_name: Yup.string().required('First name is required'),
  last_name: Yup.string().required('Last name is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  mobile: Yup.string().required('Mobile is required'),
});

const AddNewUser = (props) => {
  const { closeModal, refreshList, editingUser } = props;
  const [selectedKind, setSelectedKind] = useState(
    editingUser?.role !== null
      ? UserRolesOptions.find((role) => role.value === editingUser?.role)?.label
      : UserRolesOptions[0]
  );

  const onSubmit = useCallback(async (values) => {
    if (editingUser === null) {
      const res = await createUserPostRequest(values);
      if (res.status === ENUM_API_STATUS.SUCCESS_POST_API) {
        toastSuccess('New user created successfully.');
        refreshList();
        closeModal();
      }
    } else {
      const res = await editUserPatchRequest(editingUser?.id, values);
      if (res.status === ENUM_API_STATUS.SUCCESS_PATCH_API) {
        toastSuccess('User updated successfully');
        refreshList();
        closeModal();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const roleOptions = UserRolesOptions?.map((userRole) => ({
    label: userRole?.label,
    value: userRole.value,
  }));

  return (
    <Formik
      initialValues={{
        first_name: editingUser?.first_name || '',
        last_name: editingUser?.last_name || '',
        email: editingUser?.email || '',
        kind: editingUser?.kind || 3,
        mobile_number: editingUser?.mobile_number || '',
      }}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form className='modal modal--new-user open'>
          <div className='modal__container'>
            <div className='modal__header'>
              <h1 className='text-lg font-bold'>
                {editingUser === null ? 'Add new user' : 'Edit user'}
              </h1>
              <div className='modal__close' onClick={closeModal}>
                <img src={close} alt='close' />
              </div>
            </div>
            <div className='modal__body' style={{ paddingBottom: 100 }}>
              <div className='input-field-wrapper sm'>
                <label className='input-field-label primary-font text-base font-medium'>
                  First name
                </label>
                <Field
                  type='text'
                  name='first_name'
                  className='input-field input-field--regular input-field--h40 w-full lato text-sm font-semibold'
                  placeholder='Name of user'
                />
                <ErrorMessage name='first_name' className='error-message' component='p' />
              </div>
              <div className='input-field-wrapper sm'>
                <label className='input-field-label primary-font text-base font-medium'>
                  Last name
                </label>
                <Field
                  type='text'
                  name='last_name'
                  className='input-field input-field--regular input-field--h40 w-full lato text-sm font-semibold'
                  placeholder='Name of user'
                />
                <ErrorMessage name='last_name' className='error-message' component='p' />
              </div>
              <div className='input-field-wrapper sm'>
                <label className='input-field-label primary-font text-base font-medium'>
                  Email
                </label>
                <Field
                  type='text'
                  name='email'
                  className='input-field input-field--regular input-field--h40 w-full lato text-sm font-semibold'
                  placeholder='abcd@gmail.com'
                />
              </div>
              <div className='input-field-wrapper sm'>
                <label className='input-field-label primary-font text-base font-medium'>
                  Mobile No.
                </label>
                <Field
                  type='text'
                  name='mobile_number'
                  className='input-field input-field--regular input-field--h40 w-full lato text-sm font-semibold'
                  placeholder='+918923456798'
                />
              </div>
              <div className='input-field-wrapper sm'>
                <label className='input-field-label primary-font text-base font-medium'>Role</label>
                <Select
                  options={roleOptions}
                  value={roleOptions.find((option) => option.label === selectedKind)}
                  onChange={(selectedOption) => {
                    setSelectedKind(selectedOption.label);
                    formik.setFieldValue('role', selectedOption.value);
                  }}
                  isSearchable={false}
                />
              </div>
            </div>
            <div className='modal__footer justify-end'>
              <button
                className='btn btn--secondary btn--h40 px-6 py-2 text-base font-semibold'
                onClick={closeModal}
              >
                Cancel
              </button>
              <button
                className='btn btn--primary btn--h40 px-6 py-2 text-base font-semibold'
                type='submit'
              >
                Save
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddNewUser;
