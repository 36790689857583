import React from 'react';

const DashboardIcon = ({ fillColor = '', strokeColor = '', className = '' }) => {
  return (
    <svg
      width='22'
      height='12'
      viewBox='0 0 22 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className ?? ''}
    >
      <path
        d='M1.40002 10.8002L6.77602 5.63096L11.384 10.0617L20.6 1.2002M20.6 1.2002H13.688M20.6 1.2002V7.84635'
        stroke={strokeColor ? strokeColor : '#4B5563'}
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};

export default DashboardIcon;
