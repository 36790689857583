import { useDispatch, useSelector } from 'react-redux';

import { AuthInit } from './components/auth/core/Auth';
import { Outlet } from 'react-router-dom';
import { Suspense } from 'react';
import { toastSuccess } from './components/ui-elements/_Toastify';
import { useEffect } from 'react';

const App = () => {
  const dispatch = useDispatch();
  const dashboardRedux = useSelector((state) => state?.authReducer?.user?.dashboard);
  const userNameRedux = useSelector((state) => state?.authReducer?.user?.first_name);

  const getCookie = (name) => {
    const cookieValue = document.cookie
      .split('; ')
      .find((row) => row.startsWith(name))
      ?.split('=')[1];
    return cookieValue || '';
  };

  useEffect(() => {
    if (dashboardRedux || userNameRedux) {
      const checkForCookieChanges = () => {
        const dashboard = getCookie('dashboard');
        const userName = getCookie('userName');

        if (dashboard !== dashboardRedux || userName !== userNameRedux) {
          localStorage.clear();
          dispatch({ type: 'USER_LOGOUT' });
          // cookies.remove("leadId", {
          //   domain: process.env.REACT_APP_DOMAIN,
          //   path: "/",
          // });

          // cookies.remove("userName", {
          //   domain: process.env.REACT_APP_DOMAIN,
          //   path: "/",
          // });

          toastSuccess('Sie wurden abgemeldet!');
          window.location.replace(process.env.REACT_APP_LANDING_PAGE_URL);
          // navigate("/");
        }
      };
      checkForCookieChanges();
    }
  }, [dashboardRedux, userNameRedux, dispatch]);

  return (
    <Suspense>
      <AuthInit>
        <Outlet />
      </AuthInit>
    </Suspense>
  );
};
export { App };
