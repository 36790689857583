import React from 'react';
import deleteIcon from '@/icons/trash-grey.svg';
import { formatAmountInGerman } from '@/utils/utils';
import { useTranslation } from 'react-i18next';

const ProductOverview = ({ products, readonly, onDelete = () => {} }) => {
  const { t } = useTranslation();

  return (
    <div>
      <h2 className='text-28 font-medium leading2'>{t('productOverview.title')}</h2>

      {products?.map((item, idx) => {
        const { id, name, description, quantity, actual_price, final_price } = item;
        return (
          <div
            className='bg-white rounded-xl px-5 py-6 mt-4'
            key={idx - id}
            style={readonly ? { border: '1px solid #D1D5DB' } : {}}
          >
            <div className='flex items-center justify-between'>
              <h2 className='text-28 font-medium leading2 mr-4'>
                {`${name}${quantity > 1 ? ' x ' + quantity : ''}`}
              </h2>
              {!readonly && (
                <div className='w-6 h-6 flex items-center justify-center flex-shrink-0 cursor-pointer'>
                  <img src={deleteIcon} alt='delete-icon' onClick={() => onDelete(id)} />
                </div>
              )}
            </div>
            <p className='color-grey2 text-base font-medium leading2 mt-3'>{description}</p>
            <div className='flex items-center justify-end mt-3'>
              <div className='flex items-center justify-center secondary-font text-32 font-bold'>
                {actual_price !== final_price && (
                  <span className='relative secondary-font text-15 line-through' style={{ top: 2 }}>
                    {formatAmountInGerman(actual_price)}
                  </span>
                )}
                &nbsp; {formatAmountInGerman(final_price)}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ProductOverview;
