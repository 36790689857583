import './styles.scss';

import { DPuserRoles, ENUM_API_STATUS, ENUM_DP_USER_STATUS } from '@/utils/_gConstant';
import { deleteDPuserRequest, handleChangeStatus } from '../store/request';

import AddDPuser from './AddDPuser';
import DeleteDP from '@/pages/Admin/DeleteDP';
import editIcon from '@/icons/edit.svg';
import trashIcon from '../../../assets/icons/trash1.svg';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const DropdownMenu = ({ hasDeletePermission, hasChangeStatusPermission, dpUser, refreshList }) => {
  const { t } = useTranslation();
  const [showDelete, setShowDelete] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const _guserRole = useSelector((state) => state?.authReducer?.user?.role);

  const handleDelete = async () => {
    const res = await deleteDPuserRequest(dpUser?.id);
    if (res?.status === ENUM_API_STATUS?.SUCCESS_DELETE_API) {
      refreshList(1, true);
    } 
  };

  const handleStatus = async () => {
    const res = await handleChangeStatus(dpUser?.id);
    if (res?.status === ENUM_API_STATUS?.SUCCESS_PATCH_API) {
      refreshList(1, true);
    }
  };

  return (
    <>
      {_guserRole !== DPuserRoles.DP_USER && (
        <div className='flex justify-between items-center' style={{ gap: '20px' }}>
          {dpUser.status !== ENUM_DP_USER_STATUS.PENDING && !hasChangeStatusPermission && (
            <label class='switch tooltip'>
              <input
                type='checkbox'
                checked={dpUser.status === ENUM_DP_USER_STATUS.ACTIVE}
                onChange={() => handleStatus()}
              />
              <span class='slider round'></span>
              <span className='tooltiptext'>
                {dpUser.status === ENUM_DP_USER_STATUS.ACTIVE
                  ? `${t('disable')}`
                  : `${t('enable')}`}
              </span>
            </label>
          )}
          {(_guserRole === DPuserRoles?.DP_ADMIN || DPuserRoles?.DP_MASTER) && (
            <div className='tooltip'>
              <img
                src={editIcon}
                alt='exit-icon'
                className='action-icon tooltip'
                onClick={() => setShowEditModal(true)}
              />
              <span className='tooltiptext'>{t('edit')}</span>
            </div>
          )}
          {hasDeletePermission && (
            <div className='tooltip'>
              <img
                src={trashIcon}
                alt='exit-icon'
                className='action-icon tooltip'
                onClick={() => setShowDelete(true)}
              />
              <span className='tooltiptext'>{t('delete')}</span>
            </div>
          )}
        </div>
      )}
      {showDelete && (
        <DeleteDP
          deleteDPuser={dpUser}
          setShowDeleteModal={setShowDelete}
          handleDelete={handleDelete}
        />
      )}
      {showEditModal && (
        <AddDPuser
          closeModal={setShowEditModal}
          refreshList={refreshList}
          dpUser={dpUser}
          readOnly={true}
        />
      )}
    </>
  );
};

export default DropdownMenu;
