import './auth-module.scss';

import * as Yup from 'yup';

import { toastErrors, toastSuccess } from '../../ui-elements/_Toastify';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { ENUM_API_STATUS } from '../../../utils/_gConstant';
import LogoImage from '@/components/common/LogoImage';
import React from 'react';
import { emailResetPostAPI } from '../core/_request';
import loginBanner from '../../../assets/img/home-owner-banner.svg';
import { useFormik } from 'formik';

const ResetPasswordStep2 = () => {
  const host = window.location.host;
  const [slug] = host.split('.', 1);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get('token');

  const formik = useFormik({
    initialValues: {
      password: '',
      confirm_password: '',
      token,
    },
    validationSchema: Yup.object({
      password: Yup.string().required('Erforderlich'),
      confirm_password: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwörter müssen übereinstimmen')
        .required('Erforderlich'),
    }),
    onSubmit: async (values, { setErrors }) => {
      const res = await emailResetPostAPI(values);
      if (res?.status === ENUM_API_STATUS?.SUCCESS_GET_API) {
        toastSuccess(res?.data?.message);
        navigate('/');
      } else {
        toastErrors(res?.data);
      }
    },
  });

  return (
    <section className='auth-module'>
      <div className='auth-module__container'>
        <div className='auth-module__main'>
          <div className='logo-container'>
            <LogoImage slug={slug} />
          </div>
          <form onSubmit={formik.handleSubmit} className='auth-module__form'>
            <h1 className='text-48 font-medium'>Passwort zurücksetzen</h1>
            <p className='secondary-font text-secondary text-lg leading4 mt-2'>
              Neues Passwort einrichten
            </p>
            <div className='mt-8'>
              <div className='input-field-wrapper'>
                <input
                  type='password'
                  id='password'
                  name='password'
                  {...formik.getFieldProps('password')}
                  className={`input-field input-field--h52 input-field--md font-medium lato ${
                    formik.touched.password && formik.errors.password
                      ? 'border-red-500' // Apply your error styles here
                      : ''
                  }`}
                  placeholder='Neues Passwort eingeben'
                />
                {formik.touched.password && formik.errors.password && (
                  <div className='error-message'>{formik.errors.password}</div>
                )}
              </div>
              <div className='input-field-wrapper'>
                <input
                  type='password'
                  id='confirm_password'
                  name='confirm_password'
                  {...formik.getFieldProps('confirm_password')}
                  className={`input-field input-field--h52 input-field--md font-medium lato ${
                    formik.touched.confirm_password && formik.errors.confirm_password
                      ? 'border-red-500' // Apply your error styles here
                      : ''
                  }`}
                  placeholder='Bestätige neues Passwort'
                />
                {formik.touched.confirm_password && formik.errors.confirm_password && (
                  <div className='error-message'>{formik.errors.confirm_password}</div>
                )}
              </div>

              <button
                type='submit'
                className='btn btn--primary btn--h52 px-6 text-base w-full mt-12'
              >
                Bestätigen
              </button>
            </div>
          </form>
        </div>
        <div className='auth-module__banner'>
          <div className='w-full h-full rounded-2xl'>
            <img
              src={loginBanner}
              alt='banner'
              className='w-full h-full object-cover rounded-2xl'
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResetPasswordStep2;
