import React from 'react';
import checkIcon from '../../../assets/icons/tick-white.svg';

const Stepper = ({ steps, activeStep }) => {
  return (
    <div className='stepper-wrapper flex justify-between'>
      {steps.map((step, index) => (
        <div
          key={index}
          className={`stepper__list ${index === activeStep ? 'active' : index < activeStep ? 'completed' : ''}`}
        >
          <code className='stepper__count'>
            <span className='stepper__count__text'>{index + 1}</span>
            {index <= activeStep && (
              <img src={checkIcon} alt='check-icon' className='stepper__check-icon h-3' />
            )}
          </code>
          <span className='stepper__label'>{step}</span>
        </div>
      ))}
    </div>
  );
};

export default Stepper;
