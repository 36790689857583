import '../../assets/styles/scss/pages/admin-dashboard.scss';

import { ENUM_LEAD_CREATED_FROM, ORDER_STATUS } from '@/utils/_gConstant';
import { useCallback, useEffect, useState } from 'react';

import DeleteUser from './DeleteUser';
import dots from '../../assets/icons/dots.svg';
import email from '../../assets/icons/email-white.svg';
import { formatDate } from '../../utils/_gFunctions/_handleDateFormat';
import { getInitials } from '../../utils/_gFunctions/_handleGetInitials';
import { selectedLead } from './store/action';
import { useDispatch } from 'react-redux';
import { useDrag } from 'react-dnd';
import { useNavigate } from 'react-router-dom';

const LeadCard = (props) => {
  const {
    lead,
    setIsModalOpen,
    setModalData,
    handleAssignConsultantModal,
    deleteLead,
    setFilterSelectedValue,
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [deleteUser, setDeleteUser] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [users, setUsers] = useState([lead]);
  const [tagsExpanded, setTagsExpanded] = useState(false);

  const openAddModal = () => {
    setModalData({
      add: false,
      lead: lead,
    });
    setIsModalOpen(true);
  };
  const handleAssignConsultan = (lead) => {
    dispatch(selectedLead(lead));
    handleAssignConsultantModal();
  };

  const [{ isDragging }, drag] = useDrag({
    type: 'CARD',
    item: { LeadData: lead },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });
  const handleToggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };
  const toggleDeleteUser = useCallback((user = null) => {
    setDeleteUser(user);
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest(`#edit-delete${lead.id}`)) {
        setDropdownVisible(false);
      }
    };
    window.addEventListener('mousedown', handleOutsideClick);
    return () => {
      window.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [setDropdownVisible, lead.id]);

  return (
    <>
      <div ref={drag} className={`leads-list__card card-box p-4 ${isDragging ? 'dragging' : ''}`}>
        <div
          className='flex items-center justify-between pb-3'
          style={{ borderBottom: '1px solid #D1D5DB' }}
        >
          <div className='flex flex-wrap'>
            <h4
              className='secondary-font font-semibold truncate pr-4'
              style={{ textWrap: 'pretty' }}
            >
              {lead?.first_name}
            </h4>
            {lead?.created_from?.type !== ENUM_LEAD_CREATED_FROM?.DISTRIBUTOR ? (
              <div className='legend legend--ikosia secondary-font'>IKOSIA</div>
            ) : (
              <>
                <div className='legend legend--dp secondary-font'>
                  {lead?.created_from?.distributor?.name?.toUpperCase()}
                </div>
                {lead?.order?.status === ORDER_STATUS.CANCELLED && (
                  <div className='legend legend--red secondary-font ml-1'>
                    {lead?.order?.status}
                  </div>
                )}
              </>
            )}
          </div>

          <div
            className=' flex items-center justify-center flex-shrink-0 cursor-pointer'
            id={`edit-delete${lead.id}`}
          >
            <div
              className='relative flex items-center justify-center w-4 h-4 cursor-pointer '
              onClick={handleToggleDropdown}
            >
              <img src={dots} alt='dots' />
              {isDropdownVisible && (
                <div
                  className='dropdown-menu dropdown-menu--edit-delete dropdown-menu--pointer md py-1 show'
                  style={{ left: -16, top: 'calc(100% + 20px)' }}
                >
                  <div className='dropdown-menu__list text-sm' onClick={openAddModal}>
                    Edit
                  </div>
                  <div
                    className='dropdown-menu__list text-sm'
                    onClick={() => toggleDeleteUser(lead)}
                  >
                    Delete
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className='mt-4'
          onClick={() => navigate(`/admin/leads/details/${lead?.id}?${lead?.stages}`)}
          style={{ cursor: 'pointer' }}
        >
          <div className='flex items-center -mx-2'>
            <div className='col-4 px-2 flex items-center justify-between'>
              <span className='secondary-font text-secondary text-sm truncate'>Enq Date</span>
              <code className='secondary-font text-secondary text-sm ml-1'>:</code>
            </div>

            <div className='secondary-font col-8 px-2 text-secondary text-sm truncate'>
              {formatDate(lead?.enq_date)}
            </div>
          </div>
          <div className='flex items-center -mx-2 mt-3'>
            <div className='col-4 px-2 flex items-center justify-between'>
              <span className='secondary-font text-secondary text-sm truncate'>Mobile</span>
              <code className='secondary-font text-secondary text-sm ml-1'>:</code>
            </div>

            <div className='secondary-font col-8 px-2 text-secondary text-sm truncate'>
              {lead?.mobile_number}
            </div>
          </div>
          <div className='flex items-center -mx-2 mt-3'>
            <div className='col-4 px-2 flex items-center justify-between'>
              <span className='secondary-font text-secondary text-sm truncate'>Email ID</span>
              <code className='secondary-font text-secondary text-sm ml-1'>:</code>
            </div>

            <div className='secondary-font col-8 px-2 text-secondary text-sm truncate'>
              {lead?.email}
            </div>
          </div>
        </div>

        <div className='pills-wrapper mt-4'>
          {tagsExpanded
            ? lead?.tags?.map((tag, index) => {
                return (
                  <div
                    className='pills-container cursor-pointer'
                    key={index}
                    onClick={() => setFilterSelectedValue((filters) => [...filters, `tags-${tag}`])}
                  >
                    {tag.length > 30 ? `${tag.slice(0, 30)}...` : tag}
                  </div>
                );
              })
            : lead?.tags?.slice(0, 4).map((tag, index) => {
                return (
                  <div
                    className='pills-container cursor-pointer'
                    key={index}
                    onClick={() => setFilterSelectedValue((filters) => [...filters, `tags-${tag}`])}
                  >
                    {tag.length > 30 ? `${tag.slice(0, 30)}...` : tag}
                  </div>
                );
              })}

          {!tagsExpanded && lead?.tags?.length > 4 && (
            <div
              className='flex items-center secondary-font text-primary-brand text-sm font-semibold text-underline cursor-pointer'
              onClick={() => setTagsExpanded(!tagsExpanded)}
            >
              +{lead.tags.length - 4}
            </div>
          )}
        </div>

        <div className='flex items-center justify-between mt-3'>
          <div className='pr-5 truncate'>
            {lead?.consultant_assigned?.first_name ? (
              <div className='flex items-center'>
                <figure
                  className='avatar-container'
                  style={{
                    width: 24,
                    height: 24,
                    backgroundColor: '#FF767B',
                    border: '1px solid #F3F2EC',
                  }}
                >
                  <p className='text-white text-13 font-medium'>
                    {getInitials(lead?.consultant_assigned?.first_name)}
                  </p>
                </figure>
                <span
                  className='secondary-font text-sm font-medium text-underline ml-1 truncate cursor-pointer'
                  onClick={() => handleAssignConsultan(lead)}
                >
                  {lead?.consultant_assigned?.first_name
                    ? `${lead?.consultant_assigned?.first_name}`
                    : 'Assign Consultant'}
                </span>
              </div>
            ) : (
              <span
                className='secondary-font text-sm font-medium text-underline truncate cursor-pointer'
                onClick={() => handleAssignConsultan(lead)}
              >
                Assign Consultant
              </span>
            )}
          </div>
          <div className='flex items-center justify-between'>
            <a
              className='w-6 h-6 flex items-center justify-center flex-shrink-0 rounded-full bg-secondary-brand mr-2 mr-not-last-child cursor-pointer'
              href={`mailto:${lead?.email}`}
            >
              <img src={email} alt='email-icon' />
            </a>
          </div>
        </div>
      </div>

      <DeleteUser
        user={deleteUser}
        closeModal={() => toggleDeleteUser(null)}
        setUsers={setUsers}
        LeadCard={true}
        deleteFunction={() => deleteLead(lead?.id)}
      />
    </>
  );
};

export default LeadCard;
