import React from 'react';
import logo from '@/images/ikosia-logo.svg'
import logo2 from '@/icons/Group 776.svg'

const LogoImage = ({ slug }) => {
  const logoSrc = slug === 'app'? logo : logo2;
  const logoHeight = slug === 'app'? 32 : 40;

  return (
    <img src={logoSrc} alt='logo' style={{ height: logoHeight }} />
  );
};

export default LogoImage;