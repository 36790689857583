import * as Yup from 'yup';

import { ErrorMessage, Field, Form, Formik } from 'formik'; // Import Formik here

import { ENUM_API_STATUS } from '@/utils/_gConstant';
import close from '@/icons/close.svg';
import { createDistributionPartnerPostRequest } from './_request';
import { toastSuccess } from '@/components/ui-elements/_Toastify';

const validationSchema = Yup.object().shape({
  first_name: Yup.string()
    .matches(/^(?=.*[a-zA-Z])[\w\s]+$/, 'First name must contain at least one alphabetic character')
    .required('First name is required'),
  last_name: Yup.string()
    .matches(/^(?=.*[a-zA-Z])[\w\s]+$/, 'Last name must contain at least one alphabetic character')
    .required('Last name is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  company_name: Yup.string().required('Company Name is required'),
  slug: Yup.string()
    .matches(
      /^[a-z0-9]+(?:[-_][a-z0-9]+)*$/,
      'Slug must contain only lowercase letters, numbers, hyphens, and underscores, and cannot start or end with a hyphen or underscore'
    )
    .required('Slug is required'),
});

const AddDistributionPartner = (props) => {
  const { closeModal, refreshList } = props;

  const generateSlug = (companyName) => {
    if (!companyName) return '';
    const words = companyName.trim().split(' ');
    return words.length > 0 ? words[0] : '';
  };
  const handleCompanyNameChange = (event, formik) => {
    const { value } = event.target;

    formik.setFieldValue('company_name', value);
    const slug = generateSlug(value?.toLowerCase());
    formik.setFieldValue('slug', slug);
  };
  const handleSlugChange = (event, formik) => {
    const { value } = event.target;
    formik.setFieldValue('slug', value?.toLowerCase());
  };
  const onSubmit = async (values) => {
    const res = await createDistributionPartnerPostRequest(values);
    if (res?.status === ENUM_API_STATUS?.SUCCESS_POST_API) {
      refreshList();
      closeModal();
      toastSuccess('Distribution Partner Added successfully');
    }
  };

  return (
    <Formik
      initialValues={{
        first_name: '',
        last_name: '',
        email: '',
        company_name: '',
        slug: '',
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form className='modal modal--new-user open'>
          <div className='modal__container'>
            <div className='modal__header'>
              <h1 className='text-lg font-bold'>Add new distribution partner</h1>
              <div className='modal__close' onClick={closeModal}>
                <img src={close} alt='close' />
              </div>
            </div>
            <div className='modal__body'>
              <div className='input-field-wrapper sm'>
                <label className='input-field-label primary-font text-base font-medium'>
                  First name
                </label>
                <Field
                  type='text'
                  name='first_name'
                  className='input-field input-field--regular input-field--h40 w-full lato text-sm font-semibold'
                  placeholder=' First name of partner'
                />
                <ErrorMessage name='first_name' className='error-message' component='p' />
              </div>
              <div className='input-field-wrapper sm'>
                <label className='input-field-label primary-font text-base font-medium'>
                  Last name
                </label>
                <Field
                  type='text'
                  name='last_name'
                  className='input-field input-field--regular input-field--h40 w-full lato text-sm font-semibold'
                  placeholder='Last name of partner'
                />
                <ErrorMessage name='last_name' className='error-message' component='p' />
              </div>
              <div className='input-field-wrapper sm'>
                <label className='input-field-label primary-font text-base font-medium'>
                  Email
                </label>
                <Field
                  type='text'
                  name='email'
                  className='input-field input-field--regular input-field--h40 w-full lato text-sm font-semibold'
                  placeholder='abcd@gmail.com'
                />
                <ErrorMessage name='email' className='error-message' component='p' />
              </div>
              <div className='input-field-wrapper sm'>
                <label className='input-field-label primary-font text-base font-medium'>
                  Company Name
                </label>
                <Field
                  type='text'
                  name='company_name'
                  className='input-field input-field--regular input-field--h40 w-full lato text-sm font-semibold'
                  placeholder='Company Name'
                  onChange={(event) => handleCompanyNameChange(event, formik)}
                />
                <ErrorMessage name='company_name' className='error-message' component='p' />
              </div>
              <div className='input-field-wrapper sm'>
                <label className='input-field-label primary-font text-base font-medium'>Slug</label>
                <Field
                  type='text'
                  name='slug'
                  className='input-field input-field--regular input-field--h40 w-full lato text-sm font-semibold'
                  placeholder='Slug'
                  onChange={(event) => handleSlugChange(event, formik)}
                />
                <ErrorMessage name='slug' className='error-message' component='p' />
              </div>
            </div>
            <div className='modal__footer justify-end'>
              <button
                className='btn btn--secondary btn--h40 px-6 py-2 text-base font-semibold'
                onClick={closeModal}
              >
                Cancel
              </button>
              <button
                className='btn btn--primary btn--h40 px-6 py-2 text-base font-semibold'
                type='submit'
              >
                Save
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddDistributionPartner;
