import * as Yup from 'yup';

import { DPuserRolesOptions, ENUM_API_STATUS, Permissions } from '@/utils/_gConstant';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { patchDPuserRequest, postDPuserRequest } from '../store/request';

import CustomSelect from '@/components/common/CustomeSelect';
import React from 'react';
import close from '@/icons/close.svg';
import { useAuth } from '@/components/auth/core/Auth';
import { useTranslation } from 'react-i18next';

const AddDPuser = (props) => {
  const { closeModal, refreshList, dpUser, readOnly } = props;
  const hasPermission = useAuth().hasPermission;
  const roleOptions = DPuserRolesOptions?.map((dpUserRole) => ({
    label: dpUserRole?.label,
    value: dpUserRole?.value,
  }));

  const { t } = useTranslation();

  const generateSlug = (companyName) => {
    if (!companyName) return '';
    const words = companyName.trim().split(' ');
    return words.length > 0 ? words[0] : '';
  };
  const handleCompanyNameChange = (event, formik) => {
    const { value } = event.target;

    formik.setFieldValue('company_name', value);
    const slug = generateSlug(value?.toLowerCase());
    formik.setFieldValue('slug', slug);
  };
  const handleSlugChange = (event, formik) => {
    const { value } = event.target;
    formik.setFieldValue('slug', value?.toLowerCase());
  };
  const validationSchema = Yup.object({
    first_name: Yup.string().required(t('First name is required')),
    last_name: Yup.string().required(t('Last name is required')),
    email: Yup.string().email(t('Invalid email address')).required(t('Email is required')),
    ...(hasPermission(Permissions.ADD_CHILD_DP)
      ? {
          company_name: Yup.string().required(t('Partner Code is required')),
          slug: Yup.string()
            .matches(
              /^[a-z0-9]+(?:[-_][a-z0-9]+)*$/,
              t('Slug must contain only lowercase letters, numbers, hyphens, and underscores, and cannot start or end with a hyphen or underscore')
            )
            .required(t('Slug is required')),
        }
      : {
          role: Yup.string().required(t('Role is required')),
        }),
  });
  const initialValues = {
    first_name: dpUser?.first_name || '',
    last_name: dpUser?.last_name || '',
    email: dpUser?.email || '',
    ...(hasPermission(Permissions.ADD_CHILD_DP)
      ? {
          company_name: dpUser?.company_name || '',
          slug: dpUser?.slug || '',
        }
      : {
          role: dpUser?.role || 'dp_admin',
        }),
  };
  const onSubmit = async (values) => {
    if (readOnly === true) {
      const res = await patchDPuserRequest(dpUser?.id, values);
      if (res?.status === ENUM_API_STATUS?.SUCCESS_PATCH_API) {
        refreshList(1, true);
        closeModal(false);
      }
    } else {
      const res = await postDPuserRequest(values);
      if (res?.status === ENUM_API_STATUS?.SUCCESS_POST_API) {
        refreshList(1, true);
        closeModal(false);
      }
    }
  };
  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {(formik) => (
        <Form className='modal modal--new-user open'>
          <div className='modal__container'>
            <div className='modal__header'>
              <h1 className='text-lg font-bold'>{readOnly ? t('Edit user') : t('Add new user')}</h1>
              <div className='modal__close' onClick={() => closeModal(false)}>
                <img src={close} alt='close' />
              </div>
            </div>
            <div className='modal__body'>
              <div className='input-field-wrapper sm'>
                <label className='input-field-label primary-font text-base font-medium'>
                  {t('First name')}
                </label>
                <Field
                  type='text'
                  name='first_name'
                  className='input-field input-field--regular input-field--h40 w-full lato text-sm font-semibold'
                  placeholder={t('First name of partner')}
                />
                <ErrorMessage name='first_name' className='error-message' component='p' />
              </div>
              <div className='input-field-wrapper sm'>
                <label className='input-field-label primary-font text-base font-medium'>
                  {t('Last name')}
                </label>
                <Field
                  type='text'
                  name='last_name'
                  className='input-field input-field--regular input-field--h40 w-full lato text-sm font-semibold'
                  placeholder={t('Last name of partner')}
                />
                <ErrorMessage name='last_name' className='error-message' component='p' />
              </div>
              <div className='input-field-wrapper sm'>
                <label className='input-field-label primary-font text-base font-medium'>
                  {t('Email')}
                </label>
                <Field
                  type='text'
                  name='email'
                  className={`input-field input-field--regular input-field--h40 w-full lato text-sm font-semibold ${readOnly ? 'input-blur' : ''}`}
                  placeholder='abcd@gmail.com'
                  readOnly={readOnly}
                />
                <ErrorMessage name='email' className='error-message' component='p' />
              </div>
              {hasPermission(Permissions.ADD_CHILD_DP) ? (
                <>
                  <div className='input-field-wrapper sm'>
                    <label className='input-field-label primary-font text-base font-medium'>
                      {t('Partner Code')}
                    </label>
                    <Field
                      type='text'
                      name='company_name'
                      className={`input-field input-field--regular input-field--h40 w-full lato text-sm font-semibold  ${readOnly ? ' input-blur' : ''}`}
                      placeholder={t('Partner Code')}
                      onChange={(event) => handleCompanyNameChange(event, formik)}
                      readOnly={readOnly}
                    />
                    <ErrorMessage name='company_name' className='error-message' component='p' />
                  </div>
                  <div className='input-field-wrapper sm'>
                    <label className='input-field-label primary-font text-base font-medium'>
                      {t('Slug')}
                    </label>
                    <Field
                      type='text'
                      name='slug'
                      className={`input-field input-field--regular input-field--h40 w-full lato text-sm font-semibold ${readOnly ? 'input-blur' : ''}`}
                      placeholder={t('Slug')}
                      onChange={(event) => handleSlugChange(event, formik)}
                      readOnly={readOnly}
                    />
                    <ErrorMessage name='slug' className='error-message' component='p' />
                  </div>
                </>
              ) : (
                <div className='input-field-wrapper sm'>
                  <label className='input-field-label primary-font text-base font-medium'>
                    {t('Role')}
                  </label>
                  <CustomSelect
                    options={roleOptions}
                    value={roleOptions.find((option) => option.value === formik.values.role)}
                    onChange={(selectedOption) => {
                      formik.setFieldValue('role', selectedOption.value);
                    }}
                  />
                </div>
              )}
            </div>
            <div className='modal__footer justify-end'>
              <button
                className='btn btn--secondary btn--h40 px-6 py-2 text-base font-semibold'
                onClick={() => closeModal(false)}
              >
                {t('Cancel')}
              </button>
              <button
                className='btn btn--primary btn--h40 px-6 py-2 text-base font-semibold'
                type='submit'
              >
                {t('Save')}
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddDPuser;
