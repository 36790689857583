import React from 'react';
import { useNavigate } from 'react-router-dom';

const Card = ({ icon, title, count, textColor, valueColor, filter }) => {
  const navigate = useNavigate();
  return (
    <li
      className={`card-list px-2 my-2 ${filter ? ' cursor-pointer' : ''}`}
      onClick={() => {
        if (filter) {
          navigate(`/dp/user-lead-listing`, {
            state: {
              kpi: {
                value: filter,
                label: title,
              },
            },
          });
        }
      }}
    >
      <div className='h-full p-4 bg-white rounded-10'>
        <div className='flex items-center'>
          <img src={icon} alt='activity-icon' />
          <span className={`roboto-font text-sm font-light ml-3 ${textColor}`}>{title}</span>
        </div>
        <div className={`text-2xl lg-text-36 text-right mt-8 ${valueColor}`}>{count}</div>
      </div>
    </li>
  );
};

export default Card;
