import '../../assets/styles/scss/pages/admin-dashboard.scss';

import { exportConsultantInFileRequest, getEnergyConsultantGetRequest } from './_request';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import AddEnergyConsultant from './AddEnergyConsultant';
import DeleteUser from './DeleteUser';
import { ENUM_API_STATUS } from '../../utils/_gConstant';
import UploadFile from './UploadFile';
import deleteIcon from '../../assets/icons/delete-with-circle.svg';
import editIcon from '../../assets/icons/edit-with-circle.svg';
import exportIcon from '../../assets/icons/export.svg';
import { getInitials } from '../../utils/_gFunctions/_handleGetInitials';
import importIcon from '../../assets/icons/import.svg';
import plusIcon from '../../assets/icons/plus-with-circle.svg';
import { selecteditConsultant } from './store/action';
import sortArrow from '../../assets/icons/sort-double-arrow.svg';
import { useDebounce } from '../../utils/_gFunctions/handleDebouncing';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const EnergyConsultant = (props) => {
  const consultantCount = 10;
  const [EnergyConsultant, setEnergyConsultant] = useState(null);
  const [totalConsultants, setTotalConsultants] = useState(null);
  const [nextPageNumber, setNextPageNumber] = useState(null);
  const [prevPageNumber, setPrevPageNumber] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [viewEnergyConsultant, setViewEnergyConsultant] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [sortValue, setSortValue] = useState('');
  const [uploadFile, setUploadFile] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toggleFileUploadModal = () => {
    setUploadFile(!uploadFile);
  };
  useEffect(() => {
    document.title = props.title;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getEnergyConsultant = useCallback(
    async (page) => {
      const queryParams = {};
      if (page) {
        queryParams.page = page;
      } else {
        page = currentPage;
      }
      if (debouncedSearchValue !== '') {
        queryParams.search = debouncedSearchValue;
      }
      const res = await getEnergyConsultantGetRequest({
        ...queryParams,
        sort: sortValue,
      });
      if (res?.status === ENUM_API_STATUS?.SUCCESS_GET_API) {
        setEnergyConsultant(res?.data?.results);
        setNextPageNumber(res?.data?.next);
        setPrevPageNumber(res?.data?.previous);
        setTotalConsultants(res?.data?.count);
        setCurrentPage(page);
      }
    },
    [debouncedSearchValue, currentPage, sortValue]
  );
  const handleSortClick = (field) => {
    const sortMap = {
      postal_code: 'postal_code',
      total: 'no_of_leads',
      location: 'location',
    };

    const currentSort = sortValue === `${sortMap[field]}_asc` ? '_asc' : '_desc';

    const newSortValue =
      currentSort === '_asc'
        ? ''
        : `${sortMap[field]}${currentSort === '_desc' ? '_asc' : '_desc'}`;

    setSortValue(newSortValue);
  };

  useEffect(() => {
    getEnergyConsultant(currentPage).then(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchValue, sortValue, currentPage]);

  const toggleEnergyConsultant = () => {
    setViewEnergyConsultant((viewEnergyConsultant) => !viewEnergyConsultant);
  };

  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const fromAndToText = useMemo(() => {
    const start_data = (currentPage - 1) * consultantCount + 1;
    const end_data = Math.min(currentPage * consultantCount, totalConsultants);

    return `${start_data}-${end_data}`;
  }, [currentPage, totalConsultants]);

  const handleExportButtonClick = useCallback(async () => {
    try {
      const res = await exportConsultantInFileRequest({ search: debouncedSearchValue });

      const blob = new Blob([res.data], {
        type: res.headers.get('content-type'),
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', 'consultant-details.csv');
      link.click();
    } catch (error) {}
  }, [debouncedSearchValue]);

  const [isSticky, setIsSticky] = useState(false);
  const tableBody = useRef();
  useEffect(() => {
    const handleScroll = () => {
      const offset = tableBody?.current?.scrollTop;
      const threshold = 30;
      setIsSticky(offset > threshold);
    };

    tableBody?.current?.addEventListener('scroll', handleScroll);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      tableBody?.current?.removeEventListener('scroll', handleScroll);
      setIsSticky(false);
    };
  }, [tableBody]);
  const handleEditClick = (consultantData) => {
    dispatch(selecteditConsultant(consultantData));
    toggleEnergyConsultant();
  };
  const handleDeleteClick = (consultantData) => {
    dispatch(selecteditConsultant(consultantData));
    toggleDeleteModal();
  };
  return (
    <>
      <section className='admin-dashboard-wrapper'>
        <main
          className='admin-dashboard__main admin-dashboard__main--table-details pb-4'
          style={{ paddingTop: 88 }}
        >
          {(EnergyConsultant?.length > 0 || searchValue !== '') && (
            <>
              <div className='flex items-center w-full px-10'>
                <h1 className='text-32 font-semibold'>Energy Consultants</h1>
                <div className='flex flex-1 ml-14'>
                  <div className='flex flex-1 items-center'>
                    <input
                      type='text'
                      className='input-field input-field--search input-field--h44 input-field--md lato'
                      placeholder='Search'
                      value={searchValue}
                      onChange={handleChange}
                      style={{ width: '70%' }}
                    />
                  </div>
                  <div className='flex items-center ml-auto'>
                    <button
                      className='btn btn--secondary-brand btn--h52 btn--md font-bold'
                      onClick={() => toggleFileUploadModal()}
                    >
                      <img src={importIcon} alt='import-icon' className='mr-3' />
                      Import
                    </button>
                    {EnergyConsultant.length !== 0 ? (
                      <button
                        className='btn btn--secondary-brand btn--h52 btn--md ml-4 font-bold'
                        onClick={() => handleExportButtonClick()}
                      >
                        <img src={exportIcon} alt='import-icon' className='mr-3' />
                        Export
                      </button>
                    ) : null}

                    <button
                      className='btn btn--primary btn--h52 px-8 ml-6'
                      style={{ fontSize: '16px' }}
                      onClick={toggleEnergyConsultant}
                    >
                      <img src={plusIcon} alt='plus-icon' className='mr-3' />
                      Add Energy Consultant
                    </button>
                  </div>
                </div>
              </div>
              <div className='h-full px-10 mt-6 overflow-y-auto' ref={tableBody}>
                <table
                  className='table-styles table-styles--energy-consultant'
                  style={{ maxHeight: '100%' }}
                >
                  <thead className={isSticky ? 'box-shadow' : ''}>
                    <tr>
                      <td>EC ID</td>
                      <td>
                        <div className='flex items-center justify-between'>
                          <span
                            className='secondary-font text-sm font-bold'
                            style={{ color: 'color: rgba(44, 44, 44, 0.7)' }}
                          >
                            Postal Code
                          </span>
                          <div
                            className='flex items-center justify-center cursor-pointer'
                            style={{ width: 18, height: 18 }}
                            onClick={() => handleSortClick('postal_code')}
                          >
                            <img src={sortArrow} alt='sort-arrow' className='ml-2' />
                          </div>
                        </div>
                      </td>
                      <td style={{ maxWidth: 250 }}>Name</td>
                      <td>
                        <div className='flex items-center justify-between'>
                          <span
                            className='secondary-font text-sm font-bold'
                            style={{ color: 'color: rgba(44, 44, 44, 0.7)' }}
                          >
                            Location
                          </span>
                          <div
                            className='flex items-center justify-center cursor-pointer'
                            style={{ width: 18, height: 18 }}
                            onClick={() => handleSortClick('location')}
                          >
                            <img src={sortArrow} alt='sort-arrow' className='ml-3' />
                          </div>
                        </div>
                      </td>
                      <td className='text-center'>New</td>
                      <td className='text-center'>Call Scheduled</td>
                      <td className='text-center'>Contacted</td>
                      <td className='text-center'>Contract Signed</td>
                      <td className='text-center'>Consultant Assigned</td>
                      <td className='text-center'>Roadmap Ready</td>
                      <td>
                        <div className='flex items-center justify-between'>
                          <span
                            className='secondary-font text-sm font-bold'
                            style={{ color: 'color: rgba(44, 44, 44, 0.7)' }}
                          >
                            Total
                          </span>
                          <div
                            className='flex items-center justify-center cursor-pointer'
                            style={{ width: 18, height: 18 }}
                            onClick={() => handleSortClick('total')}
                          >
                            <img src={sortArrow} alt='sort-arrow' className='ml-3' />
                          </div>
                        </div>
                      </td>
                      <td className='text-center'>Action</td>
                    </tr>
                  </thead>
                  <tbody>
                    {EnergyConsultant.map((options, index) => (
                      <tr key={index}>
                        <td>{options?.ec_id}</td>
                        <td>{options?.postal_code}</td>
                        <td style={{ maxWidth: 250 }}>
                          <div className='flex'>
                            <figure
                              className='avatar-container'
                              style={{
                                width: 32,
                                height: 32,
                                backgroundColor: '#FF767B',
                                border: '1px solid #F3F2EC',
                              }}
                            >
                              <p
                                className='text-white text-13 font-medium'
                                style={{ marginTop: 2 }}
                              >
                                {getInitials(options?.first_name)}
                              </p>
                            </figure>
                            <div className='flex flex-col ml-3'>
                              <span className='font-medium'>
                                {options?.first_name} {options?.last_name}
                              </span>
                              <span className='text-xs font-medium mt-2'>
                                {
                                  options?.mobile_number
                                    ? `(${options.mobile_number.substring(
                                        0,
                                        3
                                      )})${options.mobile_number.substring(3)}`
                                    : 'N/A' // or any other default value you want to display
                                }
                              </span>
                              <span
                                className={`secondary-font text-xs mt-1 ${
                                  options?.email?.length > 25 ? 'cursor-pointer' : ''
                                }`}
                                title={options?.email?.length > 25 ? options?.email : undefined}
                              >
                                {options?.email?.length > 25
                                  ? `${options?.email?.slice(0, 25)}...`
                                  : options?.email}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>{options?.location}</td>
                        <td>
                          <div className='secondary-font secondary-text text-13 font-bold text-center'>
                            {options?.no_of_leads?.new_leads}
                          </div>
                        </td>
                        <td>
                          <div className='secondary-font secondary-text text-13 font-bold text-center'>
                            {options?.no_of_leads?.call_scheduled}
                          </div>
                        </td>
                        <td>
                          <div className='secondary-font secondary-text text-13 font-bold text-center'>
                            {options?.no_of_leads?.contacted}
                          </div>
                        </td>
                        <td>
                          <div className='secondary-font secondary-text text-13 font-bold text-center'>
                            {options?.no_of_leads?.contract_signed}
                          </div>
                        </td>
                        <td>
                          <div className='secondary-font secondary-text text-13 font-bold text-center'>
                            {options?.no_of_leads?.consultant_assigned}
                          </div>
                        </td>
                        <td>
                          <div className='secondary-font secondary-text text-13 font-bold text-center'>
                            {options?.no_of_leads?.roadmap_read}
                          </div>
                        </td>
                        <td>
                          <div
                            className='secondary-font text-primary-brand text-13 font-bold  text-center cursor-pointer'
                            onClick={() =>
                              navigate(
                                `/admin/leads/?filter=consultant_assigned-${options?.first_name}`
                              )
                            }
                          >
                            {options?.no_of_leads?.total_leads}
                          </div>
                        </td>
                        <td>
                          <div className='flex items-center'>
                            <img
                              src={editIcon}
                              alt='edit'
                              className='cursor-pointer'
                              onClick={() => handleEditClick(options)}
                            />
                            <img
                              src={deleteIcon}
                              alt='delete'
                              className='ml-3 cursor-pointer'
                              onClick={() => handleDeleteClick(options)}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className='flex items-center justify-end px-10 mt-3'>
                <label className='secondary-font color-grey2 text-xs font-medium'>
                  {fromAndToText} of {totalConsultants}
                </label>
                <div className='flex items-center ml-6'>
                  <button
                    className={
                      'btn-pagination btn-pagination--previous ' + (prevPageNumber && 'active')
                    }
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={!prevPageNumber}
                  >
                    <svg width='8' height='12' viewBox='0 0 8 12' fill='none'>
                      <path
                        d='M7.12407 10.9757C7.35838 11.2101 7.35838 11.5899 7.12407 11.8243C6.88975 12.0586 6.50985 12.0586 6.27554 11.8243L0.87554 6.42426C0.641225 6.18995 0.641225 5.81005 0.87554 5.57573L6.27554 0.175735C6.50986 -0.0585795 6.88976 -0.0585795 7.12407 0.175735C7.35838 0.410049 7.35838 0.789949 7.12407 1.02426L2.14833 6L7.12407 10.9757Z'
                        fill='#D1D5DB'
                      />
                    </svg>
                  </button>
                  <button
                    className={
                      'btn-pagination btn-pagination--next ' + (nextPageNumber && 'active')
                    }
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={!nextPageNumber}
                  >
                    <svg width='8' height='12' viewBox='0 0 8 12' fill='none'>
                      <path
                        d='M7.12407 10.9757C7.35838 11.2101 7.35838 11.5899 7.12407 11.8243C6.88975 12.0586 6.50985 12.0586 6.27554 11.8243L0.87554 6.42426C0.641225 6.18995 0.641225 5.81005 0.87554 5.57573L6.27554 0.175735C6.50986 -0.0585795 6.88976 -0.0585795 7.12407 0.175735C7.35838 0.410049 7.35838 0.789949 7.12407 1.02426L2.14833 6L7.12407 10.9757Z'
                        fill='#D1D5DB'
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </>
          )}
          {EnergyConsultant?.length === 0 && searchValue === '' && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                height: '100vh',
              }}
            >
              <div style={{ marginBottom: '5vh', fontSize: '30px', fontWeight: 'bold' }}>
                No Data Found
              </div>

              <button
                className='btn btn--primary btn--h52 px-8 ml-6'
                style={{ fontSize: '16px' }}
                onClick={toggleEnergyConsultant}
              >
                <img src={plusIcon} alt='plus-icon' className='mr-3' />
                Add Energy Consultant
              </button>
            </div>
          )}
        </main>
      </section>
      {viewEnergyConsultant && (
        <AddEnergyConsultant
          closeModal={toggleEnergyConsultant}
          refreshList={getEnergyConsultant}
        />
      )}
      {uploadFile && (
        <UploadFile
          toggleFileUploadModal={toggleFileUploadModal}
          leadCard={false}
          energyConsultant={true}
        />
      )}
      {deleteModal && (
        <DeleteUser user={true} closeModal={toggleDeleteModal} refreshList={getEnergyConsultant} />
      )}
    </>
  );
};
export default EnergyConsultant;
