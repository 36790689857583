export const preventExponentialInputInNumber = (event) => {
  ['e', 'E', '+', '-', '.'].includes(event.key) && event.preventDefault();
  if (event.keyCode === 40 || event.keyCode === 38) {
    event.preventDefault();
  }
};

export const formatAmountInGerman = (amount) => {
  return new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  }).format(amount);
};
