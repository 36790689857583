const initialState = {
  userAnswers: {},
  leadId: '',
};

const userAnswersReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_USER_ANSWERS':
      const { id, value } = action.payload;
      // Return a new object with updated userAnswers
      return {
        ...state,
        userAnswers: {
          ...state.userAnswers,
          [id]: value,
        },
      };
    case 'RESET_USER_ANSWERS':
      // Reset the entire userAnswers object to an empty object
      return {
        ...state,
        userAnswers: {},
      };
    case 'SET_LEAD_ID':
      //set the leadId in the state
      return {
        ...state,
        leadId: action.payload,
      };
    default:
      return state;
  }
};

export default userAnswersReducer;
