import './page-footer.scss';

import React from 'react';
import iKosiyaLogoWhite from '../../assets/img/ikosia-logo-white.svg';

const PageFooter = () => {
  return (
    <footer className='page-footer'>
      <div className='page-footer__container max-container'>
        <div className='flex flex-wrap-md'>
          <div className='col-12-md'>
            <div className='page-footer__logo'>
              <img src={iKosiyaLogoWhite} alt='logo' />
            </div>

            <p className='page-footer__address'>
              IKOSIA Energy Solutions (Germany) GmbH
              <br></br> Wadzeckstraße 10<br></br>
              10178 Berlin<br></br>Deutschland
            </p>
          </div>
          <ul className='page-footer__list-wrapper'>
            <li className='page-footer__list'>
              <h2 className='page-footer__list__title'>Services</h2>
              <ol className='flex flex-col'>
                <li className='page-footer__list__item'>
                  <a
                    href={`${process.env.REACT_APP_LANDING_PAGE_URL}energieberatung-unternehmen`}
                    target='_blank'
                    className=''
                  >
                    Geschäftskunden
                  </a>
                </li>
                <li className='page-footer__list__item'>
                  <a
                    href={`${process.env.REACT_APP_LANDING_PAGE_URL}dein-sanierungsfahrplan-isfp-ikosia`}
                    target='_blank'
                    className=''
                  >
                    Individueller Sanierungsfahrplan (iSFP)
                  </a>
                </li>
                <li className='page-footer__list__item'>
                  <a
                    href={`${process.env.REACT_APP_LANDING_PAGE_URL}energieberater-fur-isfp-und-forderung`}
                    target='_blank'
                    className='page-footer__list__item'
                  >
                    Energieberater
                  </a>
                </li>
              </ol>
            </li>
            <li className='page-footer__list'>
              <h2 className='page-footer__list__title'>Förderung</h2>
              <ol className='flex flex-col'>
                <li className='page-footer__list__item'>
                  <a
                    href={`${process.env.REACT_APP_LANDING_PAGE_URL}bafa-forderung-alle-fordermoglichkeiten-ikosia`}
                    target='_blank'
                    className=''
                  >
                    BAFA Förderung
                  </a>
                </li>
                <li className='page-footer__list__item'>
                  <a
                    href={`${process.env.REACT_APP_LANDING_PAGE_URL}kfw-forderung-forderung-fur-wohngebaude-ikosia`}
                    target='_blank'
                    className='page-footer__list__item'
                  >
                    KfW Förderung
                  </a>
                </li>
              </ol>
            </li>
            <li className='page-footer__list'>
              <h2 className='page-footer__list__title'>IKOSIA</h2>
              <ol className='flex flex-col'>
                <li className='page-footer__list__item'>
                  <a
                    href={`${process.env.REACT_APP_LANDING_PAGE_URL}uber-uns`}
                    target='_blank'
                    className=''
                  >
                    Über uns
                  </a>
                </li>
                <li className='page-footer__list__item'>
                  <a
                    href={`${process.env.REACT_APP_LANDING_PAGE_URL}wissen`}
                    target='_blank'
                    className='page-footer__list__item'
                  >
                    Wissen
                  </a>
                </li>
                <li className='page-footer__list__item'>
                  <a
                    href={`${process.env.REACT_APP_LANDING_PAGE_URL}impressum`}
                    target='_blank'
                    className='page-footer__list__item'
                  >
                    Impressum
                  </a>
                </li>
              </ol>
            </li>
            <li className='page-footer__list'>
              <h2 className='page-footer__list__title'>Legal</h2>
              <ol className='flex flex-col'>
                <li className='page-footer__list__item'>
                  <a
                    href={`${process.env.REACT_APP_LANDING_PAGE_URL}datenschutzrichtlinie-ikosia`}
                    target='_blank'
                    className=''
                  >
                    Datenschutzrichtlinie
                  </a>
                </li>
                <li className='page-footer__list__item'>
                  <a
                    href={`${process.env.REACT_APP_LANDING_PAGE_URL}nutzungsbedingungen`}
                    target='_blank'
                    className='page-footer__list__item'
                  >
                    Nutzungsbedingungen
                  </a>
                </li>
                <li className='page-footer__list__item'>
                  <a
                    href={`${process.env.REACT_APP_LANDING_PAGE_URL}empfehlungsprogramm`}
                    target='_blank'
                    className='page-footer__list__item'
                  >
                    Empfehlungsprogramm
                  </a>
                </li>
                <li className='page-footer__list__item'>
                  <a
                    href={`${process.env.REACT_APP_LANDING_PAGE_URL}Cookie-Richtlinie`}
                    target='_blank'
                    className='page-footer__list__item'
                  >
                    Cookie-Richtlinie
                  </a>
                </li>
              </ol>
            </li>
          </ul>
        </div>
        <div className='copyright-content'>
          <p className='flex items-center justify-center text-white text-sm'>&copy; 2024 IKOSIA</p>
        </div>
      </div>
    </footer>
  );
};

export default PageFooter;
