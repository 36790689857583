import Backend from 'i18next-http-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';
import deTranslation from './de.json';
import enTranslation from './en.json';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
//   .use(LanguageDetector) 
  .use(Backend)
  .use(initReactI18next)
  .init({
    debug: true,
    lng: 'de',
    fallbackLng: 'de',
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: enTranslation,
      },
      de: {
        translation: deTranslation,
      },
    },
  });

export default i18n;
