const initialState = {
  selectedConsultant: {},
};
const initialStateConsultantedit = {
  selecteditConsultant: null,
};
const leadInitialState = {
  selectedLead: null,
  editListViewLead: null,
};
const initialStateTag = {
  selectedTagName: null,
};

export const consultant = (state = initialState, action) => {
  switch (action.type) {
    case 'SELECT_CONSULTANT':
      return {
        ...state,
        selectedConsultant: action.payload,
      };
    default:
      return state;
  }
};

export const lead = (state = leadInitialState, action) => {
  switch (action.type) {
    case 'SELECT_LEAD':
      return {
        ...state,
        selectedLead: action.payload,
      };
    case 'SELECT_LIST_VIEW_LEAD':
      return {
        ...state,
        editListViewLead: action.payload,
      };
    default:
      return state;
  }
};

export const energyConsultantReducer = (state = initialStateConsultantedit, action) => {
  switch (action.type) {
    case 'SELECT_EDIT_CONSULTANT':
      return {
        ...state,
        selecteditConsultant: action.payload,
      };
    default:
      return state;
  }
};

export default energyConsultantReducer;

export const TagName = (state = initialStateTag, action) => {
  switch (action.type) {
    case 'SELECT_TAG_NAME':
      return {
        ...state,
        selectedTagName: action.payload,
      };
    default:
      return state;
  }
};
