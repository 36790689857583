import React, { useCallback, useEffect, useMemo, useState } from 'react';

import AddEditTag from './AddEditTag';
import DeleteTag from './DeleteTag';
import { ENUM_API_STATUS } from '../../utils/_gConstant';
import deleteIcon from '../../assets/icons/delete-with-circle.svg';
import editIcon from '../../assets/icons/edit-with-circle.svg';
import plusIcon from '../../assets/icons/plus-with-circle.svg';
import { selectedTagName } from './store/action';
import { tagsGetRequest } from './_request';
import { useDebounce } from '../../utils/_gFunctions/handleDebouncing';
import { useDispatch } from 'react-redux';

const TagManagement = (props) => {
  const tagCount = 10;
  const dispatch = useDispatch();
  const [tagList, setTagList] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [totalTags, setTotalTags] = useState(null);
  const [nextPageNumber, setNextPageNumber] = useState(null);
  const [prevPageNumber, setPrevPageNumber] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [AddEditModal, setAddEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };
  useEffect(() => {
    document.title = props.title;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getTagList = useCallback(
    async (page) => {
      const queryParams = {};
      if (page) {
        queryParams.page = page;
      } else {
        page = currentPage;
      }
      if (debouncedSearchValue !== '') {
        queryParams.search = debouncedSearchValue;
      }
      const res = await tagsGetRequest(queryParams);
      if (res?.status === ENUM_API_STATUS?.SUCCESS_GET_API) {
        setTagList(res?.data?.results);
        setNextPageNumber(res?.data?.next);
        setPrevPageNumber(res?.data?.previous);
        setCurrentPage(page);
        setTotalTags(res?.data?.count);
      }
    },
    [debouncedSearchValue, currentPage]
  );
  useEffect(() => {
    getTagList(currentPage).then(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchValue, currentPage]);
  const toggleAddEditModal = () => {
    setAddEditModal(!AddEditModal);
  };
  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };
  const handleEditButton = (tag) => {
    dispatch(selectedTagName(tag));
    toggleAddEditModal();
  };
  const handleDeleteClick = (tag) => {
    dispatch(selectedTagName(tag));
    toggleDeleteModal();
  };
  const fromAndToText = useMemo(() => {
    const start_data = (currentPage - 1) * tagCount + 1;
    const end_data = Math.min(currentPage * tagCount, totalTags);

    return `${start_data}-${end_data}`;
  }, [currentPage, totalTags]);
  return (
    <>
      <section className='admin-dashboard-wrapper'>
        <main
          className='admin-dashboard__main admin-dashboard__main--table-details pb-4'
          style={{ paddingTop: 88 }}
        >
          {(tagList?.length > 0 || searchValue !== '') && (
            <>
              <div className='flex items-center w-full px-10'>
                <h1 className='text-32 font-semibold'>Tag Management</h1>
                <div className='flex flex-1 ml-14'>
                  <div className='flex flex-1 items-center'>
                    <input
                      type='text'
                      className='input-field input-field--search input-field--h44 input-field--md lato'
                      placeholder='Search'
                      value={searchValue}
                      onChange={handleChange}
                      style={{ width: '60%' }}
                    />
                  </div>
                  <button
                    className='btn btn--primary btn--h48 text-base px-8 ml-6'
                    onClick={toggleAddEditModal}
                  >
                    <img src={plusIcon} alt='plus-icon' className='mr-3' />
                    New Tag
                  </button>
                </div>
              </div>
              <div className='h-full px-10 mt-6 overflow-y-auto'>
                <table className='table-styles table--user' style={{ maxHeight: '100%' }}>
                  <thead>
                    <tr>
                      <td>S.no</td>
                      <td>Tags</td>
                      <td>Action</td>
                    </tr>
                  </thead>
                  <tbody>
                    {tagList?.map((item, index) => (
                      <>
                        <tr>
                          <td>{(currentPage - 1) * 10 + index + 1}</td>
                          <td>
                            <div
                              style={{
                                color: '#00381B',
                                background: '#CCCED033',
                                borderRadius: '15px',
                                padding: '5px 10px',
                                width: 'min-content',
                              }}
                            >
                              {item?.name}
                            </div>
                          </td>
                          <td>
                            <div className='flex items-center'>
                              <img
                                src={editIcon}
                                alt='edit'
                                className='cursor-pointer'
                                onClick={() => handleEditButton(item)}
                              />
                              <img
                                src={deleteIcon}
                                alt='delete'
                                className='ml-3 cursor-pointer'
                                onClick={() => handleDeleteClick(item)}
                              />
                            </div>
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className='flex items-center justify-end px-10 mt-3'>
                <label className='secondary-font color-grey2 text-xs font-medium'>
                  {fromAndToText} of {totalTags}
                </label>
                <div className='flex items-center ml-6'>
                  <button
                    className={
                      'btn-pagination btn-pagination--previous ' + (prevPageNumber && 'active')
                    }
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={!prevPageNumber}
                  >
                    <svg width='8' height='12' viewBox='0 0 8 12' fill='none'>
                      <path
                        d='M7.12407 10.9757C7.35838 11.2101 7.35838 11.5899 7.12407 11.8243C6.88975 12.0586 6.50985 12.0586 6.27554 11.8243L0.87554 6.42426C0.641225 6.18995 0.641225 5.81005 0.87554 5.57573L6.27554 0.175735C6.50986 -0.0585795 6.88976 -0.0585795 7.12407 0.175735C7.35838 0.410049 7.35838 0.789949 7.12407 1.02426L2.14833 6L7.12407 10.9757Z'
                        fill='#D1D5DB'
                      />
                    </svg>
                  </button>
                  <button
                    className={
                      'btn-pagination btn-pagination--next ' + (nextPageNumber && 'active')
                    }
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={!nextPageNumber}
                  >
                    <svg width='8' height='12' viewBox='0 0 8 12' fill='none'>
                      <path
                        d='M7.12407 10.9757C7.35838 11.2101 7.35838 11.5899 7.12407 11.8243C6.88975 12.0586 6.50985 12.0586 6.27554 11.8243L0.87554 6.42426C0.641225 6.18995 0.641225 5.81005 0.87554 5.57573L6.27554 0.175735C6.50986 -0.0585795 6.88976 -0.0585795 7.12407 0.175735C7.35838 0.410049 7.35838 0.789949 7.12407 1.02426L2.14833 6L7.12407 10.9757Z'
                        fill='#D1D5DB'
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </>
          )}
          {tagList?.length === 0 && searchValue === '' && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                height: '100vh',
              }}
            >
              <div style={{ marginBottom: '5vh', fontSize: '30px', fontWeight: 'bold' }}>
                No Data Found
              </div>

              <button
                className='btn btn--primary btn--h48 text-base px-8 ml-6'
                onClick={toggleAddEditModal}
              >
                <img src={plusIcon} alt='plus-icon' className='mr-3' />
                New Tag
              </button>
            </div>
          )}
        </main>
      </section>
      {AddEditModal && <AddEditTag closeModal={toggleAddEditModal} refreshList={getTagList} />}
      {deleteModal && <DeleteTag closeModal={toggleDeleteModal} refreshList={getTagList} />}
    </>
  );
};

export default TagManagement;
