import * as Yup from 'yup';

import { ENUM_API_STATUS } from '../../utils/_gConstant';
import { contactInformationPostRequest } from './_request';
import countryCode from './_country.json';
import { toastError } from '../../components/ui-elements/_Toastify';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import useThrottle from '@/utils/_gFunctions/handleDebouncing';

const LoginQuestionner = (props) => {
  const { showQna, responseLeadId } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('Vorname ist erforderlich'),
    last_name: Yup.string().required('Nachname ist erforderlich'),
    email: Yup.string().email('Invalidemail').required('E-Mail ist erforderlich'),
    countryCode: Yup.string().required('Ländercode ist erforderlich'),
    mobile_number: Yup.string()
      .matches(/^[0-9]+$/, 'Die Telefonnummer darf nur Ziffern enthalten')
      .required('Telefonnummer ist erforderlich'),
    referral_code: Yup.string()
      .matches(/^[a-zA-Z0-9]*$/, 'Nur alphanumerische Zeichen sind erlaubt')
      .transform((value, originalValue) => (originalValue ? originalValue.toUpperCase() : '')),
  });

  const handlePostRequest = async (values) => {
    // Concatenate country code and mobile number
    const phoneNumberWithCountryCode = `${values.countryCode}${values.mobile_number}`;

    // Exclude countryCode from the values
    const { countryCode, ...restValues } = values;

    // Make the API request
    const res = await contactInformationPostRequest(responseLeadId, {
      ...restValues,
      mobile_number: phoneNumberWithCountryCode,
    });

    if (res?.status === ENUM_API_STATUS?.SUCCESS_PUT_API) {
      navigate(`/homeowner/${responseLeadId}`);
    } else {
      toastError(res?.data);
    }

    dispatch({ type: 'RESET_USER_ANSWERS' });
  };
  const throttledHandleSubmit = useThrottle((values) => handlePostRequest(values), 300);
  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      countryCode: '+49',
      mobile_number: '',
      referral_code: '',
    },
    validationSchema: validationSchema,
    onSubmit: throttledHandleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={`qna-container pt-10 ${showQna ? 'show' : ''}`}>
        <h2 className='heading-level2 leading4'>Fast geschafft!</h2>
        <p className='text-secondary font-regular leading3 mt-1'>
          Erstelle dein IKOSIA Konto, um dein Einsparpotenzial einzusehen.
        </p>

        <div className='mt-6'>
          <div className='col-11 col-12-sm flex flex-wrap -mx-3 mx-0-sm'>
            <div className='col-6 col-12-sm px-3 px-0-sm mb-5'>
              <label className='inline-block font-medium mb-1'>Vorname</label>
              <input
                type='text'
                name='first_name'
                className='input-field input-field--md input-field--h44 lato font-medium'
                placeholder='Vorname'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.first_name}
              />
              {formik.touched.first_name && formik.errors.first_name && (
                <div className='error-message md'>{formik.errors.first_name}</div>
              )}
            </div>
            <div className='col-6 col-12-sm px-3 px-0-sm mb-5'>
              <label className='inline-block font-medium mb-1'>Nachname</label>
              <input
                type='text'
                name='last_name'
                className='input-field input-field--md input-field--h44 lato font-medium'
                placeholder='Nachname'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.last_name}
              />
              {formik.touched.last_name && formik.errors.last_name && (
                <div className='error-message md'>{formik.errors.last_name}</div>
              )}
            </div>
          </div>
          <div className='mb-not-last-child col-11 col-12-sm flex flex-wrap -mx-3 mx-0-sm mb-5'>
            <div className='col-12 px-3 px-0-sm'>
              <label className='inline-block font-medium mb-1'>E-Mail</label>
              <input
                type='text'
                name='email'
                className='input-field input-field--md input-field--h44 lato font-medium'
                placeholder='e.g meier@gmail.com'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />

              {formik.touched.email && formik.errors.email && (
                <div className='error-message md'>{formik.errors.email}</div>
              )}
            </div>
          </div>
          <div className='mb-not-last-child col-11 col-12-sm flex flex-wrap -mx-3 mx-0-sm mb-5'>
            <div className='col-6 col-12-sm px-3 px-0-sm'>
              <label className='inline-block font-medium mb-1'>Telefon</label>
              <div className='relative'>
                <select
                  name='countryCode'
                  defaultValue=''
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.countryCode}
                  className='country-code'
                >
                  <option value='' disabled hidden>
                    +49
                  </option>
                  {countryCode
                    .sort((a, b) => a.dial_code.localeCompare(b.dial_code))
                    .map((country, index) => (
                      <option key={index} value={country.dial_code}>
                        {country.dial_code}
                      </option>
                    ))}
                </select>
                {/* {formik.touched.countryCode && formik.errors.countryCode && (
                  <div className="error-message md">{formik.errors.countryCode}</div>
                )} */}
                <input
                  type='text'
                  name='mobile_number'
                  className='input-field input-field--md input-field--h44 lato font-medium'
                  placeholder='Telefonnummer'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.mobile_number}
                  style={{ paddingLeft: 105 }}
                />
              </div>
              {formik.touched.mobile_number && formik.errors.mobile_number && (
                <div className='error-message md'>{formik.errors.mobile_number}</div>
              )}
            </div>
          </div>
          <div className='mb-not-last-child col-11 col-12-sm flex flex-wrap -mx-3 mx-0-sm mb-5'>
            <div className='col-6 col-12-sm px-3 px-0-sm'>
              <label className='inline-block font-medium mb-1'>Referral-Code</label>
              <input
                type='text'
                name='referral_code'
                className='input-field input-field--md input-field--h44 lato font-medium'
                placeholder='Referral-Code'
                onChange={(e) => {
                  formik.handleChange(e);
                  formik.setFieldValue('referral_code', e.target.value.toUpperCase());
                }}
                onBlur={formik.handleBlur}
                value={formik.values.referral_code}
              />
              {formik.touched.referral_code && formik.errors.referral_code && (
                <div className='error-message md'>{formik.errors.referral_code}</div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='qna-container__btn-next-wrapper'>
        <button
          type='submit'
          className={`btn btn--primary btn--h52 btn--md qna-container__btn-next text-base ${
            !formik.isValid ? 'disabled' : ''
          }`}
          style={{ minWidth: 180 }}
          disabled={!formik.isValid}
        >
          Einsparpotenzial anschauen
        </button>
      </div>
    </form>
  );
};

export default LoginQuestionner;
