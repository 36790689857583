import '../../assets/styles/scss/pages/admin-dashboard.scss';

import { ENUM_API_STATUS, UserRolesOptions } from '@/utils/_gConstant';
import { UsersListViewGetRequest, changeUserActiveStatusPatchRequest } from './_request';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import AddNewUser from './AddNewUser';
import DeleteUser from './DeleteUser';
import deleteIcon from '../../assets/icons/delete-with-circle.svg';
import editIcon from '../../assets/icons/edit-with-circle.svg';
import { getInitials } from '@/utils/_gFunctions/_handleGetInitials';
import plusIcon from '../../assets/icons/plus-with-circle.svg';
import { useDebounce } from '@/utils/_gFunctions/handleDebouncing';

const UserManagement = (props) => {
  const usersCount = 10;
  const [users, setUsers] = useState(null);
  const [nextPageNumber, setNextPageNumber] = useState(null);
  const [prevPageNumber, setPrevPageNumber] = useState(null);
  const [totalUsers, setTotalUsers] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [showAddModal, setShowAddModal] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [deleteUser, setDeleteUser] = useState(null);
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };

  const fetchUsers = useCallback(
    async (page) => {
      const queryParams = {};
      if (page) {
        queryParams.page = page;
      } else {
        page = currentPage;
      }
      if (debouncedSearchValue !== '') {
        queryParams.search = debouncedSearchValue;
      }
      const res = await UsersListViewGetRequest(queryParams);
      if (res?.status === ENUM_API_STATUS?.SUCCESS_GET_API) {
        setUsers(res?.data?.results);
        setNextPageNumber(res?.data?.next);
        setPrevPageNumber(res?.data?.previous);
        setTotalUsers(res?.data?.count);
        setCurrentPage(page || 1);
      }
    },
    [debouncedSearchValue, currentPage]
  );
  useEffect(() => {
    document.title = props.title;
  }, [props.title]);
  const changeUserActiveStatus = useCallback((userId, status) => {
    changeUserActiveStatusPatchRequest(userId, { active: status }).then((res) => {
      if (res?.status === ENUM_API_STATUS?.SUCCESS_PATCH_API) {
        setUsers((users) =>
          users.map((user) => {
            if (user?.id === userId) {
              return res?.data;
            }
            return user;
          })
        );
      }
    });
  }, []);

  useEffect(() => {
    fetchUsers(currentPage).then(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchValue, currentPage]);

  const fromAndToText = useMemo(() => {
    const from = (currentPage - 1) * usersCount + 1;
    const to = from + users?.length - 1;
    return `${from}-${to}`;
  }, [currentPage, users?.length]);

  const toggleEditUser = useCallback(
    (user) => {
      if (editingUser?.id === user?.id) {
        setEditingUser(null);
      } else {
        setEditingUser(user);
      }
    },
    [editingUser]
  );

  const toggleAddUser = useCallback(() => {
    setShowAddModal((addModal) => {
      return !addModal;
    });
  }, []);

  const toggleDeleteUser = useCallback((user = null) => {
    setDeleteUser(user);
  }, []);

  const closeModal = useCallback(() => {
    setShowAddModal(false);
    setEditingUser(null);
  }, []);

  const [isSticky, setIsSticky] = useState(false);
  const tableBody = useRef();
  useEffect(() => {
    const handleScroll = () => {
      const offset = tableBody?.current?.scrollTop;
      const threshold = 30;
      setIsSticky(offset > threshold);
    };

    const tableBodyRef = tableBody?.current;
    // console.log("Adding scroll event listener");
    tableBodyRef?.addEventListener('scroll', handleScroll);

    return () => {
      tableBodyRef?.removeEventListener('scroll', handleScroll);
      setIsSticky(false);
    };
  }, [tableBody]);

  return (
    <>
      <section className='admin-dashboard-wrapper'>
        <main
          className='admin-dashboard__main admin-dashboard__main--table-details pb-4'
          style={{ paddingTop: 88 }}
        >
          {(users?.length > 0 || searchValue !== '') && (
            <>
              <div className='flex items-center w-full px-10'>
                <h1 className='text-32 font-semibold'>User Management</h1>
                <div className='flex flex-1 ml-14'>
                  <div className='flex flex-1 items-center'>
                    <input
                      type='text'
                      className='input-field input-field--search input-field--h44 input-field--md lato'
                      placeholder='Search'
                      value={searchValue}
                      onChange={handleChange}
                      style={{ width: '60%' }}
                    />
                  </div>
                  <button
                    className='btn btn--primary btn--h48 text-base px-8 ml-6'
                    onClick={toggleAddUser}
                  >
                    <img src={plusIcon} alt='plus-icon' className='mr-3' />
                    New User
                  </button>
                </div>
              </div>
              <div className='h-full px-10 mt-6 overflow-y-auto' ref={tableBody}>
                <table className='table-styles table--user' style={{ maxHeight: '100%' }}>
                  <thead className={isSticky ? 'box-shadow' : ''}>
                    <tr>
                      <td>Name</td>
                      <td>Email</td>
                      <td>Mobile</td>
                      <td>Role</td>
                      <td>Status</td>
                      <td>Action</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((user) => (
                      <tr key={user.id}>
                        <td>
                          <div className='flex items-center'>
                            <figure
                              className='avatar-container'
                              style={{
                                width: 32,
                                height: 32,
                                backgroundColor: '#FF767B',
                                border: '1px solid #F3F2EC',
                              }}
                            >
                              <p className='text-white text-13 font-medium'>
                                {getInitials(user?.first_name)}
                              </p>
                            </figure>
                            <span className='text-lg font-medium ml-3'>
                              {user.first_name} {user.last_name}
                            </span>
                          </div>
                        </td>
                        <td>{user.email}</td>
                        <td>
                          {
                            user?.mobile_number
                              ? `(${user?.mobile_number?.substring(
                                  0,
                                  3
                                )})${user?.mobile_number?.substring(3)}`
                              : 'N/A' // or any other default value you want to display
                          }
                        </td>
                        <td>
                          {UserRolesOptions?.find((role) => role?.value === user?.role)?.label}
                        </td>
                        <td>
                          <div className='switch-container sm flex-shrink-0 mt-1'>
                            <input
                              id={`active-switch-${user?.id}`}
                              type='checkbox'
                              className='switch-input'
                              checked={user?.is_active}
                              onChange={(e) => changeUserActiveStatus(user?.id, !user.is_active)}
                            />
                            <label
                              htmlFor={`active-switch-${user?.id}`}
                              className='switch-label switch-label--sm'
                            ></label>
                          </div>
                        </td>
                        <td>
                          <div className='flex items-center'>
                            <img
                              src={editIcon}
                              alt='edit'
                              className='cursor-pointer'
                              onClick={() => toggleEditUser(user)}
                            />
                            <img
                              src={deleteIcon}
                              alt='delete'
                              className='ml-3 cursor-pointer'
                              onClick={() => toggleDeleteUser(user)}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className='flex items-center justify-end px-10 mt-3'>
                <label className='secondary-font color-grey2 text-xs font-medium'>
                  {fromAndToText} of {totalUsers}
                </label>
                <div className='flex items-center ml-6'>
                  <button
                    className={
                      'btn-pagination btn-pagination--previous ' + (prevPageNumber && 'active')
                    }
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={!prevPageNumber}
                  >
                    <svg width='8' height='12' viewBox='0 0 8 12' fill='none'>
                      <path
                        d='M7.12407 10.9757C7.35838 11.2101 7.35838 11.5899 7.12407 11.8243C6.88975 12.0586 6.50985 12.0586 6.27554 11.8243L0.87554 6.42426C0.641225 6.18995 0.641225 5.81005 0.87554 5.57573L6.27554 0.175735C6.50986 -0.0585795 6.88976 -0.0585795 7.12407 0.175735C7.35838 0.410049 7.35838 0.789949 7.12407 1.02426L2.14833 6L7.12407 10.9757Z'
                        fill='#D1D5DB'
                      />
                    </svg>
                  </button>
                  <button
                    className={
                      'btn-pagination btn-pagination--next ' + (nextPageNumber && 'active')
                    }
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={!nextPageNumber}
                  >
                    <svg width='8' height='12' viewBox='0 0 8 12' fill='none'>
                      <path
                        d='M7.12407 10.9757C7.35838 11.2101 7.35838 11.5899 7.12407 11.8243C6.88975 12.0586 6.50985 12.0586 6.27554 11.8243L0.87554 6.42426C0.641225 6.18995 0.641225 5.81005 0.87554 5.57573L6.27554 0.175735C6.50986 -0.0585795 6.88976 -0.0585795 7.12407 0.175735C7.35838 0.410049 7.35838 0.789949 7.12407 1.02426L2.14833 6L7.12407 10.9757Z'
                        fill='#D1D5DB'
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </>
          )}
          {users?.length === 0 && searchValue === '' && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                height: '100vh',
              }}
            >
              <div style={{ marginBottom: '5vh', fontSize: '30px', fontWeight: 'bold' }}>
                No Data Found
              </div>

              <button
                className='btn btn--primary btn--h48 text-base px-8 ml-6'
                onClick={toggleAddUser}
              >
                <img src={plusIcon} alt='plus-icon' className='mr-3' />
                New User
              </button>
            </div>
          )}
        </main>
      </section>
      {(showAddModal || editingUser) && (
        <AddNewUser closeModal={closeModal} refreshList={fetchUsers} editingUser={editingUser} />
      )}
      <DeleteUser
        user={deleteUser}
        closeModal={() => toggleDeleteUser(null)}
        setUsers={setUsers}
        LeadCard={false}
      />
    </>
  );
};
export default UserManagement;
