export const updateUserAnswers = (id, value) => {
  return {
    type: 'UPDATE_USER_ANSWERS',
    payload: {
      id,
      value,
    },
  };
};

export const updateLeadId = (id) => {
  return {
    type: 'SET_LEAD_ID',
    payload: id,
  };
};
