import './auth-module.scss';

import * as Yup from 'yup';

import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Cookies from 'universal-cookie';
import { ENUM_API_STATUS } from '../../../utils/_gConstant';
import loginBanner from '../../../assets/img/login-banner.svg';
import logo from '../../../assets/img/ikosia-logo.svg';
import { setPasswordPostRequest } from '../core/_request';
import { toastSuccess } from '../../ui-elements/_Toastify';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';

const CreatePassword = (props) => {
  const cookies = new Cookies();
  const location = useLocation();
  const { token } = location.state;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    document.title = props.title;
  }, [props.title]);
  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(6, 'Das Passwort muss mindestens aus 6 Zeichen bestehen')
        .required('Passwort wird benötigt'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Die Passwörter müssen übereinstimmen')
        .required('Bestätigen Sie, dass ein Passwort erforderlich ist'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      const data = {
        token,
        password: values.password,
        confirm_password: values.confirmPassword,
      };

      setPasswordPostRequest(data).then((r) => {
        if (r?.status === ENUM_API_STATUS.SUCCESS_GET_API) {
          // saveAuth(r.data?.access);
          // localStorage.setItem(siteConfig.REFRESH_TOKEN, r?.data.refresh);
          // getUserByToken(token).then(({ data: user }) => {
          //   setCurrentUser(user);
          //   dispatch(fetchAuthUserSuccess(user));
          //   cookies.set("leadId", leadId, {
          //     domain: ".ikosia.com",
          //     path: "/",
          //   });
          //   cookies.set("userName", user?.first_name, {
          //     domain: ".ikosia.com",
          //     path: "/",
          //   });
          //   navigate(`/homeowner/${leadId}/`, { replace: true });
          // });
          localStorage.clear();
          dispatch({ type: 'USER_LOGOUT' });
          cookies.remove('dashboard', {
            domain: process.env.REACT_APP_DOMAIN,
            path: '/',
          });

          cookies.remove('userName', {
            domain: process.env.REACT_APP_DOMAIN,
            path: '/',
          });
          navigate('/');
          toastSuccess('Passwort erfolgreich gesetzt!');
        }
      });

      setSubmitting(false);
    },
  });

  return (
    <>
      <section className='auth-module'>
        <div className='auth-module__container'>
          <div className='auth-module__main'>
            <div className='logo-container'>
              <img src={logo} alt='logo' />
            </div>
            <form className='auth-module__form' onSubmit={formik.handleSubmit}>
              <h1 className='text-48 font-medium'>Passwort erstellen</h1>
              <p className='secondary-font text-secondary text-lg mt-1'>
                Bitte erstelle dein Passwort, um dich jederzeit anzumelden.
              </p>
              <div className='mt-8'>
                <div className='input-field-wrapper'>
                  <label className='block ont-medium mb-1'>Neues Passwort erstellen</label>
                  <input
                    type='password'
                    id='password'
                    name='password'
                    className='input-field input-field--h52 input-field--md font-medium lato'
                    placeholder='******'
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <div style={{ color: 'red' }} className='error'>
                      {formik.errors.password}
                    </div>
                  )}
                </div>
                <div className='input-field-wrapper'>
                  <label className='block font-medium mb-1'>Bestätige das Passwort</label>
                  <input
                    type='password'
                    id='confirmPassword'
                    name='confirmPassword'
                    className='input-field input-field--h52 input-field--md font-medium lato'
                    placeholder='******'
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                    <div className='error' style={{ color: 'red' }}>
                      {formik.errors.confirmPassword}
                    </div>
                  )}
                </div>
              </div>
              <button
                type='submit'
                className='btn btn--primary btn--h52 px-6 text-base w-full mt-8'
              >
                Legen Sie mein Passwort festlegen
              </button>
            </form>
          </div>
          <div className='auth-module__banner'>
            <img src={loginBanner} alt='banner' className='w-full' />
          </div>
        </div>
      </section>
    </>
  );
};

export default CreatePassword;
