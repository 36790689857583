import { TagName, consultant, energyConsultantReducer, lead } from '../pages/Admin/store/reducer';
import {
  createOrder,
  dpuserDetails,
  headerCreateNewButtonStateHandle,
} from '@/pages/DistributionPartner/store/_reducer';

import authReducer from '@/components/auth/state/_reducer';
import { combineReducers } from 'redux';
import userAnswersReducer from '@/pages/HomeOwner/state/_reducer';

const appReducer = combineReducers({
  authReducer,
  consultant,
  lead,
  userAnswersReducer,
  energyConsultantReducer,
  TagName,
  headerCreateNewButtonStateHandle,
  createOrder,
  dpuserDetails,
});

export const rootReducer = (state, action) => {
  if (action?.type === 'USER_LOGOUT') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};
