import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { DPuserRoles } from '@/utils/_gConstant';
import DashboardIcon from '../../../assets/IconFiles/DashboardIcon';
import Logout from '@/icons/logout.svg';
import ProfileIcon from '../../../assets/IconFiles/ProfileIcon';
import { useTranslation } from 'react-i18next';

const DropdownMenu = ({ showDropdown, dashboardButtonState }) => {
  const { t } = useTranslation();
  const _guserRole = useSelector((state) => state?.authReducer?.user?.role);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <ul
      className={`dropdown-menu py-2 ${showDropdown ? 'show' : ''}`}
      style={{
        width: 210,
        left: 'initial',
        right: 0,
        top: 'calc(100% + 13px)',
      }}
    >
      <NavLink to='/dp/dashboard/' className='admin-dashboard__nav__list pt-5 pl-3'>
        <div className='admin-dashboard__nav__icon'>
          <DashboardIcon className='admin-dashboard__nav__icon__default' />
          <DashboardIcon strokeColor={'#00BD1E'} className='admin-dashboard__nav__icon__color' />
        </div>

        <span className='admin-dashboard__nav__title' style={{ fontWeight: 'normal' }}>
          {t('dashboard')}
        </span>
      </NavLink>
      {_guserRole !== DPuserRoles?.DP_USER && (
        <NavLink to='/dp/users/' className='admin-dashboard__nav__list pt-5 pl-3'>
          <div className='admin-dashboard__nav__icon'>
            <ProfileIcon className='admin-dashboard__nav__icon__default' />
            <ProfileIcon strokeColor={'#00BD1E'} className='admin-dashboard__nav__icon__color' />
          </div>

          <span className='admin-dashboard__nav__title' style={{ fontWeight: 'normal' }}>
            {t('user_management')}
          </span>
        </NavLink>
      )}

      <div
        className='admin-dashboard__nav__list pt-5 pl-2'
        onClick={() => {
          localStorage.clear();
          dispatch({ type: 'USER_LOGOUT' });

          navigate('/');
        }}
      >
        <div className='ml-1'>
          <img src={Logout} alt='exit-icon' className='mr-3 ' />
        </div>
        <span className='  text-red' style={{ fontSize: '18px' }}>
          {t('exit')}
        </span>
      </div>
    </ul>
  );
};

export default DropdownMenu;
