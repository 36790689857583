export const hearderCreateButtonHide = (flag) => ({
  type: 'BUTTON_STATE',
  payload: flag,
});

export const setServices = (value) => ({
  type: 'SERVICES',
  payload: value,
});

export const setClientType = (value) => ({
  type: 'CLIENT_TYPE',
  payload: value,
});

export const setCompanyName = (value) => ({
  type: 'COMPANY_NAME',
  payload: value,
});

export const setPostalCode = (value) => ({
  type: 'POSTAL_CODE',
  payload: value,
});

export const setCountry = (value) => ({
  type: 'COUNTRY',
  payload: value,
});

export const setCity = (value) => ({
  type: 'CITY',
  payload: value,
});

export const setResidentialUnits = (value) => ({
  type: 'RESIDENTIAL_UNITS',
  payload: value,
});

export const setAnnualEnergyCost = (value) => ({
  type: 'ANNUAL_ENERGY_COST',
  payload: value,
});

export const setProducts = (value) => ({
  type: 'PRODUCTS',
  payload: value,
});

export const setFirstName = (value) => ({
  type: 'FIRST_NAME',
  payload: value,
});

export const setLastName = (value) => ({
  type: 'LAST_NAME',
  payload: value,
});

export const setPhone = (value) => ({
  type: 'PHONE',
  payload: value,
});

export const setEmail = (value) => ({
  type: 'EMAIL',
  payload: value,
});

export const setConsultingLocation = (value) => ({
  type: 'CONSULTING_LOCATIONS',
  payload: value,
});
export const setStreet = (value) => ({
  type: 'STREET',
  payload: value,
});
export const setHouseNumber = (value) => ({
  type: 'HOUSE_NUMBER',
  payload: value,
});
export const setBuildingApplication = (value) => ({
  type: 'BUILDING_APPLICATION',
  payload: value,
});

export const setApproxEmployees = (value) => ({
  type: 'APPROX_EMPLOYEES',
  payload: value,
});

export const setAnnualTurnover = (value) => ({
  type: 'ANNUAL_TURNOVER',
  payload: value,
});
export const setBillingCompanyName = (value) => ({
  type: 'SET_BILLING_COMPANY_NAME',
  payload: value,
});
export const setBillingFirstName = (value) => ({
  type: 'SET_BILLING_FIRST_NAME',
  payload: value,
});
export const setBillingLastName = (value) => ({
  type: 'SET_BILLING_LAST_NAME',
  payload: value,
});
export const setBillingStreet = (value) => ({
  type: 'SET_BILLING_STREET',
  payload: value,
});
export const setBillingHouseNumber = (value) => ({
  type: 'SET_BILLING_HOUSE_NUMBER',
  payload: value,
});
export const setBillingPostalCode = (value) => ({
  type: 'SET_BILLING_POSTAL_CODE',
  payload: value,
});
export const setBillingCountry = (value) =>({
  type :'SET_BILLING_COUNTRY',
  payload:value
})
export const setBillingCity = (value) => ({
  type: 'SET_BILLING_CITY',
  payload: value,
});

export const resetData = () => ({
  type: 'RESET',
});

export const updateEnergyCost = (name, unit, currency, value, amount) => ({
  type: 'UPDATE_ENERGY_COST',
  payload: { name, unit, currency, value, amount },
});
export const setEnergyCost = (values) => ({
  type: 'SET_ENERGY_COST',
  payload: values,
});

export const setDPUserDetails = (values) => ({
  type: 'DP_USER_DETAILS',
  payload: values,
});

export const resetDPUser = () => ({
  type: 'RESET_DP_USER',
});
