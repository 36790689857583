import * as Yup from 'yup';

import { City, Country } from 'country-state-city';
import { ENUM_API_STATUS, UserRolesOptions } from '../../../utils/_gConstant';
import { EditProfileRequest, getUserByToken } from '../core/_request';
import React, { useEffect, useState } from 'react';
import { toastErrors, toastSuccess } from '../../ui-elements/_Toastify';
import { useDispatch, useSelector } from 'react-redux';

import Cookies from 'universal-cookie';
import Select from 'react-select';
import close from '../../../assets/icons/close.svg';
import { fetchAuthUserSuccess } from '../state/_action';
import { useAuth } from '../core/Auth';
import { useFormik } from 'formik';

const EditMyProfile = (props) => {
  const cookies = new Cookies();
  // eslint-disable-next-line no-unused-vars
  const { saveAuth, setCurrentUser } = useAuth();
  const { closeModal } = props;
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.authReducer?.user);

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('Vorname ist erforderlich'),
    last_name: Yup.string().required('Familienname, Nachname ist erforderlich'),
    email: Yup.string().email('Invalid email').required('E-Mail ist erforderlich'),
    mobile_number: Yup.string().required('Kontaktnummer ist erforderlich'),
    postal_code: Yup.string().required('Postleitzahl ist erforderlich'),
    country: Yup.object().required('Land ist erforderlich'),
    city: Yup.object().required('Stadt ist erforderlich'),
  });

  const formik = useFormik({
    initialValues: {
      first_name: user?.first_name || '',
      last_name: user?.last_name || '',
      email: user?.email || '',
      mobile_number: user?.mobile_number || '',
      postal_code: user?.postal_code || '',
      country: user?.country ? { label: user?.country, value: user?.country } : null,
      city: user?.city ? { label: user?.city, value: user?.city } : null,
    },
    validationSchema,
    onSubmit: async (values) => {
      // Extract label property for city and country
      const { city, country, ...restValues } = values;
      const cityLabel = city?.label || null;
      const countryLabel = country?.label || null;

      // Construct the payload to send
      const payload = {
        ...restValues,
        city: cityLabel,
        country: countryLabel,
      };

      const res = await EditProfileRequest(payload);
      if (res?.status === ENUM_API_STATUS?.SUCCESS_PATCH_API) {
        toastSuccess('Profil erfolgreich aktualisiert');
        const token = localStorage.getItem('access_token');
        const { data: user } = await getUserByToken(token);
        setCurrentUser(user);

        if (user?.id) {
          dispatch(fetchAuthUserSuccess(user));
        }
        cookies.set('userName', user?.first_name, {
          domain: process.env.REACT_APP_DOMAIN,
          path: '/',
        });
        closeModal();
      } else {
        toastErrors(res?.data);
      }
    },
  });

  const countryOptions = Country?.getAllCountries().map((country) => ({
    label: country.name,
    value: country.name,
  }));

  const [cityOptions, setCityOptions] = useState([]);

  useEffect(() => {
    const fetchCityOptions = () => {
      try {
        if (formik.values.country) {
          const selectedCountry = Country.getAllCountries().find(
            (country) => country.name === formik.values.country.value
          );

          if (selectedCountry) {
            const cities = City.getCitiesOfCountry(selectedCountry.isoCode);

            const cityOptions = cities.map((city) => ({
              label: city.name,
              value: city.name,
            }));

            // Update city options
            setCityOptions(cityOptions);

            // Check if the currently selected city is in the new options
            const currentCityOption = cityOptions.find(
              (option) => option.value === formik.values.city?.value
            );

            // If the currently selected city is not in the new options, reset to null
            if (!currentCityOption) {
              formik.setFieldValue('city', null);
            }
          } else {
            console.log('Invalid country name');
          }
        } else {
          // Clear city options when the country is null
          setCityOptions([]);
        }
      } catch (error) {
        console.error('Error fetching cities:', error);
        setCityOptions([]);
      }
    };

    fetchCityOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.country, formik.values.city, formik.setFieldValue]);

  return (
    <section className='modal modal--edit-profile open'>
      <div className='modal__container' style={{ width: 550 }}>
        <div className='modal__header'>
          <h1 className='text-lg font-bold'>Mein Profil</h1>
          <div className='modal__close' onClick={closeModal}>
            <img src={close} alt='close' />
          </div>
        </div>
        <div className='modal__body' style={{ padding: 0 }}>
          <div
            className='flex flex-col items-center justify-center p-6'
            style={{ backgroundColor: '#00381B' }}
          >
            <figure
              className='avatar-container avatar-container--cover'
              style={{ width: 74, height: 74 }}
            >
              <img
                src='https://images.unsplash.com/photo-1704303928271-775bb222ab46?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
                alt='profile'
              />
              {/* <div
                className="avatar-initials"
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "30px",
                  fontStyle:"initial",
                  color: "#f3f0e3",
                  background: "rgb(0, 56, 27)", // You can customize the background color
                  borderRadius: "50%",
                }}
              >
                {getInitials(user?.full_name)}
              </div> */}
            </figure>
            <h2 className='color-tertiary-brand text-36 font-semibold mt-3'>
              {user?.first_name && user.first_name.length > 15
                ? `${user.first_name.substring(0, 15)}...`
                : user?.first_name}
            </h2>
            <div
              className='inline-flex items-center justify-center secondary-font text-sm font-medium py-1 px-4 mt-2 rounded-2xl'
              style={{ backgroundColor: '#FED07B' }}
            >
              {UserRolesOptions.find((option) => option.value === user?.kind)?.label}
            </div>
          </div>
          <div className='p-6'>
            <div className='flex'>
              <div className='input-field-wrapper sm w-full mr-4'>
                <label className='input-field-label text-base font-medium'>Vorname</label>
                <input
                  type='text'
                  name='first_name'
                  value={formik.values.first_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className='input-field input-field--regular input-field--h40 text-sm w-full lato'
                  placeholder=''
                />
                {formik.touched.first_name && formik.errors.first_name && (
                  <div className='error-message'>{formik.errors.first_name}</div>
                )}
              </div>
              <div className='input-field-wrapper sm w-full'>
                <label className='input-field-label text-base font-medium'>Nachname</label>
                <input
                  type='text'
                  name='last_name'
                  value={formik.values.last_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className='input-field input-field--regular input-field--h40 text-sm w-full lato'
                  placeholder=''
                />
                {formik.touched.last_name && formik.errors.last_name && (
                  <div className='error-message'>{formik.errors.last_name}</div>
                )}
              </div>
            </div>

            <div className='input-field-wrapper sm'>
              <label className='input-field-label text-base font-medium'>Email</label>
              <input
                disabled
                type='text'
                name='email'
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className='input-field input-field--regular input-field--h40 text-sm w-full lato'
                placeholder=''
              />
              {formik.touched.email && formik.errors.email && (
                <div className='error-message'>{formik.errors.email}</div>
              )}
            </div>
            <div className='input-field-wrapper sm flex -mx-2'>
              <div className='col-6 px-2'>
                <label className='input-field-label text-base font-medium'>Kontakt Nummer</label>
                <input
                  type='text'
                  name='mobile_number'
                  value={formik.values.mobile_number}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className='input-field input-field--regular input-field--h40 text-sm w-full lato'
                  placeholder=''
                />
                {formik.touched.mobile_number && formik.errors.mobile_number && (
                  <div className='error-message'>{formik.errors.mobile_number}</div>
                )}
              </div>
              <div className='col-6 px-2'>
                <label className='input-field-label text-base font-medium'>Postleitzahl</label>
                <input
                  type='text'
                  name='postal_code'
                  value={formik.values.postal_code}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className='input-field input-field--regular input-field--h40 text-sm w-full lato'
                  placeholder=''
                />
                {formik.touched.postal_code && formik.errors.postal_code && (
                  <div className='error-message'>{formik.errors.postal_code}</div>
                )}
              </div>
            </div>
            <div className='input-field-wrapper sm'>
              <label className='input-field-label text-base font-medium'>Land</label>
              <Select
                name='country'
                options={countryOptions}
                value={formik.values.country}
                onChange={(selectedOption) => formik.setFieldValue('country', selectedOption)}
                onBlur={formik.handleBlur}
              />
              {formik.touched.country && formik.errors.country && (
                <div className='error-message'>{formik.errors.country}</div>
              )}
            </div>
            <div className='input-field-wrapper sm'>
              <label className='input-field-label text-base font-medium'>Stadt</label>
              <Select
                name='city'
                options={cityOptions}
                value={formik.values.city}
                onChange={(selectedOption) => formik.setFieldValue('city', selectedOption)}
                onBlur={formik.handleBlur}
                isSearchable
              />
              {formik.touched.city && formik.errors.city && (
                <div className='error-message'>{formik.errors.city}</div>
              )}
            </div>
          </div>
        </div>
        <div className='modal__footer justify-end'>
          <button
            type='button'
            className='btn btn--secondary btn--h40 px-6 py-2 text-base font-semibold'
            onClick={closeModal}
          >
            Stornieren
          </button>
          <button
            type='submit'
            className='btn btn--primary btn--h40 px-6 py-2 text-base font-semibold'
            onClick={formik.handleSubmit}
          >
            Speichern
          </button>
        </div>
      </div>
    </section>
  );
};

export default EditMyProfile;
