const siteConfig = Object.freeze({
  BASE_URL: process.env.REACT_APP_API_URL,

  //LOCAL STORAGE PROPERTIES
  DC_AUTH_IKOSIA: 'dc_auth_ikosia',
  ACCESS_TOKEN: 'access_token',
  REFRESH_TOKEN: 'refresh_token',

  //Query Param Keys
  AUTH_URL: 'auth_url',
  REDIRECTED_URL: 'redirected_url',

  //ENDPOINTS PROPERTIES
  REFRESH_TOKEN_URL: '/api/users/token/refresh/',
  LOGIN: '/api/users/auth/login/',
  GET_USER_BY_ACCESSTOKEN_URL: '/api/users/me/',
  LISTING_LEADS: '/api/leads/',
  LEADS: '/api/leads/stages/',
  UPDATE_LEADS: '/api/leads/',
  FILTER_VALUES: '/api/leads/get-filter-values/',
  FILTER_OPTIONS: '/api/leads/filter-options/',
  ASSIGN_CONSULTANT_LISTING: '/api/consultant/assign-list/',
  USERS: '/api/users/',
  ENERGY_CONSULTANT: '/api/consultant/',
  ENERGY_CONSULTANT_EXPORT: '/api/consultant/export/',
  USER_ACTIVITY_STATUS: '/api/users/<user-id>/set-active/',
  SINGLE_USERS: '/api/users/<user-id>/',
  CHANGE_PASSWORD: '/api/users/change-password/',
  EXPORT_FILE: '/api/leads/export/',
  REQUEST_OTP: '/api/users/otp/request-otp/',
  VERIFY_OTP: '/api/users/otp/verify-otp/',
  SET_PASSWORD: '/api/users/otp/create-password/',
  QUESTIONNEIR: '/api/landingpage/get-survey-categories/',
  QUESTIONNEIR_RESPONSE: '/api/landingpage/create-leads-response/',
  DASHBOARD_STATISTICS: '/api/energy-stats/dashboard/<lead-id>/',
  CONTACT_INFORMATION: '/api/leads/',
  EMAIL_ID: '/api/users/reset-password-email/',
  RESET_PASSWORD: '/api/users/reset-password/',
  VERIFY_EMAIL: '/api/leads/verify-email/',
  DELETE_LEAD: '/api/leads/<lead_id>/',
  SAMPLE_IMPORT_CONSULTANT: '/api/consultant/download-import-sample/',
  IMPORT_CONSULTANT_FILE: '/api/consultant/import/',
  SAMPLE_IMPORT_LEADS: '/api/leads/download-import-sample/',
  IMPORT_LEADS_FILE: '/api/leads/import/',
  TAG_LIST: '/api/leads/tags/',
  TAG_ALL_LIST: '/api/leads/tags/list/',
  DISTRIBUTION_PARTNER: '/api/distributor/',
  DISTRIBUTION_PARTNER_DASHBOARD: '/api/distributor/users/dashboard/',
  NEWS_API: '/api/cms/pages/blogs/?locale=de&limit=2',
  DISTRIBUTION_PARTNER_USERS: '/api/distributor/users/',
  DISTRIBUTION_PARTNER_LEADS: '/api/distributor/leads/',
  SERVICES: '/api/products/',
  CREATE_ORDER: '/api/orders/',
  GET_ORDER_DETAIL: '/api/orders/by-lead-id/',
  GET_VALIDATE_PRICE: '/api/products/validate-prices/',
  POST_CALCULATE_PRICE: '/api/products/calculate-price/',
  GET_COUNTRIES: '/api/countries/',
  GET_CITIES: '/api/countries/cities/',
});

export default siteConfig;
