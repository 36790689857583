import React from 'react';
import { useTranslation } from 'react-i18next';

const LeadInfo = (props) => {
  const {t} =useTranslation();
  const { leadData } = props;
  return (
    <div>
      <h3 className='title-text'>{t('basic_lead_info')}</h3>
      <div className='flex flex-wrap -mx-8 mt-4'>
        <div className='col-4 px-8 mb-4'>
          <label className='label-text'>{t('full_name')}</label>
          <p className='desc-text'>{leadData?.full_name}</p>
        </div>
        {/* <div className='col-4 px-8 mb-4'>
          <label className='label-text'>{t('lead_number')}</label>
          <p className='desc-text'>{leadData?.lead_no}</p>
        </div> */}
        <div className='col-4 px-8 mb-4'>
          <label className='label-text'>Email</label>
          <p className='desc-text'>{leadData?.email}</p>
        </div>
        <div className='col-4 px-8 mb-4'>
          <label className='label-text'>{t('phone_number')}</label>
          <p className='desc-text'>{leadData?.mobile_number}</p>
        </div>
        {/* <div className="col-4 px-8 mb-4">
                    <label className="label-text">Location</label>
                    <p className="desc-text">{leadData?.location}</p>
                </div> */}
      </div>
    </div>
  );
};

export default LeadInfo;
