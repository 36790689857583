import { deleteEnergyConsultantRequest, deleteUserDeleteRequest } from './_request';
import { useDispatch, useSelector } from 'react-redux';

import { ENUM_API_STATUS } from '../../utils/_gConstant';
import close from '../../assets/icons/close.svg';
import { selecteditConsultant } from './store/action';
import trashIcon from '../../assets/icons/trash-lg.svg';
import { useCallback } from 'react';

const DeleteUser = (props) => {
  const { user, closeModal, setUsers, LeadCard, deleteFunction, refreshList } = props;
  const dispatch = useDispatch();
  const consultantDelete = useSelector(
    (state) => state?.energyConsultantReducer?.selecteditConsultant
  );
  const deleteUser = useCallback(
    (userId) => {
      deleteUserDeleteRequest(userId, LeadCard).then((res) => {
        if (res?.status === ENUM_API_STATUS?.SUCCESS_DELETE_API) {
          setUsers((users) => users.filter((oldUser) => oldUser.id !== user.id));
          if (LeadCard) {
            deleteFunction();
          }
          closeModal();
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [closeModal, deleteFunction]
  );

  const deleteEnergyConsultant = async () => {
    const res = await deleteEnergyConsultantRequest(consultantDelete?.id);
    if (res?.status === ENUM_API_STATUS?.SUCCESS_DELETE_API) {
      dispatch(selecteditConsultant(null));
      refreshList();
      closeModal();
    }
  };

  const handleCloseButton = () => {
    if (consultantDelete) {
      dispatch(selecteditConsultant(null));
    }
    closeModal();
  };

  return (
    <section className={`modal modal--delete-user ${user && 'open'}`}>
      <div className='modal__container' style={{ width: 550 }}>
        <div className='modal__header'>
          <h1 className='text-lg font-bold'>Are you sure?</h1>
          <div className='modal__close' onClick={handleCloseButton}>
            <img src={close} alt='close' />
          </div>
        </div>
        <div className='modal__body' style={{ backgroundColor: '#FFF8F8' }}>
          <div className='flex flex-col items-center'>
            <img src={trashIcon} alt='trash' />
            <p className='font-medium mt-5'>
              Do you want to delete&nbsp;
              <span className='font-bold'>{user?.first_name}</span>? If yes, click 'delete.'
            </p>
          </div>
        </div>
        <div className='modal__footer justify-end'>
          <button
            className='btn btn--secondary btn--h40 px-6 py-2 text-base font-semibold'
            onClick={handleCloseButton}
          >
            Cancel
          </button>
          <button
            className='btn btn--red btn--h40 px-6 py-2 text-base font-semibold'
            onClick={() => {
              consultantDelete ? deleteEnergyConsultant() : deleteUser(user?.id);
            }}
          >
            Delete
          </button>
        </div>
      </div>
    </section>
  );
};

export default DeleteUser;
