import React from 'react';
import exportIcon from '../../assets/icons/export.svg';

const FormAnswers = () => {
  const questionnaireList = [
    { id: 1, questions: 'Basic Questions' },
    { id: 2, questions: 'Heating & Consumption' },
    { id: 3, questions: 'Roof' },
    { id: 4, questions: 'Outer Wall' },
    { id: 5, questions: 'Windows' },
    { id: 6, questions: 'Top Floor' },
    { id: 7, questions: 'Bottom plate' },
    { id: 8, questions: 'Bay Window & conservatory' },
    { id: 9, questions: 'Front Door' },
    { id: 10, questions: 'Photovoitaic & smart Home' },
    { id: 11, questions: 'Location' },
    { id: 12, questions: 'Basement' },
    { id: 13, questions: 'Miscellaneous' },
  ];
  const formAnswersList = [
    { id: 1, question: 'Heating & Consumption', answer: '2021' },
    { id: 2, question: 'How big is the living space?', answer: '1500' },
    { id: 3, question: 'How many full floors are there?', answer: '2' },
    {
      id: 4,
      question: 'What is the building situation?',
      answer: '3 sides covered',
    },
    {
      id: 5,
      question: 'Which of these parts have been replaced in the last 40 years?',
      answer: '3 sides covered',
    },
    {
      id: 6,
      question: 'Which of these parts have been replaced in the last 40 years?',
      answer: 'part 1, part 2, part3',
    },
    {
      id: 7,
      question: 'What is the average room height of a full floor of the building?',
      answer: '123',
    },
    {
      id: 8,
      question: 'Which building shape best corresponds to the floor plan of the building',
      answer: '2021',
    },
    {
      id: 9,
      question: 'Which building shape best corresponds to the floor plan of the building',
      answer: 'L-Form',
    },
  ];
  return (
    <>
      <div className='lead-main-info__form-answers mt-4 mx-4 pb-3 border1-b'>
        <div className='flex items-center justify-between'>
          <h2 className='text-lg font-semibold'>
            View all the answers collected from this user from all the questionnaire
          </h2>
          <button className='btn btn--secondary btn--h42 btn--md ml-4 font-bold'>
            <img src={exportIcon} alt='import-icon' className='mr-3' />
            Export
          </button>
        </div>
      </div>
      <div className='mt-5'>
        <div className='flex'>
          <div className='col-4 flex-shrink-0 pr-15 border1-r'>
            <ul className=''>
              {questionnaireList.map((item, index) => (
                <li key={index} className='action-nav-list'>
                  {item.questions}
                </li>
              ))}
            </ul>
          </div>
          <div className='col-9'>
            <ul className='px-8'>
              {formAnswersList.map((item, index) => (
                <li key={index} className='flex flex-col mb-6 mb-not-last-child'>
                  <h4 className='secondary-font text-secondary font-regular text-sm'>
                    {item.question}
                  </h4>
                  <span className='secondary-font text-lg font-semibold mt-1'>{item.answer}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormAnswers;
