import { ENUM_API_STATUS, isEmpty } from '@/utils/_gConstant';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import AddDPuser from './AddDPuser';
import Header from '../components/Header';
import TableRow from './TableRow';
import { getDPuserRequest } from '../store/request';
import loader from '../../../assets/icons/loading.gif';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

const DPUsers = (props) => {
  const { t } = useTranslation(); // Initialize useTranslation hook

  const [showAddUserModal, setShowAddModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [dpUsersList, setDPusersList] = useState([]);
  const DPuserList = useRef(null);
  const [nextPage, setNextPage] = useState(1);

  useEffect(()=>{
    document.title = props.title;
  },[props.title])

  const getUsersList = useCallback(
    (page, reset = false) => {
      if (page !== null) {
        getDPuserRequest({ page, page_size: 20 }, true).then((res) => {
          if (res?.status === ENUM_API_STATUS?.SUCCESS_GET_API) {
            if (!reset) {
              setDPusersList((list) => [
                ...list,
                ...(res && res.data && res.data.results ? res.data.results : []),
              ]);
            } else {
              setDPusersList(res?.data?.results);
            }
            setNextPage(res?.data?.next);
            setIsLoading(false);
          }
        });
      }
    },
    [setDPusersList]
  );
  
  useEffect(() => {
    
    getUsersList(1, true);
  }, [getUsersList]);

  useEffect(() => {
    const scrollHandler = () => {
      if (
        DPuserList.current &&
        DPuserList.current.scrollTop + DPuserList.current.clientHeight >=
          DPuserList.current.scrollHeight
      ) {
        getUsersList(nextPage);
      }
    };
    DPuserList?.current?.addEventListener('scroll', scrollHandler);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (DPuserList.current) DPuserList.current.removeEventListener('scroll', scrollHandler);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DPuserList, nextPage, DPuserList.current]);

  return (
    <section className='page-bg'>
      <Header />
      <main className='page-main h-screen'>
        <div className='max-container h-full'>
          <div className='flex flex-col items-start h-full mt-5'>
            <div className='w-full max-h-full table-styles-wrapper overflow-auto' ref={DPuserList}>
              <table className='table-styles table-styles--dp-users'>
                <thead>
                  <tr>
                    <td>{t('Name')}</td>
                    <td>{t('Status')}</td>
                    <td>{t('Email')}</td>
                    <td>{t('Role')}</td>
                    <td>{t('Number of Clients')}</td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  <TableRow dpUsersList={dpUsersList} refreshList={getUsersList} />
                  {nextPage != null ? (
                    <img
                      src={loader}
                      alt='loader'
                      style={{ width: '25px', height: '25px', margin: '10px' }}
                    />
                  ) : null}
                </tbody>
              </table>
              {isEmpty(dpUsersList) && !isLoading ? (
                <div className=' flex w-full mt-10' style={{ justifyContent: 'center' }}>
                  <div style={{ marginBottom: '5vh', fontSize: '30px', fontWeight: 'bold' }}>
                    {t('No Data Found')}
                  </div>
                </div>
              ) : null}
            </div>
            <button
              className='btn btn--primary btn--h40 flex-shrink-0 text-base px-8 py-1 mt-8'
              onClick={() => setShowAddModal(true)}
            >
              <svg width='15' height='15' viewBox='0 0 15 15' fill='none' className='mr-3'>
                <path
                  d='M7.5 1.5L7.5 13.5M13.5 7.5L1.5 7.5'
                  stroke='white'
                  strokeWidth='2'
                  strokeLinecap='round'
                />
              </svg>
              {t('Create New User')}
            </button>
          </div>
        </div>
      </main>
      {showAddUserModal && <AddDPuser closeModal={setShowAddModal} refreshList={getUsersList} readOnly={false} />}
    </section>
  );
};

export default DPUsers;
